// react
import React, { useCallback, useEffect, useRef, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import Pagination from "../shared/Pagination";
import ProductCard from "../shared/ProductCard";
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
    Search20Svg,
} from "../../svg";
import { sidebarOpen } from "../../store/sidebar";
import { usePlatformValue } from "../../atom/platform";
import Suggestions from "../header/Suggestions";
import { useCategoriesState } from "../../atom/categories";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { HiOutlineFilter } from "react-icons/hi";

import { useProductState } from "../../atom/product";
import { getProductsApi } from "../../services/api/product_api";
import { useRequest } from "ahooks";
import { useCategoriesPageState } from "../../atom/categories_page";
import { Loading } from "../loading/loading";
import { useLocation } from "react-router-dom";

function useSetOption(option, filter, dispatch) {
    const callback = useCallback(filter, []);

    return useCallback(
        (data) => {
            dispatch({
                type: "SET_OPTION_VALUE",
                option,
                value: callback(data),
            });
        },
        [option, callback, dispatch]
    );
}

function ProductsView(props) {
    const {
        isLoading,
        productsList,
        options,
        filters,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        sidebarOpen,
    } = props;
    const [layout, setLayout] = useState(propsLayout);
    const location = useLocation();

    const [inputValue, setInputValue] = useState("");
    const [loadProduct, setLoadProduct] = useState(false);
    const platform = usePlatformValue();
    /////////////
    const [dataProducts, setDataProducts] = /* useProductState() */useState();
    const [currentPage, setCurrentPage] = useCategoriesPageState();

    const count = useRef(1);

    const { loading, runAsync: getProducts } = useRequest(() => getProductsApi(count.current), {
        debounceWait: 250,
        cacheKey: "all-products-cache",
        manual: true,
        onSuccess: (res) => {
            let newData;
            if(count.current !== 1){
                newData = [...dataProducts.data, ...res.data.allProducts.data];
            }else newData = res.data.allProducts.data;
            setDataProducts((products) => ({
                ...products,
                current_page: res.data.allProducts.current_page,
                data: newData,
                last_page: res.data.allProducts.last_page,
            }));
            count.current++;
        },
    });

    const handleScrollProduct = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

        // Check if the user has scrolled to the bottom of the page

        if (!loading && scrollTop + clientHeight >= scrollHeight - 15 && location.pathname == "/") {
            // console.log('scroll-event');
            if (count.current < dataProducts?.last_page) fetchData();
        }
    };

    const fetchData = async () => {
        await getProducts();
            // .then((res) => {

            //     setCurrentPage((current) => ({
            //         ...current,
            //         pageProduct: res.data.allProducts.current_page + 1,
            //     }));
            // })
            // .catch((err) => console.log(err));
    };
    useEffect(async () => {
        count.current = 1;
        await getProducts().then(() => setLoadProduct(true));
    }, []);

    useEffect(() => {
        if (location.pathname == "/") {
            /* console.log("scroll-view-product"); */
            window.addEventListener("scroll", handleScrollProduct);
            return () => window.removeEventListener("scroll", handleScrollProduct);
        }
    }, [loadProduct]);

    const handlePageChange = useSetOption("page", parseFloat, dispatch);
    const handleSortChange = useSetOption("sort", (event) => event.target.value, dispatch);
    const handleLimitChange = useSetOption("limit", (event) => parseFloat(event.target.value), dispatch);

    const handleResetFilters = useCallback(() => {
        dispatch({ type: "RESET_FILTERS" });
    }, [dispatch]);

    const filtersCount = Object.keys(filters)
        .map((x) => filters[x])
        .filter((x) => x).length;
    let viewModes = [
        { key: "grid", title: "Grid", icon: <LayoutGrid16x16Svg /> },
        { key: "grid-with-features", title: "Grid With Features", icon: <LayoutGridWithDetails16x16Svg /> },
        { key: "list", title: "List", icon: <LayoutList16x16Svg /> },
    ];

    viewModes = viewModes.map((viewMode) => {
        const className = classNames("layout-switcher__button", {
            "layout-switcher__button--active": layout === viewMode.key,
        });

        return (
            <button
                key={viewMode.key}
                title={viewMode.title}
                type="button"
                className={className}
                onClick={() => setLayout(viewMode.key)}
            >
                {viewMode.icon}
            </button>
        );
    });

    const productsListItems = productsList.items.map((product) => (
        <div key={product.id} className="products-list__item">
            <ProductCard product={product} />
        </div>
    ));
    let listProduct;
    if (dataProducts?.data?.length > 0) {
        listProduct = dataProducts?.data?.map(
            (item, index) =>
                item.sales_price > 0 && (
                    <div key={index} className="products-list__item">
                        <ProductCard product={item} />
                    </div>
                )
        );
    }

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    let content;

    if (listProduct) {
        if (platform !== "web") {
            content = (
                <div className="products-view__content">
                    {location.pathname !== '/' &&
                    <div className="products-view__options">
                        <div className="d-flex align-items-center">
                            <div
                                className=" d-flex align-items-center border px-3 py-1 w-100"
                                style={{ borderRadius: 30 }}
                            >
                                <HiMagnifyingGlass size={24} />
                                <input
                                    type="text"
                                    placeholder="Tìm kiếm hơn 1000 sản phẩm..."
                                    value={inputValue}
                                    onChange={(x) => setInputValue(x.target.value)}
                                    style={{
                                        height: 30,
                                        width: "100%",
                                        marginLeft: 4,
                                        border: "none",
                                        outline: "none",
                                    }}
                                />
                            </div>
                            <div className="ml-3" onClick={() => sidebarOpen()}>
                                <HiOutlineFilter size={24} />
                                {!!filtersCount && <span className="filters-button__counter">{filtersCount}</span>}
                            </div>
                        </div>
                    </div>
                    }
                    <div
                        className="products-view__list products-list"
                        data-layout={layout !== "list" ? grid : layout}
                        data-with-features={layout === "grid-with-features" ? "true" : "false"}
                    >
                        <div className="products-list__body">{listProduct}</div>
                    </div>
                </div>
            );
        } else {
            content = (
                <div className="products-view__content z-50">
                    {/* {location.pathname !== "/" && (
                        <div className="products-view__options">
                            <div className={viewOptionsClasses}>
                                <div className="d-flex justify-content-between w-100">
                                    <div className="view-options__filters-button ml-2">
                                        <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                            <Filters16Svg className="filters-button__icon" />
                                            <span className="filters-button__title">Filters</span>
                                            {!!filtersCount && (
                                                <span className="filters-button__counter">{filtersCount}</span>
                                            )}
                                        </button>
                                    </div>
                                </div>

                                <div className="view-options__legend">
                                    {`Showing ${productsList.from}—${productsList.to} of ${productsList.total} products`}
                                </div>
                                <div className="view-options__divider mt-3" />
                                {platform === "web" && (
                                    <div className="view-options__layout ml-2">
                                        <div className="layout-switcher">
                                            <div className="layout-switcher__list">{viewModes}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )} */}
                    <div
                        className="products-view__list products-list"
                        data-layout={layout !== "list" ? grid : layout}
                        data-with-features={layout === "grid-with-features" ? "true" : "false"}
                    >
                        <div className="products-list__body">{listProduct}</div>
                    </div>
                </div>
            );
        }
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">Không có dữ liệu</div>
                <div className="products-view__empty-subtitle">Vui lòng thử lại sau! </div>
                <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
                    Thử lại
                </button>
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {content}
            {loading && <Loading />}
        </div>
    );
}

ProductsView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    productsList: PropTypes.object,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(["grid-3-sidebar", "grid-4-full", "grid-5-full"]),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

ProductsView.defaultProps = {
    layout: "grid",
    grid: "grid-3-sidebar",
    offcanvas: "mobile",
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsView);
