// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";

// data stubs
import theme from "../../data/theme";
import { schemaLogin } from "../../schema/schemaLogin";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRequest } from "ahooks";
import BlockLoader from "../blocks/BlockLoader";
import TextInput from "../Custom/TextInput";
import { loginWebApi } from "../../services/api/user_api";
import { toast } from "react-toastify";
import { StorageKey } from "../../services/storage";
import { setDataToStorage } from "../../utils/constant/config";
import { useSetAuthState } from "../../atom/auth";
import { useUserSetValue } from "../../atom/user";
import { ID_COMPANY } from "../../utils/constant";

export default function AccountPageLogin() {
    const history = useHistory();
    const setUser = useUserSetValue();
    const setAuth = useSetAuthState();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schemaLogin),
    });

    const { runAsync, loading } = useRequest((data) => loginWebApi(data), {
        debounceInterval: 250,
        cacheKey: "loginWeb",
        manual: true,
    });

    const onSubmit = (data) => {
        //console.log(data);
        runAsync(data)
            .then((res) => {
                const token = res.data.token;
                const userInfor = {
                    id: res.data.user.xid,
                    name: res.data.user.name,
                    phone: res.data.user.phone,
                    company_id: ID_COMPANY,
                    img_url: res.data.user.profile_image_url,
                    email:  res.data.user.email,
                    address: res.data.user.address,
                };
                setDataToStorage(StorageKey.Authen, token);
                setAuth({ loading: false, reinitialized: false, token: token });
                setUser(userInfor);
                history.push("/");
            })
            .catch((err) => {
                console.log("error", err);
                if (err.error_message == "These credentials do not match our records.") {
                    toast.error(`Email hoặc mật khẩu không chính xác, vui lòng thử lại!`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                } else {
                    toast.error(`Đăng nhập không thành công, vui lòng thử lại sau!`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                }
            });
    };

    const breadcrumb = [
        { title: "Trang chủ", url: "" },
        { title: "Tài khoản của tôi", url: "" },
    ];

    if (loading) return <BlockLoader />;

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Đăng nhập — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Đăng nhập" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Đăng nhập</h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <TextInput
                                            label="Email"
                                            rules={"email"}
                                            type="email"
                                            placeholder="example@example"
                                            register={register}
                                            errors={errors.email ? errors.email.message : ""}
                                        />
                                        <TextInput
                                            label="Mật khẩu"
                                            rules={"password"}
                                            type="password"
                                            placeholder="xxx xxx xxx"
                                            register={register}
                                            errors={errors.password ? errors.password.message : ""}
                                        />
                                        {/* {isErrorPassword && (
                                        <p style={{ color: "red", marginTop: 2 }}>Mật khẩu không trùng khớp</p>
                                    )} */}
                                    <div className="align-items-center mt-5">
                                        <button type="submit" className="btn btn-primary rounded mb-10">
                                            Đăng nhập
                                        </button>
                                        <div className="d-flex align-items-center mt-3" style={{fontSize: 14}}>Bạn chưa có tài khoản?
                                            <Link to={"/account/register"} className="ml-1 text-warning" style={{cursor: 'pointer', textDecoration: 'underline'}}>Đăng ký ngay</Link>
                                        </div>
                                    </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
