// react
import React, { useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import { ArrowRoundedDown12x7Svg, Check9x7Svg } from '../../svg';
import { useFilterState } from '../../atom/filter';
import { getBrandApi } from '../../services/api/brand';
import { useRequest } from 'ahooks';

function FilterCheck(props) {
    const { data, value, onChangeValue } = props;

    const [filter, setFilter] = useFilterState();
    const [offSet, setOffSet] = useState(0);
    const [brand, setBrand] = useState([]);

    const { loading, runAsync: getBrand } = useRequest(() => getBrandApi(offSet),
        {
            debounceWait: 250,
            cacheKey: "brand-cache",
            manual: true,
        }
    );

    useEffect(() => {
        getBrand().then((res) => {
            setBrand(res)
        }).catch(err => console.log(err));
    }, []);

    const handleShowMore = async() => {
        if(brand.meta.paging.links.next){
            setOffSet(prev => prev + 10);
            await getBrand().then((res) => {
                let newData = [...brand.data, ...res.data];
                console.log(newData);
                setBrand((data) => ({
                    ...data,
                    data: newData,
                    meta: res.meta,
                }))
                
            }).catch(err => console.log(err));
        }
    };

    const handleChange = (event) => {
        let newValue;
        if (event.target.checked)
            if(filter.brand_id)
                newValue = [...filter.brand_id, event.target.value];
            else newValue = [event.target.value];
        else
            newValue = filter.brand_id.filter(item => item !== event.target.value);
        setFilter((data) => ({
            ...data,
            brand_id: newValue,
        }));
    };

    const itemsList = brand?.data?.map((item, index) => {
        let count;

        if (item.count) {
            count = <span className="filter-list__counter">{item.count}</span>;
        }

        const itemClasses = classNames('filter-list__item', {
            'filter-list__item--disabled': item.count === 0,
        });

        return (
            <label key={item.xid} className={itemClasses}>
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input
                            className="input-check__input"
                            type="checkbox"
                            value={item.xid}
                            //checked={value.includes(item.slug)}
                            disabled={item.count === 0}
                            onChange={handleChange}
                        />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{item.name}</span>
                {count}
            </label>
        );
    });

    return (
        <div className="filter-list">
            <div className="filter-list__list">
                {itemsList}
                {brand?.meta?.paging?.links?.next &&
                    <p className="filter-list__title font-weight-normal text-decoration-underline mt-1" style={{textDecoration: 'underline', color: 'orange', cursor: 'pointer'}}
                        onClick={() => handleShowMore()}>
                        Hiển thị thêm
                    </p>
                }
            </div>
        </div>
    );
}

FilterCheck.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
    /**
     * Value.
     */
    value: PropTypes.arrayOf(PropTypes.string),
    /**
     * Change value callback.
     */
    onChangeValue: PropTypes.func,
};

export default FilterCheck;
