// react
import React, { Component, useRef, useCallback, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
import ProductCard from '../shared/ProductCard';
import StroykaSlick from '../shared/StroykaSlick';
import { useRequest } from "ahooks";
import { productsByCategory } from '../../services/api/product_api';

const slickSettings = {
    'grid-4': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-4-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 474,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-5': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    horizontal: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

const BlockProductsCarousel = ({
    layout,
    title,
    withSidebar,
    //onGroupClick,
    groups,
    rows,
    categories,
    //products
  }) => {
    let currentPage = useRef(1); 

    const { data: productByCategory, loading, runAsync } = useRequest((slug) => productsByCategory(slug, currentPage), {
      debounceWait: 250,
      cacheKey: "products-category-cache",
      manual: true,
    });

    useEffect(() => {
      runAsync(categories);
    }, [])

    const slickRef = useRef(null);
  
    const handleNextClick = () => {
      if (slickRef.current) {
        slickRef.current.slickNext();
      }
    };
  
    const handlePrevClick = () => {
      if (slickRef.current) {
        slickRef.current.slickPrev();
      }
    };
  
    const setSlickRef = (ref) => {
      slickRef.current = ref;
    };
  
    const productsColumns = () => {
      const columns = [];
      let productsCopy = productByCategory ? [...productByCategory?.data?.allProducts?.data] : [];
  
      if (rows > 0) {
        while (productsCopy.length > 0) {
          columns.push(productsCopy.splice(0, rows));
        }
      }
  
      return columns;
    };
  
    const columns = productsColumns().map((column, index) => {
      const products = column.map((product, index) => (
        <div key={index} className="block-products-carousel__cell">
          <ProductCard product={product} />
        </div>
      ));
  
      return (
        <div key={index} className="block-products-carousel__column">
          {products}
        </div>
      );
    });
  
    const blockClasses = classNames('block block-products-carousel', {
      'block-products-carousel--loading': loading,
      'block-products-carousel--has-items': columns.length > 0
    });
  
    const containerClasses = classNames({
      container: !withSidebar
    });

    const handleClick = (tab) => {
        runAsync(`&${tab.xid}`);
    };
    
    return (
      <div className={blockClasses} data-layout={layout}>
        <div className={containerClasses}>
          <BlockHeader
            title={title}
            groups={groups}
            arrows
            onNext={handleNextClick}
            onPrev={handlePrevClick}
            onGroupClick={handleClick}
          />
  
          <div className="block-products-carousel__slider">
            <div className="block-products-carousel__preloader" />
  
            <StroykaSlick ref={setSlickRef} {...slickSettings[layout]}>
              {columns}
            </StroykaSlick>
          </div>
        </div>
      </div>
    );
  };
  
  BlockProductsCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    products: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func
  };
  
  BlockProductsCarousel.defaultProps = {
    layout: 'grid-4',
    rows: 3,
    products: [],
    groups: [],
    withSidebar: false,
    loading: false,
    onGroupClick: undefined
  };
  
  export default BlockProductsCarousel;
