// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatName } from '../../utils/constant/formatName';
import { useAuthStateValue } from '../../atom/auth';

export default function FooterLinks(props) {
    const { title, items } = props;
    const auth = useAuthStateValue();
    const list = [
        { title: 'Trang chủ', url: '/'},
        { title: 'Sản phẩm', url: '/shop/category-list'},
        { title: 'Tài khoản', url: '/account/dashboard'},
        { title: 'Liên hệ', url: '/contact-us'},
    ];
    const handleAuth = (item) => {
        if(item == '/account/dashboard'){
            if(!auth.token)
                return '/account/login'
            else return item
        }
        else return item
    }
    const linksList = list.map((item, index) => (
        <li key={index} className="footer-links__item">
            <Link to={handleAuth(item.url)} className="footer-links__link">
                {item.title}
            </Link>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-links">
            <h5 className="footer-links__title">{title}</h5>
            <ul className="footer-links__list">
                {linksList}
            </ul>
        </div>
    );
}

FooterLinks.propTypes = {
    /** widget title */
    title: PropTypes.node.isRequired,
    /** array of links */
    items: PropTypes.array,
};

FooterLinks.defaultProps = {
    items: [],
};
