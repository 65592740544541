import * as yup from "yup";
import { REGEX } from "./regex";

export const schemaLogin = yup.object().shape({
    email: yup
        .string()
        .required("Vui lòng nhập Email.")
        .max(120)
        .matches(RegExp(REGEX.EMAIL), "Email không hợp lệ. Vui lòng thử lại.")
        .typeError(""),
    password: yup
        .string()
        .required("Vui lòng nhập mật khẩu.")
        .max(120)
        .matches(RegExp(REGEX.PASSWORD), "Tối thiểu 8 ký tự.")
        .typeError(""),
});
