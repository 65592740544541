// react
import React, { useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import { useEffect } from "react";
import axios from "axios";
import BlockLoader from "../blocks/BlockLoader";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextInput from "../Custom/TextInput";
import { HiOutlineMapPin, HiOutlinePhone, HiOutlineUser } from "react-icons/hi2";
import { HiOutlineMail } from "react-icons/hi";
import { toast } from "react-toastify";
import { useAuthStateValue } from "../../atom/auth";
import { useRequest } from "ahooks";
import { addAddressApi, updateAddressApi } from "../../services/api/address_api";
import { useHistory } from "react-router-dom";
import SelectInput from "../Custom/SelectInput";
import { schemaAddress } from "../../schema/schemaAddress";

export default function AccountPageEditAddress(props) {
    const { data } = props.location.state;
    const auth = useAuthStateValue();
    const history = useHistory();

    const [address, setAddress] = useState(data);
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schemaAddress),
        defaultValues: {
            name: address ? address.name : "",
            phoneNumber: address ? address.phone : "",
            email: address ? address.email : "",
            address: address ? address.shipping_address : "",
            city: address ? address.city : "",
            district: address ? address.state : "",
        },
    });

    const { runAsync: addAddressShippingApi, loading: loadingAdd } = useRequest(
        () => addAddressApi(address, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "add-address",
            manual: true,
        }
    );

    const { runAsync: updateAddressShippingApi, loading: loadingUpdate } = useRequest(
        () => updateAddressApi(address.xid, { token: auth.token }, address),
        {
            debounceInterval: 250,
            cacheKey: "update-address",
            manual: true,
        }
    );

    useEffect(() => {
        getProvinces();
    }, []);

    const getProvinces = async () => {
        const { data } = await axios.get("https://provinces.open-api.vn/api/?depth=3");
        const Provinces = data.map((item) => ({
            value: item.code,
            label: item.name,
        }));
        setProvinces(Provinces);
    };

    const getDistricts = async (provinceCode) => {
        const response = await axios.get(`https://provinces.open-api.vn/api/p/${provinceCode}?depth=3`);
        const data = response.data.districts;
        const Districts = data.map((item) => ({
            value: item.code,
            label: item.name,
        }));
        setDistricts(Districts);
    };

    const onSubmit = (data) => {
        console.log("data", data);
        setAddress((address) => ({
            ...address,
            name: data.name,
            phone: data.phoneNumber,
            email: data.email,
            shipping_address: data.address,
            city: data.city,
            state: data.district,
            address: "address",
            country: "VN",
            zipcode: "1",
        }));
        if (address) {
            updateAddressShippingApi()
                .then((res) => {
                    console.log(res);
                    history.goBack();
                    toast.success(`Chỉnh sửa địa chỉ thành công`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                })
                .catch((err) => console.log(err));
        } else {
            addAddressShippingApi()
                .then((res) => {
                    console.log(res);
                    toast.success(`Thêm địa chỉ thành công`, { theme: "colored", className: "mx-2 rounded mt-2" });
                    history.goBack();
                })
                .catch((err) => console.log(err));
        }
    };

    if (loadingUpdate) return <BlockLoader />;

    if (loadingAdd) return <BlockLoader />;

    return (
        <div className="card">
            <Helmet>
                <title>Chỉnh sửa địa chỉ</title>
            </Helmet>
            <div className="card-header">
                <h5>{address ? "Chỉnh sửa địa chỉ" : "Thêm mới địa chỉ"}</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextInput
                                label="Họ và tên"
                                rules={"name"}
                                type="text"
                                placeholder="Nguyễn Văn Ánh"
                                register={register}
                                errors={errors.name ? errors.name.message : ""}
                                icon={<HiOutlineUser size={22} />}
                            />
                            <TextInput
                                label="Số điện thoại"
                                rules={"phoneNumber"}
                                type="numberic"
                                placeholder="0 xxx xxx xxx"
                                register={register}
                                errors={errors.phoneNumber ? errors.phoneNumber.message : ""}
                                icon={<HiOutlinePhone size={22} />}
                            />
                            <TextInput
                                label="Địa chỉ email"
                                rules={"email"}
                                type="email"
                                placeholder="example@example"
                                register={register}
                                errors={errors.email ? errors.email.message : ""}
                                icon={<HiOutlineMail size={22} />}
                            />
                            <SelectInput
                                label="Tỉnh, thành phố"
                                name="city"
                                control={control}
                                errors={errors.city ? errors.city.message : ""}
                                icon={<HiOutlineMapPin size={22} />}
                                options={provinces}
                                defaultValue={{
                                    value: "city",
                                    label: getValues().city ? getValues().city : "Chọn tỉnh/ thành phố",
                                }}
                                func={getDistricts}
                                reset={() => setValue("district", "")}
                            />
                            <SelectInput
                                label="Quận, huyện"
                                name="district"
                                control={control}
                                errors={errors.district ? errors.district.message : ""}
                                icon={<HiOutlineMapPin size={22} />}
                                options={districts}
                                defaultValue={{
                                    value: "district",
                                    label: getValues().district ? getValues().district : "Chọn quận/ huyện",
                                }}
                                func={() => ({})}
                                reset={() => ({})}
                            />
                            <TextInput
                                label="Địa chỉ cụ thể"
                                rules={"address"}
                                type="address"
                                placeholder="23 đường số 8, phường Linh Trung,..."
                                register={register}
                                errors={errors.address ? errors.address.message : ""}
                                icon={<HiOutlineMapPin size={22} />}
                            />
                            <button type="submit" className="btn btn-primary rounded mt-3 mb-10">
                                Lưu thông tin
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
