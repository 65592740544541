// react
import React from 'react';

// application
import SocialLinks from '../shared/SocialLinks';

export default function FooterNewsletter() {
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Zalo Mini App</h5>
            <div className="footer-newsletter__text">
                Quét mã bên dưới để trải nghiệm ngay dịch vụ Mini App trên Zalo của chúng tôi
            </div>

            {/* <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                />
                <button type="submit" className="footer-newsletter__form-button btn btn-primary">Subscribe</button>
            </form> */}
            <div>
                <img src="https://i.imgur.com/RQiIkVO.jpg" className='w-50 h-50 object-fit-contain border rounded'/>
            </div>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Theo dõi ngay
            </div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
