import { atom, selector } from "recoil";

// Atom for storing the current locale
export const localeState = atom({
  key: "localeState",
  default: "en",
});


// Reducer function
export const localeReducer = (state = localeState.default, action) => {
  if (action.type === localeChange.key) {
    return action.locale;
  }

  return state;
}