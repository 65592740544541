import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const BANNER_FEATURES_STATE = atom({
    key: 'BANNER_FEATURES_STATE', // unique ID (with respect to other atoms/selectors)
    default: {
        categories: [],
        front_product_cards: [],
        front_settings: [],
        products: [],
    }
     // default value (aka initial value)
});

export const useBannerFeatureState = () => useRecoilState(BANNER_FEATURES_STATE);

export const useBannerFeatureValue = () => useRecoilValue(BANNER_FEATURES_STATE);

export const useSetBannerFeatureValue = () => useSetRecoilState(BANNER_FEATURES_STATE);