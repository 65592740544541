// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageTerms() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Terms And Conditions', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Điều khoản và điều kiện`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block mt-3">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h4 >Điều khoản và điều kiện</h4>
                            <div className="document__subtitle">Cập nhật lần cuối vào 27/06/2023</div>
                        </div>
                        <div className="document__content typography">
                            <p style={{textAlign: 'justify'}}>
                                Chào mừng Quý khách đến với Minh Ký – trang TMĐT được thiết lập và thuộc sở hữu của CÔNG TY CỔ PHẦN MINH KÝ. Khi Quý khách truy cập vào mini app của chúng tôi đồng nghĩa với việc quý khách đã đồng ý với các điều khoản này. Mini App có thể được thay đổi, chỉnh sửa hoặc điều chỉnh bất kỳ nội dung nào trong Quy định và Điều kiện sử dụng và có hiệu lực sau 5 ngày, khi những thay đổi được thông báo và đăng tải đầy đủ trên hệ thống.

                                Quý khách vui lòng đọc kỹ Điều khoản và Điều kiện giao dịch này trước khi đặt mua bất kỳ sản phẩm, dịch vụ nào trên Minh Ký.
                            </p>

                            <h4>Điều khoản</h4>

                            <ol>
                                <li>
                                    <strong>Phạm vi áp dụng và Hướng dẫn sử dụng</strong>
                                    <p className='mt-3' style={{textAlign: 'justify'}}>— Đặt mua các sản phẩm được bán trên Minh Ký</p>
                                    <p style={{textAlign: 'justify'}}>— Mọi cá nhân có đầy đủ năng lực hành vi dân sự, độ tuổi từ 15 tuổi trở lên và có tài sản để thực hiện giao dịch mua hàng, hoặc có sự giám sát cả cha mẹ hay người giám hộ hợp pháp.</p>
                                    <p style={{textAlign: 'justify'}}>- Mọi tổ chức được thành lập và hoạt động hợp pháp theo quy định của pháp luật Việt Nam.</p>
                                </li>

                                <li>
                                    <strong>Các hình thành hợp đồng</strong>
                                    <p className='mt-3' style={{textAlign: 'justify'}}>—  Mọi thông tin về sản phẩm được đăng tải trên Minh Ký trong bất cứ trường hợp nào không được hiểu là đề nghị giao kết hợp đồng của Minh Ký tới Khách hàng. Quan hệ hợp đồng chỉ hình thành và có hiệu lực từ thời điểm Khách hàng đặt hàng đơn đặt hàng được chấp nhận dưới một trong 02 hình thức sau: thông báo gửi đến email mà Khách hàng cung cấp hoặc tin nhắn từ Minh Ký gửi đến số điện thoại của Khách hàng xác nhận đơn đặt hàng đã được xử lý thành công.</p>
                                    <p style={{textAlign: 'justify'}}>— Mọi cá nhân có đầy đủ năng lực hành vi dân sự, độ tuổi từ 15 tuổi trở lên và có tài sản để thực hiện giao dịch mua hàng, hoặc có sự giám sát cả cha mẹ hay người giám hộ hợp pháp.</p>
                                    <p style={{textAlign: 'justify'}}>- Khi muốn hủy đơn đặt hàng (nếu có) thì Quý khách phải thực hiện theo quy định tại Chính sách đổi trả đã đăng tải trên Minh Ký. Đơn hàng có thể bị hủy một phần hoặc toàn bộ theo xác nhận của Khách hàng với Bộ phận CSKH. Minh Ký có quyền hủy Đơn đặt hàng của Khách hàng trong một số trường hợp, được quy định tại Chính sách giao hàng. Khi đó, số tiền Khách hàng đã thanh toán tương ứng với phần giá trị đơn hàng bị hủy sẽ được hoàn trả lại cho Quý khách theo quy định.</p>
                                </li>
                                <li>
                                    <strong>Đặt hàng và xác nhận đơn hàng</strong>
                                    <p className='mt-3' style={{textAlign: 'justify'}}>—  Khi quý khách đặt hàng tại trang Minh Ký, chúng tôi sẽ tiếp nhận và gửi ngay đến Quý khách mã số đơn hàng. Sau đó, khách hàng cần thực hiện thêm một bước Xác nhận đơn hàng. Minh Ký chỉ xác nhận đơn hàng khi yêu cầu đặt hàng thỏa mãn các tiêu chí thực hiện đơn hàng tại Minh Ký.</p>
                                    <p style={{textAlign: 'justify'}}>— Mọi cá nhân có đầy đủ năng lực hành vi dân sự, độ tuổi từ 15 tuổi trở lên và có tài sản để thực hiện giao dịch mua hàng, hoặc có sự giám sát cả cha mẹ hay người giám hộ hợp pháp.</p>
                                    <p style={{textAlign: 'justify'}}>- Để đơn đặt hàng được xác nhận nhanh chóng, Quý khách vui lòng cung cấp đầy đủ và chính xác các thông tin liên quan đến việc giao nhận hàng, hoặc các điều khoản và điều kiện của chương trình khuyến mãi (nếu có) mà Quý khách tham gia.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageTerms;
