import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";
import { useRecoilValue, useRecoilState } from "recoil";
import { localeState } from "../atom/locale";
import Layout from "./Layout";
import HomePageOne from "./home/HomePageOne";
import HomePageTwo from "./home/HomePageTwo";
import languages from "../i18n";
import { useAuth } from "../atom/auth";
import { useInfoAppSetValue } from "../atom/info_app";
function Root() {
    const locale = useRecoilValue(localeState);
    const setInfoApp = useInfoAppSetValue();
    const version = new URLSearchParams(location.search).get("version");
    const env = new URLSearchParams(location.search).get("env");
    if(version) setInfoApp({env, version});
    useAuth();

    const { messages, direction } = languages[locale];

    return (
        <IntlProvider locale={locale} messages={messages}>
            <BrowserRouter basename={process.env.NODE_ENV === "production" && (navigator.userAgent).indexOf("Zalo") !== -1 ? `/zapps/${window.APP_ID}` : ""}>
                <HelmetProvider>
                    <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                    <Switch>
                        <Route
                            path="/home-two"
                            render={(props) => <Layout {...props} headerLayout="compact" homeComponent={HomePageTwo} />}
                        />
                        <Route
                            path="/"
                            render={(props) => <Layout {...props} headerLayout="default" homeComponent={HomePageOne} />}
                        />
                        <Redirect to="/" />
                    </Switch>
                </HelmetProvider>
            </BrowserRouter>
        </IntlProvider>
    );
}

Root.propTypes = {
    locale: PropTypes.string,
    localeChange: PropTypes.func.isRequired,
};

export default Root;
