import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const FILTER_STATE = atom({
    key: 'FILTER_STATE', // unique ID (with respect to other atoms/selectors)
    default: {
        category_id: '',
        sales_price: [0,0],
        brand_id: [],
        discount: '',
        size: '',
        name: '',
         // default value (aka initial value)
    }
});

export const useFilterState = () => useRecoilState(FILTER_STATE);

export const useFilterValue = () => useRecoilValue(FILTER_STATE);

export const useFilterSetValue = () => useSetRecoilState(FILTER_STATE);