export const validateDate = (date) => {
    let newDate;

    if (date) {
        newDate = new Date(date);
    } else {
        newDate = new Date();
    }

    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const year = newDate.getFullYear();
    const hours = String(newDate.getHours()).padStart(2, "0");
    const minutes = String(newDate.getMinutes()).padStart(2, "0");
    const seconds = String(newDate.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateVNPay = (dateTimeStr) => {
    const year = dateTimeStr.substr(0, 4);
    const month = dateTimeStr.substr(4, 2);
    const day = dateTimeStr.substr(6, 2);
    const hour = dateTimeStr.substr(8, 2);
    const minute = dateTimeStr.substr(10, 2);
    const second = dateTimeStr.substr(12, 2);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};