import React, { useMemo, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Pagination from "../shared/Pagination";
import { usePlatformValue } from "../../atom/platform";
import { HiOutlineArrowUturnDown, HiOutlineDocumentText, HiXMark } from "react-icons/hi2";
import { cancelOrderApi, getMyOrdersApi } from "../../services/api/order_api";
import { useRequest } from "ahooks";
import Currency from "../shared/Currency";
import { useAuthStateValue } from "../../atom/auth";
import { validateDate } from "../../utils/constant/convertDate";
import BlockLoader from "../blocks/BlockLoader";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Select from "react-select";

const AccountPageOrders = () => {
    const platform = usePlatformValue();
    const auth = useAuthStateValue();
    const history = useHistory();

    const [orderList, setOrderList] = useState();   // data search
    const [value, setValue] = useState();       //search order
    const [modalIsOpen, setModalIsOpen] = useState({ item: null, open: false });

    const {
        runAsync: getMyOrders,
        loading: getMyOrdersLoading,
        data: myOrders,
    } = useRequest(
        () =>
            getMyOrdersApi({
                token: auth.token,
            }),
        {
            debounceInterval: 250,
            cacheKey: "myOrders",
        }
    );

    const { runAsync: cancelOrder, loading: cancelOrderLoading } = useRequest(
        () =>
            cancelOrderApi(modalIsOpen.item.unique_id, {
                token: auth.token,
            }),
        {
            debounceInterval: 250,
            cacheKey: "cancel-order",
            manual: true,
        }
    );

    useEffect(() => {
        getMyOrders().then((res) => {
            setOrderList(res.data.orders);
        });
    }, []);

    const filterData = (option) => {
        setValue(option);
        let data = [];
        switch (option.value) {
            case "all":
                setOrderList(myOrders.data.orders);
                break;
            case "paid":
                data = myOrders.data.orders.filter(
                    (item) => item.payment_status == option.value && item.cancelled == 0
                );
                setOrderList(data);
                break;
            case "cancelled":
                data = myOrders.data.orders.filter((item) => item.cancelled == 1);
                setOrderList(data);
                break;
            default:
                data = myOrders.data.orders.filter((item) => item.order_status == option.value && item.cancelled == 0);
                setOrderList(data);
                break;
        }
    };

    const handleCancelOrder = () => {
        cancelOrder()
            .then((res) => {
                console.log(res);
                setModalIsOpen({ item: null, open: false });
                getMyOrders().then((res) => filterData(value));
                toast.success(`Hủy đơn thành công`, {
                    theme: "colored",
                    className: "mx-2 rounded mt-2",
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(`Hủy đơn thất bại`, {
                    theme: "colored",
                    className: "mx-2 rounded mt-2",
                });
            });
    };

    const handleItem = (listProduct) => {
        let newListProduct = [...listProduct];

        newListProduct.map((item) => {
            item.product.sales_price = item.unit_price;
        });
        return newListProduct;
    };

    const Status = ({ title, value, cancelled, paid }) => {
        let shipStatus;
        let paidStatus;
        let cancelStatus;

        const Tag = ({ title, color, bgColor }) => {
            return (
                <div className="mb-1">
                    <span
                        className="px-3 py-1"
                        style={{
                            borderRadius: 10,
                            fontSize: 12,
                            fontWeight: "500",
                            color: color,
                            backgroundColor: bgColor,
                        }}
                    >
                        {title}
                    </span>
                </div>
            );
        };

        if (cancelled) {
            cancelStatus = <Tag title="Đã hủy" color="#FFFFFF" bgColor="#ef4444" />;
        } else {
            if (value == "completed") {
                shipStatus = <Tag title="Hoàn thành" color="#389e0d" bgColor="#f6ffed" />;
            } else {
                switch (value) {
                    case "ordered":
                        shipStatus = <Tag title="Chờ xác nhận" color="#096dd9" bgColor="#e6f7ff" />;
                        break;
                    case "confirmed":
                        shipStatus = <Tag title="Đã xác nhận" color="#08979c" bgColor="#e6fffb" />;
                        break;
                    default:
                        shipStatus = <Tag title="Đang giao hàng" color="#f97316" bgColor="#ffedd5" />;
                        break;
                }
                switch (paid) {
                    case "paid":
                        paidStatus = <Tag title="Đã thanh toán" color="#389e0d" bgColor="#f6ffed" />;
                        break;
                    default:
                        paidStatus = <Tag title="Chưa thanh toán" color="black" bgColor="#e5e7eb" />;
                        break;
                }
            }
        }

        return (
            <div className="row mb-2">
                {title && <div className="col-4">{title}</div>}
                <div className="col-8" style={{ whiteSpace: "nowrap" }}>
                    <div className="col pl-0">
                        {shipStatus}
                        {paidStatus}
                        {cancelStatus}
                    </div>
                </div>
            </div>
        );
    };

    const CancelModal = () => {
        const customStyles = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black overlay
                zIndex: 9999,
            },
            content: {
                top: "40%",
                left: "50%",
                width: platform !== "web" ? "90%" : "30%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 10,
                maxHeight: "70%",
            },
        };

        return (
            <Modal
                isOpen={modalIsOpen.open}
                onRequestClose={() => setModalIsOpen({ item: null, open: false })}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="delete items in cart"
            >
                <p style={{ textAlign: "center" }}>
                    Bạn muốn <span style={{ fontWeight: "500", color: "red" }}>hủy đơn</span> hàng có mã{" "}
                    <span style={{ color: "black", fontWeight: "500" }}>#{modalIsOpen?.item?.unique_id}</span> không ?
                </p>
                <div className="w-100 row justify-content-between ml-0">
                    <button
                        type="button"
                        onClick={() => setModalIsOpen({ item: null, open: false })}
                        className="btn btn-secondary rounded"
                    >
                        Không
                    </button>
                    <button type="button" onClick={() => handleCancelOrder()} className="btn btn-danger rounded mr-3">
                        Hủy đơn
                    </button>
                </div>
            </Modal>
        );
    };

    if (platform !== "web") {
        const Card = ({ item }) => {
            const Item = ({ title, value }) => {
                return (
                    <div className="row mb-2">
                        <div className="col-4">{title}</div>
                        <div className="col-8" style={{ color: title == "#Mã đơn:" ? "blue" : "black", fontSize: 16 }}>
                            <span>{value}</span>
                        </div>
                    </div>
                );
            };

            return (
                <div className="card p-3 rounded mb-3 shadow-sm">
                    <Item title="#Mã đơn:" value={item.unique_id} />
                    <Item title="Thời gian:" value={validateDate(item.order_date)} />
                    <Status
                        title="Trạng thái"
                        value={item.order_status}
                        cancelled={item.cancelled}
                        paid={item.payment_status}
                    />
                    <Item title="Địa chỉ:" value={item.shipping_address.shipping_address} />
                    <Item
                        title="Tổng tiền"
                        value={
                            <span style={{ color: "black", fontWeight: "500" }}>
                                <Currency value={item.total} />
                            </span>
                        }
                    />
                    <div className="d-flex row mt-2 border-top pt-3">
                        <div className="col">
                            <Link
                                to={{ pathname: `/account/orders/${item.unique_id}`, state: { order: item } }}
                                className="row h-100 justify-content-center align-items-center"
                                style={{ color: "#075985", fontWeight: "500" }}
                            >
                                <HiOutlineDocumentText size={24} />
                                <div style={{ marginLeft: 6 }}>Xem chi tiết</div>
                            </Link>
                        </div>
                        <div style={{ backgroundColor: "#d1d5db", width: 1, height: 32 }} />
                        <div className="col">
                            <div className="btn d-flex justify-content-center align-items-center">
                                {item.payment_status !== "paid" &&
                                item.cancelled == 0 &&
                                item.order_status !== "delivered" ? (
                                    <div style={{ color: "red" }}>
                                        <div
                                            onClick={() => setModalIsOpen({ item: item, open: true })}
                                            className="flex row"
                                        >
                                            <HiXMark size={24} />
                                            <div style={{ marginLeft: 6 }}>Hủy đơn</div>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to={{ pathname: "/shop/checkout", state: { order: handleItem(item.items) } }}>
                                        <div className="flex row">
                                            <HiOutlineArrowUturnDown size={24} />
                                            <div style={{ marginLeft: 6 }}>Mua lại</div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const Search = () => {
            const options = [
                { value: "all", label: "Tất cả đơn hàng" },
                { value: "ordered", label: "Chờ xác nhận" },
                { value: "confirmed", label: "Đã xác nhận" },
                { value: "delivered", label: "Đang giao hàng" },
                { value: "cancelled", label: "Đã hủy" },
                { value: "paid", label: "Đã thanh toán" },
                { value: "completed", label: "Hoàn thành" },
            ];

            const customStyles = {
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#EEEEEE" : "white",
                    color: state.isSelected ? "#FF9900" : "black",
                }),
            };

            return (
                <div className="mb-3">
                    <Select
                        options={options}
                        placeholder="Chọn trạng thái đơn hàng"
                        onChange={(data) => filterData(data)}
                        value={value}
                        defaultValue={options[0]}
                        ariaHideApp={false}
                        styles={customStyles}
                    />
                </div>
            );
        };

        return (
            <div>
                <Helmet>
                    <title>{`Đơn hàng của tôi`}</title>
                </Helmet>

                {getMyOrdersLoading && <BlockLoader />}

                {myOrders?.data?.orders?.length > 0 && <Search />}

                {useMemo(() => orderList?.map((item, index) => <Card key={index} item={item} />), [orderList])}

                {orderList?.length < 1 && (
                    <div className="block block-empty">
                        <div className="container">
                            <div className="block-empty__body">
                                <div>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/4318/4318459.png"
                                        style={{ height: 48 }}
                                    />
                                </div>
                                <div className="block-empty__message mt-3">Danh sách đơn hàng trống</div>
                            </div>
                        </div>
                    </div>
                )}

                {myOrders?.data?.orders?.length < 1 && (
                    <div className="block block-empty">
                        <div className="container">
                            <div className="block-empty__body">
                                <div>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/4318/4318459.png"
                                        style={{ height: 48 }}
                                    />
                                </div>
                                <div className="block-empty__message mt-3">Bạn chưa có đơn hàng nào,</div>
                                <div className="block-empty__message">Hãy đặt hàng ngay nào!</div>
                                <div className="block-empty__actions">
                                    <Link to="/" className="btn btn-primary btn-sm shadow-sm">
                                        Đặt hàng ngay
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <CancelModal />
            </div>
        );
    } else {
        const itemsPerPage = 4; // number item in one page
        const totalPages = Math.ceil(myOrders?.data.orders.length / itemsPerPage) || 1;
        const [currentPage, setCurrentPage] = useState(1);
        const [positionSlice, setPositionSlice] = useState({
            startIndex: 0,
            endIndex: itemsPerPage,
        });

        const handlePage = (newPage) => {
            setCurrentPage(newPage);
            const startIndex = (newPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            setPositionSlice({ startIndex: startIndex, endIndex: endIndex });
        };

        const Card = ({ item }) => {
            return (
                <tr>
                    <td>
                        <Link to={{ pathname: `/account/orders/${item.unique_id}`, state: { order: item } }}>
                            {`#${item.unique_id}`}
                        </Link>
                    </td>
                    <td>{validateDate(item.order_date)}</td>
                    <td>
                        <Status value={item.order_status} cancelled={item.cancelled} paid={item.payment_status} />
                    </td>
                    <td>
                        <Currency value={item.total} />
                    </td>
                    <td>
                        <div>
                            {item.payment_status !== "paid" &&
                            item.cancelled == 0 &&
                            item.order_status !== "delivered" ? (
                                <button
                                    className="btn btn-danger btn-sm w-100"
                                    onClick={() => setModalIsOpen({ item: item, open: true })}
                                >
                                    <span style={{ whiteSpace: "nowrap" }}>Hủy đơn</span>
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm w-100"
                                    onClick={() =>
                                        history.push({
                                            pathname: "/shop/checkout",
                                            state: { order: handleItem(item.items) },
                                        })
                                    }
                                >
                                    <span style={{ whiteSpace: "nowrap" }}>Mua lại</span>
                                </button>
                            )}
                        </div>
                    </td>
                </tr>
            );
        };

        return (
            <div className="card">
                <Helmet>
                    <title>{`Đơn hàng của tôi`}</title>
                </Helmet>
                {getMyOrdersLoading && <BlockLoader />}
                {myOrders?.data.orders.length > 0 ? (
                    <>
                        <div className="card-header">
                            <h5>Đơn hàng của tôi</h5>
                        </div>
                        <div className="card-divider" />
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Mã đơn hàng</th>
                                            <th>Ngày đặt</th>
                                            <th>Trạng thái</th>
                                            <th>Tổng tiền</th>
                                            <th>Tùy chọn</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myOrders?.data.orders
                                            .slice(positionSlice.startIndex, positionSlice.endIndex)
                                            .map((order, index) => (
                                                <Card key={index} item={order} />
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider" />
                        <div className="card-footer">
                            <Pagination
                                current={currentPage}
                                total={totalPages}
                                onPageChange={(newPage) => handlePage(newPage)}
                            />
                        </div>
                    </>
                ) : (
                    <div className="block block-empty">
                        <div className="container">
                            <div className="block-empty__body">
                                <div>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/4318/4318459.png"
                                        style={{ height: 48 }}
                                    />
                                </div>
                                <div className="block-empty__message mt-3">Bạn chưa có đơn hàng nào,</div>
                                <div className="block-empty__message">Hãy đặt hàng ngay nào!</div>
                                <div className="block-empty__actions">
                                    <Link to="/" className="btn btn-primary btn-sm shadow-sm">
                                        Đặt hàng ngay
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <CancelModal />
            </div>
        );
    }
};

export default AccountPageOrders;
