// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner() {
    return (
        <div className="block block-banner" style={{marginTop: 50, marginBottom: 50}}>
            <div className="container">
                <Link to="/shop/category-list" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: 'url("https://i.imgur.com/KDyo6G2.png")' }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: 'url("https://i.imgur.com/Gb7hJUu.png")' }}
                    />
                    <div className="block-banner__title" style={{color: '#ffcc00'}}>
                        Cam kết mang lại những sản phẩm chất lượng
                    </div>
                    <div className="block-banner__text" style={{color: '#ffcc00'}}>
                        Minh Ký là đơn vị phân phối các sản phẩm điện lạnh, cơ khí, điện tử, máy khoan...
                    </div>
                    <div className="block-banner__button">
                        <span className="btn btn-sm btn-primary">Xem tất cả sản phẩm</span>
                    </div>
                </Link>
            </div>
        </div>
    );
}
