import { ID_COMPANY } from "../../utils/constant";

export const PATH = {

    //CATEGORY
    list_categories: `/front/categories/2`,
    update_categories: "/update-categories",

    //USERS
    update_user: '/front/sign-up-zalo',
    sign_up_web: '/front/signup',
    update_user_zalo: '/front/profile',
    login_web: '/front/login',
    get_profile: '/front/user',

    //PRODUCTS
    get_top_selling: `/front/top-selling-products/${ID_COMPANY}`,
    get_product_by_page: `/front/all-products-limit/${ID_COMPANY}&50?page=`,
    get_product_by_category: `/front/all-products/${ID_COMPANY}`,
    filter_product : `/front/all-products-limit/${ID_COMPANY}&50?filters=`,

    //ORDER
    checkout_order: `/front/self/checkout-orders`,
    checkout_order_vnpay: `/front/self/checkout-orders/vnpay`,
    get_my_orders: `/front/self/orders`,
    cancel_order: `/front/self/cancel-order/unique_id=`,
    save_order: `/front/self/checkout-orders/vnpay-callback`,

    //ADDRESS
    get_address_shipping: '/front/self/address?fields=id,xid,name,email,phone,address,shipping_address,city,state,country,zipcode',
    add_address_shipping: '/front/self/address',
    update_address_shipping: '/front/self/address/',
    delete_address_shipping: '/front/self/address/',

    //WAREHOUSE
    get_warehouse_by_company_id: `/warehouses/${ID_COMPANY}`,

    //BRAND
    get_brand_by_page: `/list-brands?fields=id,name,xid&offset=`,

    //RATING
    get_rating: `/front/response-rating?fields=id,xid,rating,user_comment,created_at,order=id,product=id,user=id&filters=`,
    add_rating: `/front/self/response-rating`,

    //BANNER & PRODUCT FEATURES
    get_banner_features: '/front/homepage/',
};