// recoil
import { getWarehouseByCompanyIdApi } from "../services/api/warehouse_api";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useRequest } from "ahooks";
import { getBannerFeatureApi } from "../services/api/banner_feature_api";
import { useSetBannerFeatureValue } from "./banner_feature";

const WAREHOUSE_STATE = atom({
    key: 'WAREHOUSE_STATE', // unique ID (with respect to other atoms/selectors)
    default: [{
        company_id: 1,
        logo: '',
        dark_logo: '',
        name: '',
        slug: '',
        email: '',
        phone: '',
        show_email_on_invoice: 0,
        show_phone_on_invoice: 0,
        address: null,
        terms_condition: '',
        bank_details: null,
        signature: null,
        online_store_enabled: 1,
        customers_visibility: '',
        suppliers_visibility: '',
        products_visibility: '',
        default_pos_order_status: '',
        show_mrp_on_invoice: 1,
        show_discount_tax_on_invoice: 1,
        created_at: '2023-06-07T20:15:40+00:00',
        updated_at: '2023-06-07T20:15:40+00:00',
        xid: '',
        logo_url: '',
        dark_logo_url: '',
        signature_url: null
    }], // default value (aka initial value)
});

export const useWarehouseState = () => useRecoilState(WAREHOUSE_STATE);

export const useWarehouseValue = () => useRecoilValue(WAREHOUSE_STATE);

export const useWarehouseSetValue = () => useSetRecoilState(WAREHOUSE_STATE);

export const useWarehouse = () => {
    const [warehouse, setWarehouse] = useWarehouseState();
    const setBannerFeature = useSetBannerFeatureValue();
    const { runAsync, loading } = useRequest(() => getWarehouseByCompanyIdApi(), {
        debounceInterval: 250,
        cacheKey: "warehouse",
        manual: true,
    });
    const { runAsync: getBannerFeature } = useRequest((slug_company) => getBannerFeatureApi(slug_company), {
        debounceInterval: 250,
        cacheKey: "banner-feature",
        manual: true,
    });
    
    const getWarehouse = async() => {
        await runAsync().then((res) => {
            setWarehouse(res.data.data);
            
            getBannerFeature((res.data.data[0]).slug).then(res => {
                setBannerFeature(res.data);
            }).catch((err) => console.log(err));
            
        }).catch((err) => console.log(err));
    }
    
    return {getWarehouse};
}