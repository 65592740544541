// react
import React from "react";

const TextInput = (props) => {
    const { label, register, type, rules, errors, icon, } = props;
    return (
        <div>
            <div className="d-flex align-items-center mb-2">
                {icon}
                <div style={{ fontSize: 16, fontWeight: "500", marginLeft: 4 }}>{label}</div>
            </div>
            <input type={type} {...props} {...register(`${rules}`)} className="form-control w-full" />
            <p style={{ color: "red", marginTop: 2 }}>{errors}</p>
        </div>
    );
};

export default TextInput;
