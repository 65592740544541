 // react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import { ArrowRoundedRight6x9Svg } from '../../svg';
import { formatName } from '../../utils/constant/formatName';
import { useProductByCategoryState } from '../../atom/product_by_category';
import { productsByCategory } from '../../services/api/product_api';
import { useRequest } from 'ahooks';

function MenuCategories(props) {
    const {
        layout,
        withIcons,
        items,
        onClick,
        megaMenu,
       
    } = props;

    const [, setProductByCategory] = useProductByCategoryState();
    const { data, loading, runAsync } = useRequest((slug) => productsByCategory(slug, 1), {
        debounceWait: 250,
        cacheKey: "products-category-cache",
        manual: true,
    });
    const handleSlug = async(idSlug) => {
        await runAsync(idSlug)
            .then((res) => {
                console.log(res.data.allProducts);
                setProductByCategory(res.data.allProducts);
            })
            .catch((err) => console.log(err));
    }
    const renderLink = (item, content) => {
        let link;
    
        if (item.slug) {
            link = (
                <AppLink
                    {...item.props}
                    to={`/category/${item.xid}`}
                    onClick={() => handleSlug(`&${item.xid}`)}
                >
                    {content}
                </AppLink>
            );
        } else {
            link = <button type="button" onClick={() => onClick(item)}>{content}</button>;
        }

        return link;
    };
    const itemsList = items.map((item, index) => {
        let arrow;
        let submenu;
        let icon;
        if (item.children && item.children.length) {
            arrow = <ArrowRoundedRight6x9Svg className="menu__arrow" />;
        }

        if (item.children && item.children.length) {
            submenu = (
                <div className="menu__submenu">
                    <MenuCategories items={item.children} />
                </div>
            );
        }

        if (withIcons && item.icon) {
            icon = (
                <div className="menu__icon">
                    <img src={item.icon} srcSet={item.icon_srcset} alt="" />
                </div>
            );
        }

        return (
            <li key={index}>
                {renderLink(item, (
                    <React.Fragment>
                        {icon}
                        {formatName(item.name)}
                        {arrow}
                    </React.Fragment>
                ))}
                {submenu}
            </li>
        );
    });

    const classes = classNames(`menu menu--layout--${layout}`, {
        'menu--with-icons': withIcons,
    });

    return (
        <ul className={classes}>
            {itemsList}
        </ul>
    );
}

MenuCategories.propTypes = {
    /** one of ['classic', 'topbar'] (default: 'classic') */
    layout: PropTypes.oneOf(['classic', 'topbar']),
    /** default: false */
    withIcons: PropTypes.bool,
    /** array of menu items */
    items: PropTypes.array,
    /** callback function that is called when the item is clicked */
    onClick: PropTypes.func,
};

MenuCategories.defaultProps = {
    layout: 'classic',
    withIcons: false,
    items: [],
    onClick: () => {},
};

export default MenuCategories;
