export default [
    {
        title: 'Trang chủ',
        url: '',
    },
    /* {
        title: 'Danh mục',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Power Tools',
                                url: '',
                                links: [
                                    { title: 'Engravers', url: '' },
                                    { title: 'Wrenches', url: '' },
                                    { title: 'Wall Chaser', url: '' },
                                    { title: 'Pneumatic Tools', url: '' },
                                ],
                            },
                            {
                                title: 'Machine Tools',
                                url: '',
                                links: [
                                    { title: 'Thread Cutting', url: '' },
                                    { title: 'Chip Blowers', url: '' },
                                    { title: 'Sharpening Machines', url: '' },
                                    { title: 'Pipe Cutters', url: '' },
                                    { title: 'Slotting machines', url: '' },
                                    { title: 'Lathes', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Hand Tools',
                                url: '',
                                links: [
                                    { title: 'Screwdrivers', url: '' },
                                    { title: 'Handsaws', url: '' },
                                    { title: 'Knives', url: '' },
                                    { title: 'Axes', url: '' },
                                    { title: 'Multitools', url: '' },
                                    { title: 'Paint Tools', url: '' },
                                ],
                            },
                            {
                                title: 'Garden Equipment',
                                url: '',
                                links: [
                                    { title: 'Motor Pumps', url: '' },
                                    { title: 'Chainsaws', url: '' },
                                    { title: 'Electric Saws', url: '' },
                                    { title: 'Brush Cutters', url: '' },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    }, */
    /* {
        title: 'Danh mục',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Power Tools',
                                url: '',
                                links: [
                                    { title: 'Engravers', url: '' },
                                    { title: 'Wrenches', url: '' },
                                    { title: 'Wall Chaser', url: '' },
                                    { title: 'Pneumatic Tools', url: '' },
                                ],
                            },
                            {
                                title: 'Machine Tools',
                                url: '',
                                links: [
                                    { title: 'Thread Cutting', url: '' },
                                    { title: 'Chip Blowers', url: '' },
                                    { title: 'Sharpening Machines', url: '' },
                                    { title: 'Pipe Cutters', url: '' },
                                    { title: 'Slotting machines', url: '' },
                                    { title: 'Lathes', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Hand Tools',
                                url: '',
                                links: [
                                    { title: 'Screwdrivers', url: '' },
                                    { title: 'Handsaws', url: '' },
                                    { title: 'Knives', url: '' },
                                    { title: 'Axes', url: '' },
                                    { title: 'Multitools', url: '' },
                                    { title: 'Paint Tools', url: '' },
                                ],
                            },
                            {
                                title: 'Garden Equipment',
                                url: '',
                                links: [
                                    { title: 'Motor Pumps', url: '' },
                                    { title: 'Chainsaws', url: '' },
                                    { title: 'Electric Saws', url: '' },
                                    { title: 'Brush Cutters', url: '' },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    }, */
    {
        title: 'Sản phẩm',
        // url: '/shop/category-grid-3-columns-sidebar',
        url: '/category/all-products',
        submenu: {
            type: 'menu',
            menu: [
                // {
                //     title: 'Shop Grid',
                //     url: '/shop/category-grid-3-columns-sidebar',
                //     submenu: [
                //         { title: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
                //         { title: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
                //         { title: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
                //     ],
                // },
                { title: 'Danh sách sản phẩm', url: '/category/all-products' },
                // { title: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
                // {
                //     title: 'Product',
                //     url: '/shop/product-standard',
                //     submenu: [
                //         { title: 'Product', url: '/shop/product-standard' },
                //         { title: 'Product Alt', url: '/shop/product-columnar' },
                //         { title: 'Product Sidebar', url: '/shop/product-sidebar' },
                //     ],
                // },
                { title: 'Danh sách yêu thích', url: '/shop/wishlist' },
                { title: 'Giỏ hàng', url: '/shop/cart' },
                // { title: 'Đặt hàng', url: '/shop/checkout' },
                // { title: 'Order Success', url: '/shop/checkout/success' },
                // { title: 'Compare', url: '/shop/compare' },
                // { title: 'Track Order', url: '/shop/track-order' },
            ],
        },
    },
    {
        title: 'Tài khoản',
        url: '/account',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Tài khoản của tôi', url: '/account/dashboard' },
                { title: 'Chỉnh sửa thông tin', url: '/account/profile' },
                { title: 'Đơn hàng của tôi', url: '/account/orders' },
                // { title: 'Chi tiết đơn hàng', url: '/account/orders/5' },
                { title: 'Danh sách địa chỉ', url: '/account/addresses' },
                // { title: 'Chỉnh sửa địa chỉ', url: '/account/addresses/edit' },
                { title: 'Thay đổi mật khẩu', url: '/account/password' },
            ],
        },
    },
    // {
    //     title: 'Tin tức',
    //     url: '/blog/category-classic',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Blog Classic', url: '/blog/category-classic' },
    //             { title: 'Blog Grid', url: '/blog/category-grid' },
    //             { title: 'Blog List', url: '/blog/category-list' },
    //             { title: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //             { title: 'Post Page', url: '/blog/post-classic' },
    //             { title: 'Post Without Sidebar', url: '/blog/post-full' },
    //         ],
    //     },
    // },
    {
        title: 'Liên hệ',
        url: '/contact-us',
    },
];
