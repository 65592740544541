import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const INFO_APP = atom({
    key: 'INFO_APP', // unique ID (with respect to other atoms/selectors)
    default: {
        env: null,
        version: null,
        
         // default value (aka initial value)
    }
});

export const useInfoAppState = () => useRecoilState(INFO_APP);

export const useInfoAppValue = () => useRecoilValue(INFO_APP);

export const useInfoAppSetValue = () => useSetRecoilState(INFO_APP);