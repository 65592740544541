import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

export const ORDER_STATE = atom({
    key: "ORDER_STATE", // unique ID (with respect to other atoms/selectors)
    default: [],
    // default value (aka initial value)
});

export const useOrder = () => {
    const [order, setOrder] = useRecoilState(ORDER_STATE);
    const addToOrder = (product, quantity) => {
        if (order.length < 1) {
            setOrder([{ product: product, quantity: quantity }]);
        } else {
            const existingItem = order.find((orderItem) => orderItem.product.product_id === product.product_id);
            if (existingItem) {
                let updatedOrder = order.map((orderItem) =>
                    orderItem.product.product_id === product.product_id
                        ? { product: product, quantity: quantity }
                        : orderItem
                );
                setOrder(updatedOrder);
            } else {
                let updatedOrder = [...order, { product: product, quantity: quantity }];
                setOrder(updatedOrder);
            }
        }
    };

    const removeFromOrder = (itemId) => {
        let updatedOrder = order.filter((x) => x.product.product_id !== itemId);
        setOrder(updatedOrder);
    };

    const getTotalPrice = () => {
        let totalAmount = order.reduce((accumulator, item) => {
            return accumulator + item.product.sales_price * item.quantity;
        }, 0);
        return totalAmount;
    };

    const isItemInOrder = (itemId) => {
        const index = order.findIndex((item) => item.product.product_id === itemId);
        return index != -1;
    };

    return { order, setOrder, addToOrder, removeFromOrder, getTotalPrice, isItemInOrder };
};

export const useOrderState = () => useRecoilState(ORDER_STATE);

export const useOrderValue = () => useRecoilValue(ORDER_STATE);

export const useSetOrderValue = () => useSetRecoilState(ORDER_STATE);
