// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

function SitePageContactUs() {
    const breadcrumb = [
        { title: 'Trang chủ', url: '/' },
        { title: 'Liên hệ', url: '/contact-us' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Liên hệ — ${theme.name}`}</title>
            </Helmet>
            <div style={{marginTop: 20}}>
                <PageHeader header="Liên hệ" breadcrumb={breadcrumb} />
            </div>

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Thông tin địa chỉ</h4>

                                        <div className="contact-us__address">
                                            <p>

                                                <strong>Cửa Hàng Minh Ký</strong>
                                                <br />
                                                Cửa hàng Điện Máy Nông Cơ Minh Ký mang đến những sản phẩm chính hãng, chất lượng với giá cả và chi phí phải chăng.
                                            </p>
                                            <ul className="footer-contacts__contacts">
                                                <strong>Địa chỉ</strong>
                                                <li className=''>
                                                    <i className="footer-contacts__icon fas fa-globe-americas" />
                                                    {theme.contacts.address}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                                                    {`${theme.contacts.phone}`}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon far fa-clock" />
                                                    Cả ngày 10:00pm - 7:00pm
                                                </li>
                                            </ul>
                                            <br />
                                            <p>
                                                <strong>Giờ mở cửa</strong>
                                                <br />
                                                Thứ 2 đến Thứ 6: 10:00pm - 7:00pm
                                                <br />
                                                Thứ 7 đến Chủ Nhật: 10:00pm - 7:00pm
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Liên hệ với chúng tôi</h4>

                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Họ tên</label>
                                                    <input type="text" id="form-name" className="form-control" placeholder="Nhập họ tên.." />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Địa chỉ email</label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        className="form-control"
                                                        placeholder="Điền email.."
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Số điện thoại</label>
                                                <input type="number" id="form-subject" className="form-control" placeholder="Điền số điện thoại.." />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">Tin nhắn</label>
                                                <textarea id="form-message" className="form-control" rows="4" />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Gửi ngay</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='block'>
                <BlockMap />
            </div>

        </React.Fragment>
    );
}

export default SitePageContactUs;
