import React, { useMemo, useEffect, useState } from "react";
import Modal from "react-modal";
import SocialLinks from "./SocialLinks";
import { useSetAuthState } from '../../atom/auth.js'
import { openMiniApp } from "zmp-sdk/apis";
import { HiXMark } from "react-icons/hi2";

export const ModalMiniApp = () => {

    const [modalIsOpen, setIsOpen] = useState(true);
    const setModalState = useSetAuthState();
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black overlay
            zIndex: 99999,
        },
        content: {
            //top: "35%",
            left: "50%",
            width: "100%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 0,
        },
    };
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {
                setIsOpen(false);
                setModalState((data) => ({ ...data, modalVisible: false }));
            }}
            style={customStyles}
            contentLabel="Addresses list modal"
            ariaHideApp={false}
        >
            <div className="p-2 rounded" style={{backgroundColor: '#faecbe'}}>

                <div className="d-flex justify-content-between align-items-center mx-3">
                    <div className="d-flex row">
                        <img src="https://i.imgur.com/RALnFuh.png" style={{ width: 70, height: 70 }} className='object-fit-contain' />
                        <div>
                            <p style={{ fontWeight: '500', marginTop: 10 }}>Cửa hàng Minh Ký</p>
                            <p style={{ marginTop: -15, fontSize: 15 }}>Thương mại điện tử</p>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#ffd333', borderRadius: 16 }} className="p-2"
                        onClick={async () => {
                            setIsOpen(false);
                            setModalState((data) => ({ ...data, modalVisible: false }));
                        }}>
                        <a href="https://zalo.me/s/3278898505964188118" className="" style={{ fontWeight: 'bold', color: '#2f3030' }}>
                            Open Mini App
                        </a>
                    </div>

                </div>
            </div>
        </Modal>
    );
};