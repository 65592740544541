// react
import React from 'react';

export default function BlockLoader() {
    return (
        <div className="site-preloader d-flex">
            <div>
                <img src="https://i.imgur.com/tBlpuBH.png" alt="" style={{ width: 150, height: 150, resize: 'contain', marginTop: -200}}/>
            </div>
            <div className="block-loader__spinner" />
        </div>
    );
}
