// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
// application
import ProductCard from "../shared/ProductCard";
import { Filters16Svg, LayoutGrid16x16Svg, LayoutGridWithDetails16x16Svg, LayoutList16x16Svg } from "../../svg";
import { usePlatformValue } from "../../atom/platform";
import { filterProductApi, getProductsApi, productsByCategory } from "../../services/api/product_api";
import { useRequest } from "ahooks";
import { useCategoriesPageState } from "../../atom/categories_page";
import { Loading } from "../loading/loading";
import { useProductByCategoryState } from "../../atom/product_by_category";
import { useRef } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { HiOutlineFilter } from "react-icons/hi";
import { sidebarOpen, sidebarClose } from '../../store/sidebar';
import CategorySidebar from "../shop/CategorySidebar";
import CategorySidebarItem from "../shop/CategorySidebarItem";
import WidgetFilters from "../widgets/WidgetFilters";
import { stateFilter, valueFilter } from "./filter";
import { useHistory, useLocation } from 'react-router-dom';
import BlockLoader from "../blocks/BlockLoader";
import { Helmet } from "react-helmet-async";
import { useFilterState } from "../../atom/filter";

function ProductsByFilter(props) {
    const {
        isLoading,
        filters,
        layout: propsLayout,
        sidebarClose,
        sidebarOpen,
    } = props;
    const location = useLocation();
    const history = useHistory();
    const { data } = location.state;
    // console.log(data);
    const grid = 'grid-3-sidebar'
    const [layout, setLayout] = useState('grid');
    const offcanvas = 'mobile';
    const platform = usePlatformValue();
    /////////////
    const [currentPage, setCurrentPage] = useCategoriesPageState();
    const [inputValue, setInputValue] = useState("");
    const [filterSearch, setFilterSearch] = useState("");

    const [filter, setFilter] = useFilterState();

    useEffect(() => {
        setFilter({});
    }, []);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const count = useRef(0);
    /* const { loading, runAsync: getProductsByCategory } = useRequest(
        () => productsByCategory(filterSearch, currentPage.pageProductByFilter),
        {
            debounceWait: 250,
            cacheKey: "products-cache",
            manual: true,
            onSuccess: (res) => {
                let newData = [...dataProducts.data, ...res.data.allProducts.data];
                setProductByCategory((products) => ({
                    ...products,
                    current_page: res.data.allProducts.current_page,
                    data: newData,
                    last_page: res.data.allProducts.last_page,
                }));
                count.current++;
            },
        }
    ); */
    const { loading: loadingProduct, runAsync: getProductsByFilter } = useRequest(
        () => filterProductApi(filterSearch),
        {
            debounceWait: 250,
            cacheKey: "filter-cache",
            manual: true,
        }
    );


    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

        // Check if the user has scrolled to the bottom of the page
        if (!loading && scrollTop + clientHeight >= scrollHeight - 15) {
            console.log(count.current, dataProducts.last_page);
            if (count.current < dataProducts.last_page) {
                console.log('scroll-category');
                fetchData();
            }
        }
    };

    const fetchData = async () => {
        await getProductsByCategory()
            .then((res) => {
                setCurrentPage((current) => ({
                    ...current,
                    pageProductByCategory: res.data.allProducts.current_page + 1,
                }));
            })
            .catch((err) => console.log(err));
    };

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        let filterQuery = encodeURIComponent(`name lk %${inputValue}%`);
        setFilterSearch(filterQuery);
        if (inputValue.length > 0) {
            await getProductsByFilter().then(res => {
                //console.log(res);
                history.push({
                    pathname: "/product/filter", state: {
                        data: res.data.allProducts,
                    }
                });
            }).catch(err => console.log(err));
        }
    };
    /* useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [count.current]); */


    /* const filtersCount = Object.keys(filters)
        .map((x) => filters[x])
        .filter((x) => x).length; */
    let viewModes = [
        { key: "grid", title: "Grid", icon: <LayoutGrid16x16Svg /> },
        { key: "grid-with-features", title: "Grid With Features", icon: <LayoutGridWithDetails16x16Svg /> },
        { key: "list", title: "List", icon: <LayoutList16x16Svg /> },
    ];

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    viewModes = viewModes.map((viewMode) => {
        const className = classNames("layout-switcher__button", {
            "layout-switcher__button--active": layout === viewMode.key,
        });

        return (
            <button
                key={viewMode.key}
                title={viewMode.title}
                type="button"
                className={className}
                onClick={() => setLayout(viewMode.key)}
            >
                {viewMode.icon}
            </button>
        );
    });

    let listProduct;

    if (data?.data?.length > 0) {
        listProduct = data?.data?.map((item, index) => (
            <div key={index} className="products-list__item">
                <ProductCard product={item} layout={layout} />
            </div>
        ));
    }



    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    let content;

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <WidgetFilters
                    title="Filters"
                    offcanvas={offcanvas}
                    filters={stateFilter}
                    values={valueFilter}
                />
            </CategorySidebarItem>
        </CategorySidebar>
    );


    if (platform !== "web") {
        content = (
            <div className="products-view__content">
                {location.pathname !== '/' &&
                    <div className="products-view__options">
                        {sidebarComponent}
                        <div className="d-flex align-items-center mt-3">
                            <div className="p-2 rounded" style={{ backgroundColor: '#F0F0F0' }} onClick={() => { sidebarOpen() }}>
                                <HiOutlineFilter size={26} />
                            </div>
                            <div className=" d-flex align-items-center w-100 ml-2">
                                <div className="border rounded-lg px-2 w-100 mr-2">
                                    <input
                                        type="text"
                                        placeholder="Tìm kiếm hơn 1000 sản phẩm..."
                                        value={inputValue}
                                        onChange={(x) => setInputValue(x.target.value)}
                                        onKeyUp={handleKeyUp}
                                        className="w-100"
                                        style={{ height: 40, border: "none", outline: "none", fontSize: 14 }}
                                    />
                                </div>
                                <div
                                    className="rounded-lg align-content-center"
                                    style={{ backgroundColor: "#ffd333", height: 40, padding: 10 }}
                                    onClick={() => handleSearch()}
                                >
                                    <HiMagnifyingGlass size={22} />
                                </div>
                            </div>
                        </div>
                        <div style={{ fontSize: 18, fontWeight: "500", marginTop: 20, marginLeft: 10 }}>
                            Danh sách sản phẩm
                        </div>
                    </div>
                }
                {listProduct?.length > 0 ?
                    (<div
                        className="products-view__list products-list"
                        data-layout={layout !== "list" ? grid : layout}
                        data-with-features={layout === "grid-with-features" ? "true" : "false"}
                    >
                        <div className="products-list__body">{listProduct}</div>
                    </div>)
                    :
                    (<div className="products-view__empty">
                        <div>
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/5058/5058046.png"
                                style={{ height: 48 }}
                            />
                        </div>
                        <div className="products-view__empty-subtitle mt-2">Không tìm thấy sản phẩm nào</div>
                    </div>)
                }
            </div>
        );
    } else {
        content = (
            <div className="products-view__content z-50">
                {location.pathname !== '/' &&
                    <div className="">
                        <div className={viewOptionsClasses}>
                            <div className={`${windowSize.width < 984 ? 'd-flex' : 'd-none'} justify-content-between w-100 mt-3`}>
                                <div className="view-options__filters-button rounded-lg">
                                    <button type="button" className="filters-button h-100" onClick={() => sidebarOpen()}>
                                        <Filters16Svg className="filters-button__icon mr-0" />
                                    </button>
                                </div>
                                <div
                                    className="d-flex border w-100 rounded-lg"
                                >
                                    <input
                                        type="text"
                                        placeholder="Tìm kiếm hơn 1000 sản phẩm..."
                                        value={inputValue}
                                        onChange={(x) => setInputValue(x.target.value)}
                                        onKeyUp={handleKeyUp}
                                        style={{
                                            height: 40,
                                            width: "100%",
                                            marginLeft: 4,
                                            border: "none",
                                            outline: "none",
                                            marginLeft: 10,
                                        }}
                                    />
                                    <div className="rounded-lg align-content-center" style={{ backgroundColor: '#ffd333', height: 40, padding: 10, cursor: 'pointer' }} onClick={() => handleSearch()}>
                                        <HiMagnifyingGlass size={22} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="view-options__legend">
                            {`Showing ${productsList.from}—${productsList.to} of ${productsList.total} products`}
                        </div> */}
                        </div>
                    </div>
                }
                <div
                    className="products-view__list products-list"
                    data-layout={layout !== "list" ? grid : layout}
                    data-with-features={layout === "grid-with-features" ? "true" : "false"}
                >

                    <div className="products-list__body mt-3">
                        {listProduct}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Tìm kiếm</title>
            </Helmet>

            <div className="container" style={{ marginTop: '20' }}>
                {platform == "web" &&
                    <div style={{ marginTop: 20 }} className="px-2">
                        <h3>Danh sách tìm kiếm</h3>
                        <p style={{ fontSize: 17 }}>Minh Ký chúng tôi cam kết mang lại những sản phẩm chất lượng nhất</p>
                    </div>
                }
                <div className={`shop-layout shop-layout--sidebar--start px-2`}>
                    {platform == "web" &&
                        <div className="shop-layout__sidebar" style={{ marginTop: 20 }}>
                            {sidebarComponent}
                        </div>
                    }
                    <div className="shop-layout__content">
                        <div className="block">
                            <div className={rootClasses}>
                                <div className="products-view__loader" />
                                {content}
                                {loadingProduct && <BlockLoader />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = {
    sidebarOpen,
    sidebarClose,
};


export default connect(() => ({}), mapDispatchToProps)(ProductsByFilter);
