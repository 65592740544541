// react
import React from "react";

// third-party
import classNames from "classnames";
import { Link } from "react-router-dom";

// application
import Currency from "../shared/Currency";
import Rating from "../shared/Rating";
import { url } from "../../services/utils";
import { HiShoppingCart } from "react-icons/hi2";

import { usePlatformValue } from "../../atom/platform";
import { useWishList } from "../../atom/wishlist";
import { toast } from "react-toastify";

import { HiHeart } from "react-icons/hi2";
import { useCart } from "../../atom/cart";

function Product(props) {
    const { product, layout } = props;
    const { cart, setCart, addToCart, removeFromCart, isItemInCart, updateCartItem } = useCart();
    const { wishList, setWishList, addToWishlist, removeFromWishlist, isWishProduct } = useWishList();

    const platform = usePlatformValue();

    const handleAddToWishlist = (product) => {
        addToWishlist(product);
        toast.success(`Đã thêm "${product.name}" vào danh sách yêu thích!`, {
            theme: "colored",
            className: "mx-2 rounded mt-2",
        });
    };

    const handleRemoveFromWishlist = (product) => {
        removeFromWishlist(product.product_id);
        toast.success(`Đã xóa "${product.name}" khỏi danh sách yêu thích!`, {
            theme: "colored",
            className: "mx-2 rounded mt-2",
        });
    };

    const handleAddToCart = (product, quantity) => {
        addToCart(product, quantity);
        toast.success(`Đã thêm "${product.name}" vào giỏ hàng!`, { theme: "colored", className: "mx-2 rounded mt-2" });
    };

    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    let badges = [];
    let image;
    let price;
    let features;
    let status;

    if (product.status == "out_of_stock") {
        status = (
            <div className="shadow-sm" style={{ position: "absolute", backgroundColor: "red", zIndex: 5 }}>
                <span className="px-1" style={{ color: "white", fontSize: 12, fontWeight: "400" }}>
                    Hết hàng
                </span>
            </div>
        );
    }
    if (product.badges?.includes("sale")) {
        badges.push(
            <div key="sale" className="product-card__badge product-card__badge--sale">
                Sale
            </div>
        );
    }
    if (product.badges?.includes("hot")) {
        badges.push(
            <div key="hot" className="product-card__badge product-card__badge--hot">
                Hot
            </div>
        );
    }
    if (product.badges?.includes("new")) {
        badges.push(
            <div key="new" className="product-card__badge product-card__badge--new">
                New
            </div>
        );
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.img_sapo ? product.img_sapo : product.image_url) {
        image = (
            <div className="product-card__image product-image" style={{ width: "48%" }}>
                <Link
                    to={{ pathname: url.product(product), state: { product: product } }}
                    className="product-image__body"
                >
                    <img
                        className="product-image__img"
                        src={product.img_sapo || product.image_url}
                        alt=""
                    />
                </Link>
            </div>
        );
    }

    if (product.compareAtPrice) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price">
                    <Currency value={product.sales_price} />
                </span>{" "}
                <span className="product-card__old-price">
                    <Currency value={product.compareAtPrice} />
                </span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={product.sales_price} />
            </div>
        );
    }

    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {product.attributes
                    .filter((x) => x.featured)
                    .map((attribute, index) => (
                        <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(", ")}`}</li>
                    ))}
            </ul>
        );
    }

    return (
        <div className={`${containerClasses} shadow-sm`} style={{ flexDirection: "row" }}>
            {status}
            {badges}
            {image}
            <div className="mt-3">
                {platform == "web" ? (
                    <div className="product-card__info">
                        <div className="product-card__name">
                            <Link to={{ pathname: url.product(product), state: { product: product } }}>{product.name.toUpperCase()}</Link>
                        </div>
                        <div className="product-card__rating">
                            <Rating value={product.rating || 5} />
                            <div className="product-card__rating-legend">
                                Đã bán {product.total_quantity || 0}
                            </div>
                        </div>

                        {features}
                    </div>
                ) : (
                    <div className="product-card__info">
                        <div className="product-card__name">
                            <Link to={{ pathname: url.product(product), state: { product: product } }}>
                                <span style={{}}>
                                    {product.name.length < 32
                                        ? product.name.toUpperCase()
                                        : product.name.slice(0, 32).toUpperCase() + "..."}
                                </span>
                            </Link>
                        </div>
                        <div className="product-card__rating">
                            <Rating value={product.rating || 5} />
                        </div>
                        <div className="product-card__rating-legend" style={{ marginLeft: 0, marginTop: 3 }}>
                            Đã bán {product.total_quantity || 0}
                        </div>
                        {features}
                    </div>
                )}
                <div className="product-card__actions">
                    <div className="product-card__availability">
                        Availability:
                        <span className="text-success">In Stock</span>
                    </div>
                    {price}
                    <div className="product-card__buttons mt-4">
                        <button
                            type="button"
                            onClick={() =>
                                isWishProduct(product.product_id)
                                    ? handleRemoveFromWishlist(product)
                                    : handleAddToWishlist(product)
                            }
                            className={classNames("btn btn-light btn-svg-icon shadow-sm")}
                            style={{ backgroundColor: isWishProduct(product.product_id) ? "#fee2e2" : "#f0f0f0" }}
                        >
                            <HiHeart size={20} color={isWishProduct(product.product_id) && "red"} />
                        </button>
                        <button
                            type="button"
                            onClick={() => handleAddToCart(product, 1)}
                            className={classNames("btn btn-light product-card__addtocart shadow-sm")}
                            style={{ backgroundColor: "#ffd333" }}
                        >
                            {platform === "web" ? "Thêm giỏ hàng" : <HiShoppingCart size={18} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;
