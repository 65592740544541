import { ApiUtils } from ".";
import { PATH } from "./path";

export const addRatingApi = async (data, customHeaders) => {
    return await ApiUtils.post(PATH.add_rating, data, customHeaders);
};

export const getRatingApi = async (query) => {
    return await ApiUtils.request(PATH.get_rating + query);
};
