// react
import React from "react";

// application
import BlockHeader from "../shared/BlockHeader";
import ProductCard from "../shared/ProductCard";
import Product from "../zalo_app/Product";
import { usePlatformValue } from "../../atom/platform";

export default function BlockProducts(props) {
    const platform = usePlatformValue();
    const { title, layout, featuredProduct, products } = props;

    let large;
    let smalls;

    if (platform !== "web") {
        if (featuredProduct) {
            large = (
                <div className="block-products__featured">
                    <div className="block-products__featured-item mb-md-3">
                        <Product product={featuredProduct} layout={layout} />
                    </div>
                </div>
            );
        }
    }

    if (platform !== "web") {
        if (products?.length > 0) {
            const productsList = products?.slice(1, 6).map((product, index) => (
                <div key={index} className="block-products__list-item">
                    <ProductCard product={product} layout={layout} />
                </div>
            ));

            smalls = <div className="block-products__list">{productsList}</div>;
        }
    } else {
        if (products?.length > 0) {
            const productsList = products?.slice(0, 4).map((product, index) => (
                <div key={index} className="products-list__item">
                    <ProductCard product={product} />
                </div>
            ));

            smalls = (
                <div
                    className="products-view__list products-list"
                    data-layout={`grid-4-full`}
                    data-with-features={true}
                >
                    <div className="products-list__body">
                        {productsList}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={`block block-products block-products--layout--${layout}`}>
            <div className="container">
                {products?.length > 0 && <BlockHeader title={title} />}

                <div className="block-products__body">
                    {layout === "large-first" && large}
                    {smalls}
                    {/* {layout === 'large-last' && large} */}
                </div>
            </div>
        </div>
    );
}
