import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const PAGE_STATE = atom({
    key: 'PAGE_STATE', // unique ID (with respect to other atoms/selectors)
    default: {
        pageProduct: 2,
        pageProductByCategory: 2,
        pageProductByFilter: 2,
    }
     // default value (aka initial value)
});

export const useCategoriesPageState = () => useRecoilState(PAGE_STATE);

export const useCategoriesPageValue = () => useRecoilValue(PAGE_STATE);

export const useSetCategoriesPageValue = () => useSetRecoilState(PAGE_STATE);