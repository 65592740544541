// react
import React, { useCallback } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import Collapse from '../shared/Collapse';
import FilterCategory from '../filters/FilterCategory';
import FilterCheck from '../filters/FilterCheck';
import FilterColor from '../filters/FilterColor';
import FilterRadio from '../filters/FilterRadio';
import FilterRange from '../filters/FilterRange';
import getFilterHandler from '../../services/filters';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { useFilterState } from '../../atom/filter';
import { formatBrand, formatCategory, formatFilter, formatPrice } from '../../utils/constant/formartFilter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRequest } from 'ahooks';
import { filterProductApi } from '../../services/api/product_api';
import { useState } from 'react';
import { sidebarClose } from '../../store/sidebar';
import { connect } from 'react-redux';
import BlockLoader from '../blocks/BlockLoader';

const filterComponents = {
    category: FilterCategory,
    range: FilterRange,
    check: FilterCheck,
    radio: FilterRadio,
    color: FilterColor,
};

function WidgetFilters(props) {
    const {
        dispatch,
        filters,
        values,
        title,
        offcanvas,
        sidebarClose,
    } = props;

    /* const handleValueChange = useCallback(({ filter, value }) => {
        const handler = getFilterHandler(filter);

        if (handler) {
            dispatch({
                type: 'SET_FILTER_VALUE',
                filter: filter.slug,
                value: handler.isDefaultValue(filter, value) ? undefined : handler.serialize(value),
            });
        }
    }, [dispatch]); */
    const [queryFilter, setQueryFilter] = useState('');

    const { loading, runAsync: getFilterProduct } = useRequest(() => filterProductApi(queryFilter),
        {
            debounceWait: 250,
            cacheKey: "filter-cache",
            manual: true,
        }
    );


    const history = useHistory();
    const [filter, setFilter] = useFilterState();
    const handleFilter = async() => {
        setQueryFilter('');
        let filterCategory = formatCategory(filter.category_id);
        let filterPrice = filter.sales_price ? formatPrice(filter?.sales_price[0], filter?.sales_price[1]) : '';
        let filterBrand = formatBrand(filter.brand_id);
        let filterQuery = formatFilter(filterCategory,filterPrice,filterBrand);
        setQueryFilter(filterQuery);
        
        await getFilterProduct().then(res => {
            sidebarClose();
            history.push({pathname: "/product/filter", state: {
                data: res.data.allProducts,
            }});
        }).catch(err => console.log(err));
        
    };

    const filtersList = filters.map((filter, index) => {
        let filterView;
        let { value } = filter;
        const handler = getFilterHandler(filter);

        if (handler && filter.slug in values) {
            value = handler.deserialize(values[filter.slug]) || handler.getDefaultValue(filter);
        }

        const FilterComponent = filterComponents[filter.type];

        if (FilterComponent) {
            filterView = (
                <FilterComponent
                    data={filter}
                    value={value}
                    /* onChangeValue={handleValueChange} */
                />
            );
        }
        if(loading){
            return (
                <BlockLoader />
            )
        }

        return (
            <div key={index} className="widget-filters__item">
                
                <Collapse
                    toggleClass="filter--opened"
                    render={({ toggle, setItemRef, setContentRef }) => (
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                {filter.name}
                                <ArrowRoundedDown12x7Svg className="filter__arrow" />
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    {filterView}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    });

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            
            <h4 className="widget-filters__title widget__title">Bộ lọc</h4>

            <div className="widget-filters__list">
                {filtersList}
            </div>

            <div className="widget-filters__actions d-flex mb-n2">
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleFilter}
                >
                    Tìm kiếm
                </button>
            </div>
        </div>
    );
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * Products list filters.
     */
    filters: PropTypes.array,
    /**
     * Products list filter values.
     */
    values: PropTypes.object,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

WidgetFilters.defaultProps = {
    offcanvas: 'mobile',
};

const mapDispatchToProps = {
    sidebarClose,
};

export default connect(() => ({}), mapDispatchToProps)(WidgetFilters);

