import { getUserInfo, getAccessToken, getPhoneNumber, openShareSheet, openChat, addRating } from "zmp-sdk/apis";
import axios from "axios";
import { OA_ID } from "./index.js";
import { StorageKey } from "../../services/storage/index.js";

export const getPlatform = async () => {
    try {
        let result = navigator.userAgent.indexOf("Zalo");
        if (result !== -1) return "";
        else return "web";
    } catch (error) {
        // xử lý khi gọi api thất bại
        console.log(error);
    }
};

export const getUser = async () => {
    try {
        const userInfo = await getUserInfo({}).then((result) => {
            return result.userInfo;
        });
        return userInfo;
    } catch (error) {
        // xử lý khi gọi api thất bại
        console.log(error);
    }
};

export const getPhoneUser = async () => {
    const { number, token } = await getPhoneNumber({ fail: console.warn });
    if (number) {
        return number;
    }
    if (token) {
        const accessToken = await getAccessToken({});
        const numberPhone = await axios
            .get(`https://graph.zalo.me/v2.0/me/info`, {
                headers: {
                    access_token: accessToken,
                    code: token,
                    secret_key: "mfQ4PH25MG2QvlmUpYBM",
                },
            })
            .then((response) => {
                console.log(response.data);
                return response.data.data.number;
            })
            .catch((err) => console.error(err));
        return numberPhone;
    } else {
        return null;
    }
};

export const getDataToStorage = async (key) => {
    const storedData = await JSON.parse(localStorage.getItem(`${key}`));
    return storedData;
};

export const setDataToStorage = async (key, data) => {
    await localStorage.setItem(`${key}`, JSON.stringify(data));
};

export const shareCurrentPage = async (title, thumbnail, path) => {
    try {
        const data = await openShareSheet({
            type: "zmp_deep_link",
            data: {
                title: title,
                description: "Minh Ký",
                thumbnail: thumbnail,
                path: path
            },
        });
    } catch (err) {}
};

export const openChatScreen = async () => {
    try {
        await openChat({
            type: "user",
            id: OA_ID,
            message: "Xin Chào",
        });
    } catch (error) {
        // xử lý khi gọi api thất bại
        console.log(error);
    }
};

export const ratingMiniApp = async () => {
    try {
        await addRating();
    } catch (error) {
        // xử lý khi gọi api thất bại
        console.log(error);
    }
};

export const getVersionApp = () => {
    const version = new URLSearchParams(location.search).get("version");
    const env = new URLSearchParams(location.search).get("env");
    if (version) {
        setDataToStorage(StorageKey.MiniAppVersion, {version: version, environment: env});
    }
};
