export default {
    name: 'Cửa hàng Minh Ký',
    fullName: 'DigiBird Store - Tools Store React eCommerce Template',
    url: 'https://digibird.io',
    author: {
        name: 'DigiBird Co',
        profile_url: 'https://digibird.io',
    },
    contacts: {
        address: '103 Thống Nhất, Liên Nghĩa, Đức Trọng, Lâm Đồng, Vietnam.',
        email: 'info@example.com',
        phone: '0913 667 122',
    },
};
