// react
import React from "react";

// third-party
import { Link, useHistory } from "react-router-dom";

// application
import Indicator from "./Indicator";
import { Person20Svg } from "../../svg";
import { useUserState } from "../../atom/user";
import { loginWebApi } from "../../services/api/user_api";
import { useRequest } from "ahooks";
import { toast } from "react-toastify";
import { useAuthState, useSetAuthState } from "../../atom/auth";
import { useState } from "react";
import { ID_COMPANY } from "../../utils/constant";
import { setDataToStorage } from "../../utils/constant/config";
import { StorageKey } from "../../services/storage";

export default function IndicatorAccount() {
    const history = useHistory();
    const [user, setUser] = useUserState();
    const [auth, setAuth] = useAuthState();
    const { runAsync, loading } = useRequest((data) => loginWebApi(data), {
        debounceInterval: 250,
        cacheKey: "loginWeb",
        manual: true,
    });

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const logout = () => {
        const token = null;
        const userInfor = {
            id: '',
            name: 'Họ và tên',
            phone: '123',
            company_id: '1',
            img_url: '',
            email: 'example@example.com',
            address: '',
        };
        setDataToStorage(StorageKey.Authen, token);
        setAuth({ loading: false, reinitialized: false, token: token });
        setUser(userInfor);
    };

    const onSubmit = (data) => {
        runAsync(data)
            .then((res) => {
                const token = res.data.token;
                const userInfor = {
                    id: res.data.user.xid,
                    name: res.data.user.name,
                    phone: res.data.user.phone,
                    company_id: ID_COMPANY,
                    img_url: res.data.user.profile_image_url,
                    email: res.data.user.email,
                    address: res.data.user.address,
                };
                setDataToStorage(StorageKey.Authen, token);
                setAuth({ loading: false, reinitialized: false, token: token });
                setUser(userInfor);
                history.push("/");
            })
            .catch((err) => {
                console.log("error", err);
                if (err.error_message == "These credentials do not match our records.") {
                    toast.error(`Email hoặc mật khẩu không chính xác, vui lòng thử lại!`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                } else {
                    toast.error(`Đăng nhập không thành công, vui lòng thử lại sau!`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                }
            });
    };

    const dropdown = (
        <div className="account-menu">
            <div className="account-menu__divider" />
            {auth.token ? (
                <Link to="/account/dashboard" className="account-menu__user">
                    <div className="account-menu__user-avatar">
                        <img src={user.profile_image_url ? user.profile_image_url : "https://test-pos.digibird.io/images/user.png"} alt="" />
                    </div>
                    <div className="account-menu__user-info">
                        <div className="account-menu__user-name">{user.name}</div>
                        <div className="account-menu__user-email">{user.email}</div>
                    </div>
                </Link>
            ) :
            (
                <div className="account-menu__form">
                    <div className="account-menu__form-title">Đăng nhập</div>
                    <div className="form-group">
                        <label htmlFor="header-signin-email" className="sr-only">
                            Email
                        </label>
                        <input
                            id="header-signin-email"
                            type="email"
                            className="form-control form-control-sm"
                            placeholder="Email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="header-signin-password" className="sr-only">
                            Mật khẩu
                        </label>
                        <div className="account-menu__form-forgot">
                            <input
                                id="header-signin-password"
                                type="password"
                                className="form-control form-control-sm"
                                placeholder="Mật khẩu"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            {/* <Link to="/account/login" className="account-menu__form-forgot-link">Quên mật khẩu?</Link> */}
                        </div>
                    </div>
                    <div className="form-group account-menu__form-button">
                        <button
                            onClick={() => onSubmit({ email: email, password: password })}
                            onC
                            className="btn btn-primary btn-sm"
                        >
                            Đăng nhập
                        </button>
                    </div>
                    <div className="account-menu__form-link">
                        <Link to="/account/register">Tạo tài khoản</Link>
                    </div>
                </div>
            )
            }

            {auth.token && (
                <>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <Link to="/account/profile">Chỉnh sửa thông tin</Link>
                        </li>
                        <li>
                            <Link to="/account/orders">Đơn hàng của tôi</Link>
                        </li>
                        <li>
                            <Link to="/account/addresses">Danh sách địa chỉ</Link>
                        </li>
                        <li>
                            <Link to="/account/password">Đổi Mật khẩu</Link>
                        </li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <Link to="/account/login" onClick={logout}>
                                Đăng xuất
                            </Link>
                        </li>
                    </ul>
                </>
            )}
        </div>
    );

    return <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />;
}
