import * as yup from "yup";
import { REGEX } from "./regex";

export const schemaAddress = yup.object().shape({
    name: yup
        .string()
        .required("Vui lòng nhập họ và tên của bạn.")
        .min(5, "Họ và tên phải nhiều hơn 5 ký tự")
        .matches(RegExp(REGEX.NAME), "Họ và tên không đúng. Vui lòng nhập lại."),
    phoneNumber: yup
        .string()
        .required("Số điện thoại không được để trống.")
        .matches(
            RegExp(REGEX.NUMBER_PHONE),
            "Số điện thoại không đúng. Vui lòng nhập lại (bắt buộc 10 chữ số và phải bắt đầu bằng số 0)."
        ),
    email: yup
        .string()
        .required("Vui lòng nhập địa chỉ email của bạn.")
        .max(120)
        .matches(RegExp(REGEX.EMAIL), "Địa chỉ email không đúng. Vui lòng nhập lại.")
        .typeError(""),
    address: yup
        .string()
        .required("Vui lòng nhập địa chỉ của bạn.")
        .max(400)
        .matches(RegExp(REGEX.ADDRESS), "Địa chỉ không đúng. Vui lòng nhập lại.")
        .typeError(""),
    city: yup
        .string()
        .required("Vui lòng chọn tỉnh, thành phố.")
        .max(120)
        .matches(RegExp(REGEX.ADDRESS), "Tỉnh, thành phố không đúng. Vui lòng chọn lại.")
        .typeError(""),
    district: yup
        .string()
        .required("Vui lòng chọn quận, huyên.")
        .max(120)
        .matches(RegExp(REGEX.ADDRESS), "Quận, huyên không đúng. Vui lòng chọn lại.")
        .typeError("")
});
