// react
import React, { useEffect, useRef, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, useHistory, useLocation } from "react-router-dom";

// application
import Currency from "../shared/Currency";
import { Check100Svg } from "../../svg";

// data stubs
import { usePlatformValue } from "../../atom/platform";
import {
    HiOutlineBanknotes,
    HiOutlineClock,
    HiOutlineCurrencyDollar,
    HiOutlineMapPin,
    HiOutlinePhone,
} from "react-icons/hi2";
import { HiOutlineMail } from "react-icons/hi";
import { StorageKey } from "../../services/storage";
import { getDataToStorage, setDataToStorage } from "../../utils/constant/config";
import BlockLoader from "../blocks/BlockLoader";
import { useCartState } from "../../atom/cart";
import { useOrderState } from "../../atom/order";
import { saveOrderApi } from "../../services/api/order_api";
import { useRequest } from "ahooks";
import { getAccessToken } from "../../services/tokenManager";
import { getParamsUrl } from "../../utils/convertURL";

export default function ShopPageOrderSuccess() {
    const history = useHistory();
    const locationState = useLocation();
    const platform = usePlatformValue();
    const [cart, setCart] = useCartState();
    const [order, setOrder] = useOrderState();

    const [data, setData] = useState();
    const tokenAuth = useRef(null);

    const { runAsync: saveOrder, loading: saveOrderLoading } = useRequest(
        (params) => saveOrderApi(params, { token: tokenAuth.current }),
        {
            debounceInterval: 250,
            cacheKey: "save-order",
            manual: true,
        }
    );

    const getToken = async () => {
        tokenAuth.current = await getAccessToken();
    };

    const clearItems = (orders, cart) => {
        const updatedCart = cart.filter((cartItem) => {
            const found = orders.some((orderItem) => orderItem.product.product_id === cartItem.product.product_id);
            return !found;
        });
        setCart(updatedCart);
        setDataToStorage(StorageKey.Cart, updatedCart);
        setOrder([]);
    };

    /* Xử lý trả về khi thanh toán bằng tiền mặt hoặc trên zalo */
    const buyItemsByCashOrZalo = () => {
        const { data } = locationState.state;
        setData(data);
        const updatedCart = cart.filter((cartItem) => {
            const found = data.products.some(
                (orderItem) => orderItem.product.product_id === cartItem.product.product_id
            );
            return !found;
        });
        setCart(updatedCart);
        setDataToStorage(StorageKey.Cart, updatedCart);
        setOrder([]);
    };

    /* Xử lý trả về khi thanh toán vnpay trên web */
    const buyItemsByVnPayInWeb = () => {
        /* Kiểm tra trạng thái thanh toán */
        const status = new URLSearchParams(location.search).get("vnp_ResponseCode");
        const params = getParamsUrl(location.search);
        getToken().then(() =>
            saveOrder(params)
                .then((res) => {
                    console.log("Lưu thanh toán thành công", res);
                    if (status !== "00" && status !== "07") {
                        /* Thanh toán thất bại */
                        localStorage.removeItem(StorageKey.OrderSuccess);
                        history.push("/");
                    } else {
                        /* Thanh toán thành công */
                        getDataToStorage(StorageKey.OrderSuccess)
                            .then((res) => {
                                console.log(res);
                                setData(res);
                                getDataToStorage(StorageKey.Cart).then((cart) => clearItems(res.products, cart));
                                localStorage.removeItem(StorageKey.OrderSuccess);
                            })
                            .catch((err) => console.error(err));
                    }
                })
                .catch((err) => {
                    console.log("Lưu thanh toán thất bại", err);
                    if (status !== "00" && status !== "07") {
                        /* Thanh toán thành công */
                        localStorage.removeItem(StorageKey.OrderSuccess);
                        history.push("/");
                    } else {
                        /* Thanh toán thành công */
                        getDataToStorage(StorageKey.OrderSuccess)
                            .then((res) => {
                                console.log(res);
                                setData(res);
                                getDataToStorage(StorageKey.Cart).then((cart) => clearItems(res.products, cart));
                                localStorage.removeItem(StorageKey.OrderSuccess);
                            })
                            .catch((err) => console.error(err));
                    }
                })
        );
    };

    useEffect(() => {
        const getData = () => {
            if (locationState.state) {
                buyItemsByCashOrZalo();
            } else {
                buyItemsByVnPayInWeb();
            }
        };
        getData();
    }, []);

    if (saveOrderLoading) {
        return <BlockLoader />;
    }

    const items = data?.products?.map((item, index) => {
        return (
            <tr key={index}>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <div className="product-image__body">
                            <img
                                className="product-image__img"
                                src={item.product.img_sapo ? item.product.img_sapo : item?.product.image_url}
                                alt=""
                            />
                        </div>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <div>{item.product.name}</div>
                </td>
                <td className="order-list__column-quantity" data-title="Số lượng:">
                    {item.quantity}
                </td>
                <td className="order-list__column-total">
                    <Currency value={item.product.sales_price * item.quantity} />
                </td>
            </tr>
        );
    });

    return (
        <div className="block order-success z-50">
            <Helmet>
                <title>{`Đặt hàng thành công`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header pb-3">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Thành công</h1>
                        <div className="order-success__subtitle">Đơn đặt hàng của bạn đã được gửi đi</div>
                        <div className="order-success__actions">
                            <Link to="/account/orders" className="btn btn-xs btn-primary">
                                Xem trạng thái đơn hàng
                            </Link>
                        </div>
                    </div>

                    {platform === "web" ? (
                        <div className="order-success__meta">
                            <ul className="order-success__meta-list">
                                <li className="order-success__meta-item col-6">
                                    <span className="order-success__meta-title">Mã đơn hàng:</span>
                                    <span className="order-success__meta-value">{`#${data?.unique_id}`}</span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title">Thời gian:</span>
                                    <span className="order-success__meta-value">{data?.createdAt}</span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title">Tổng tiền:</span>
                                    <span className="order-success__meta-value">
                                        <Currency value={data?.total} />
                                    </span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title">Thanh toán:</span>
                                    <span className="order-success__meta-value">{data?.pay}</span>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <div className="order-success__meta">
                            <div className="order-success__meta-item mb-5">
                                <span className="order-success__meta-title">Mã đơn hàng:</span>
                                <span
                                    className="order-success__meta-value"
                                    style={{ fontSize: 16 }}
                                >{`#${data?.unique_id}`}</span>
                            </div>
                            <div className="row">
                                <div className="col align-items-center justify-content-center">
                                    <span className="order-success__meta-title d-flex justify-content-center">
                                        <HiOutlineClock size={18} style={{ marginRight: 2 }} /> Thời gian
                                    </span>
                                    <span className="order-success__meta-value align-items-center justify-content-center">
                                        {data?.createdAt}
                                    </span>
                                </div>
                                <div style={{ backgroundColor: "#d1d5db", width: 1, height: 36 }} />
                                <div className="col align-items-center justify-content-center">
                                    <span className="order-success__meta-title d-flex  justify-content-center">
                                        <HiOutlineCurrencyDollar size={18} style={{ marginRight: 2 }} /> Tổng tiền
                                    </span>
                                    <span className="order-success__meta-value align-items-center justify-content-center">
                                        <Currency value={data?.total} />
                                    </span>
                                </div>
                                <div style={{ backgroundColor: "#d1d5db", width: 1, height: 36 }} />
                                <div className="col align-items-center justify-content-center">
                                    <span className="order-success__meta-title d-flex justify-content-center">
                                        <HiOutlineBanknotes size={18} style={{ marginRight: 2 }} /> Thanh toán
                                    </span>
                                    <span className="order-success__meta-value align-items-center justify-content-center">
                                        {data?.pay}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="card">
                        <div className="order-list">
                            <table style={{ fontSize: 14 }}>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">
                                            Sản phẩm
                                        </th>
                                        <th className="order-list__column-quantity">Số lượng</th>
                                        <th className="order-list__column-total">Tổng tiền</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">{items}</tbody>
                                <tbody className="order-list__subtotals">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            Tổng tiền hàng
                                        </th>
                                        <td className="order-list__column-total" style={{ fontWeight: "700" }}>
                                            <Currency value={data?.subTotal} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            Phí giao hàng
                                        </th>
                                        <td className="order-list__column-total" style={{ fontWeight: "700" }}>
                                            <Currency value={data?.shippingTotal} />
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot className="order-list__footer" style={{ fontSize: 16, fontWeight: "700" }}>
                                    <tr>
                                        <th
                                            className="order-list__column-label"
                                            colSpan="3"
                                            style={{ fontWeight: "700" }}
                                        >
                                            Tổng thanh toán
                                        </th>
                                        <td className="order-list__column-total">
                                            <Currency value={data?.total} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-12 px-2">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        Thông tin người nhận
                                    </div>
                                    <div className="address-card__name">Họ và tên: {data?.shippingAddress?.name}</div>
                                    <div className="address-card__row">
                                        <div
                                            className="address-card__row-title d-flex align-items-center"
                                            style={{ marginLeft: 0, fontSize: 13 }}
                                        >
                                            <HiOutlineMapPin size={18} />
                                            <div className="ml-1 mt-1">Địa chỉ</div>
                                        </div>
                                        <div className="address-card__row-content mt-2">
                                            {data?.shippingAddress?.shipping_address}
                                        </div>
                                    </div>
                                    <div className="address-card__row">
                                        <div
                                            className="address-card__row-title d-flex align-items-center"
                                            style={{ marginLeft: 0, fontSize: 13 }}
                                        >
                                            <HiOutlinePhone size={18} />
                                            <div className="ml-1 mt-1">Số điện thoại</div>
                                        </div>
                                        <div className="address-card__row-content mt-2">
                                            {data?.shippingAddress?.phone}
                                        </div>
                                    </div>
                                    <div className="address-card__row">
                                        <div
                                            className="address-card__row-title d-flex align-items-center"
                                            style={{ marginLeft: 0, fontSize: 13 }}
                                        >
                                            <HiOutlineMail size={18} />
                                            <div className="ml-1 mt-1">Địa chỉ email</div>
                                        </div>
                                        <div className="address-card__row-content mt-2">
                                            {data?.shippingAddress?.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
