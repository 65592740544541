// react
import React, { Fragment, useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { getCategoryParents, url } from '../../services/utils';
import { useCategoriesValue } from '../../atom/categories';
import { useFilterSetValue } from '../../atom/filter';

function FilterCategory(props) {
    const { data } = props;

    const category = useCategoriesValue();
    const [selectIndex, setSelectIndex] = useState();
    const setValueFilter = useFilterSetValue();
    const [quantity, setQuantity] = useState(10);

    const handleSelectCategory = (index, idCategory) => {
        setSelectIndex(index);
        setValueFilter((data) => ({
            ...data,
            category_id: idCategory,
        }));
    }

    const categoriesList = category?.slice(0,quantity)?.map((category, index) => {
        const itemClasses = classNames('filter-categories__item', {
            'filter-categories__item--current': data.value === category.slug,
        });

        return (
            <Fragment key={index}>
                {/* {getCategoryParents(category).map((parent) => (
                    <li key={parent.id} className="filter-categories__item filter-categories__item--parent">
                        <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />
                        <Link to={url.category(parent)}>{parent.name}</Link>
                    </li>
                ))} */}
                <li className={itemClasses}>
                    {/* <Link to={`/category/${category.xid}`}>{category.name}</Link> */}
                    <div onClick={() => handleSelectCategory(index, category.xid)} style={{cursor: 'pointer'}}
                        className={`${selectIndex == index ? 'text-warning font-weight-bold' : ''}`}>
                            {(category.name).toUpperCase()}
                    </div>
                </li>
                {/* {category.children && category.children.map((child) => (
                    <li key={child.id} className="filter-categories__item filter-categories__item--child">
                        <Link to={url.category(child)}>{child.name}</Link>
                    </li>
                ))} */}
            </Fragment>
        );
    });

    /* if (data.value) {
        categoriesList.unshift(
            <li key="[shop]" className="filter-categories__item filter-categories__item--parent">
                <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />
                <Link to={url.catalog()}>Tất cả danh mục</Link>
            </li>,
        );
    } */

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {categoriesList}
                <li>
                    <div onClick={() => setQuantity(prev => prev + 10)} className="filter-list__title font-weight-normal text-decoration-underline mt-1" style={{textDecoration: 'underline', color: 'orange', cursor: 'pointer'}}>
                        Hiển thị thêm
                    </div>
                </li>
            </ul>
        </div>
    );
}

FilterCategory.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
};

export default FilterCategory;
