// react
import React, { useEffect, useRef, useState } from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath } from "react-router-dom";

// application

// pages
import { useRequest } from "ahooks";
import { useCategoriesValue } from "../../atom/categories";
import { useSetCategoriesPageValue } from "../../atom/categories_page";
import { usePlatformValue } from "../../atom/platform";
import { useProductByCategoryState } from "../../atom/product_by_category";
import { productsByCategory } from "../../services/api/product_api";
import { Loading } from "../loading/loading";
import ProductsByCategory from "./ProductsByCategory";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import { useFilterState } from "../../atom/filter";

export default function ListCategory(props) {
    const dataProps = props?.location?.state;
    const { match, location, categorySlug, categoryId } = props;
    const platform = usePlatformValue();
    const category = useCategoriesValue();
    const [dataProducts, setProductByCategory] = useProductByCategoryState();
    const setCurrentPage = useSetCategoriesPageValue();
    const [slug, setSlug] = useState(categorySlug !== "all-products" ? `&${categorySlug}` : "");
    const [categorySub, setCategorySub] = useState(dataProps?.category);
    const activeCategoryTitleRef = useRef(null);
    const activeSubCategoryTitleRef = useRef(null);

    const componentRef = useRef(null);
    const [filter, setFilter] = useFilterState();
    useEffect(() => {
        setFilter([]);
        // Scroll to the component's DOM element when it mounts
        // console.log("onto screen");
        if (componentRef.current) {
            componentRef.current.scrollIntoView({});
        }
        if (activeCategoryTitleRef.current) {
            activeCategoryTitleRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (activeSubCategoryTitleRef.current) {
            activeSubCategoryTitleRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "My Account", url: "" },
    ];

    const { data, loading, runAsync } = useRequest(() => productsByCategory(slug, 1), {
        debounceWait: 250,
        cacheKey: "products-category-cache",
        manual: true,
    });

    useEffect(() => {
        runAsync()
            .then((res) => {
                //console.log(res.data.allProducts);
                setProductByCategory(res.data.allProducts);
            })
            .catch((err) => console.log(err));
    }, [slug]);

    const handleClick = (category, type) => {
        if (!type)
            setCategorySub(category);
        if (category.xid == "all-products")
            setSlug("");
        else
            setSlug(`&${category.xid}`);

    };

    const all = {
        xid: "all-products",
        name: "Tất cả",
        slug: "all-products",
        image: null,
        x_parent_id: null,
        img_sapo: null,
        image_url: null,
    };
    let submenu = null;
    if (categorySub?.children) {
        const type = 'subMenu'
        submenu = (
            categorySub.children.map((item, index) => {
                const isActive = matchPath(location.pathname, { path: `/category/${item.xid}`, exact: true });
                const classes = classNames("account-nav__item", {
                    "account-nav__item--active": isActive,
                });
                return (
                    <div key={index} className={classes} onClick={() => handleClick(item, type)}>
                        <Link to={`/category/${item.xid}`}>
                            <div
                                ref={isActive ? activeSubCategoryTitleRef : null}
                                style={{
                                    scrollMarginRight: 200,
                                }}
                            >
                                {item.name.toUpperCase()}
                            </div>
                        </Link>
                    </div>
                );
            })
        );
    }

    const links = [all, ...category].map((link, index) => {
        const url = link.xid;
        const isActive = matchPath(location.pathname, { path: `/category/${link.xid}`, exact: true });

        const classes = classNames("account-nav__item", {
            "account-nav__item--active": isActive,
        });
        return (
            <div>
                <div key={index} className={classes} onClick={() => handleClick(link)}>
                    <Link to={`/category/${link.xid}`}>
                        <div
                            ref={isActive ? activeCategoryTitleRef : null}
                            style={{
                                scrollMarginRight: 200,
                            }}
                        >
                            {link.name.toUpperCase()}
                        </div>
                    </Link>
                </div>

            </div>
        );
    });

    let productsView;
    if (dataProducts) {
        productsView = (
            <ProductsByCategory
                isLoading={loading}
                filters={"state.filters"}
                layout={"grid"}
                grid={"grid-3-sidebar"}
                offcanvas={"mobile"}
                categorySlug={slug}
            />
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Danh sách sản phẩm</title>
            </Helmet>
            {/* <PageHeader header={'Danh sách sản phẩm'} breadcrumb={breadcrumb} /> */}
            <div className="block" ref={componentRef}>
                <div className="">
                    <div>
                        {platform !== "web" && (
                            <div className="">
                                <div className="d-flex">
                                    <div className="account-nav flex-grow-1 no-scrollbar">
                                        <ul>{links}</ul>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="account-nav flex-grow-1 no-scrollbar">
                                        <ul>{submenu}</ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        {loading && <Loading />}
                        <div className={`shop-layout shop-layout--sidebar--start ${platform == 'web' ? 'px-2' : ''} justify-content-center`}>
                            <div className="shop-layout__content">
                                <div className="block">{productsView}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
