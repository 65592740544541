import { ApiUtils } from '.';
import { PATH } from './path';

export const getTopSellingApi = async () => {
    return await ApiUtils.request(PATH.get_top_selling);
}

export const getProductsApi = async (page) => {
    return await ApiUtils.request(PATH.get_product_by_page + page);
}

export const productsByCategory = async (idCategory, page) => {
    return await ApiUtils.request(`${PATH.get_product_by_category}${idCategory}?page=${page}`);
}

export const filterProductApi = async (query) => {
    return await ApiUtils.request(`${PATH.filter_product}${query}`);
}