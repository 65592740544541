import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import {
    HiOutlineHome,
    HiOutlineSquares2X2,
    HiOutlineShoppingCart,
    HiOutlineChatBubbleBottomCenterText,
    HiOutlineUserCircle,
} from "react-icons/hi2";
import { useCartValue } from "../../atom/cart";
import { openChatScreen } from "../../utils/constant/config";
const tabs = [
    {
        label: "Trang chủ",
        route: "/",
        icon: <HiOutlineHome size={28} />,
    },
    {
        label: "Giỏ hàng",
        route: "/shop/cart",
        icon: <HiOutlineShoppingCart size={28} />,
    },
    {
        label: "Sản phẩm",
        route: "/category/all-products",
        icon: <HiOutlineSquares2X2 size={28} />,
    },
    {
        label: "Nhắn tin",
        route: "/chat",
        icon: <HiOutlineChatBubbleBottomCenterText size={28} />,
    },
    {
        label: "Cá nhân",
        route: "/account",
        icon: <HiOutlineUserCircle size={28} />,
    },
];

const MobileBottomNav = () => {
    const [activeTab, setActiveTab] = useState("Trang chủ");
    const cart = useCartValue();

    const handleClick = (label, route) => {
        setActiveTab(label);
        if (route == "/chat") {
            openChatScreen();
        }
    };

    return (
        <div
            className="d-lg-none border"
            role="navigation"
            style={{ position: "fixed", width: "100%", zIndex: 10, bottom: 0 }}
        >
            <Nav className="w-100">
                <div className=" d-flex flex-row justify-content-around w-100" style={{ backgroundColor: "white" }}>
                    {tabs.map((tab, index) => (
                        <NavItem key={`tab-${index}`} onClick={() => handleClick(tab.label, tab.route)}>
                            {tab.route == "/chat" ? (
                                <div
                                    className="nav-link row d-flex flex-column justify-content-center align-items-center"
                                    style={{
                                        color: activeTab == tab.label ? "#ffd333" : "#3d464d",
                                    }}
                                >
                                    {tab.icon}
                                    <div style={{ fontSize: 12 }}>{tab.label}</div>
                                </div>
                            ) : tab.route == "/shop/cart" ? (
                                <NavLink to={tab.route} className="nav-link" style={{ position: "relative" }}>
                                    <div
                                        className="row d-flex flex-column justify-content-center align-items-center"
                                        style={{ color: activeTab == tab.label ? "#ffd333" : "#3d464d" }}
                                    >
                                        {tab.icon}
                                        <div style={{ fontSize: 12 }}>{tab.label}</div>
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 2,
                                            right: 3,
                                            backgroundColor: "#ffd333",
                                            borderRadius: "50%",
                                            paddingRight: 6,
                                            paddingLeft: 6,
                                            fontSize: 12,
                                            color: "#FFF",
                                        }}
                                    >
                                        {cart.length}
                                    </div>
                                </NavLink>
                            ) : (
                                <NavLink to={tab.route} className="nav-link">
                                    <div
                                        className="row d-flex flex-column justify-content-center align-items-center"
                                        style={{ color: activeTab == tab.label ? "#ffd333" : "#3d464d" }}
                                    >
                                        {tab.icon}
                                        <div style={{ fontSize: 12 }}>{tab.label}</div>
                                    </div>
                                </NavLink>
                            )}
                        </NavItem>
                    ))}
                </div>
            </Nav>
        </div>
    );
};

export default MobileBottomNav;
