import { useRequest } from "ahooks";
import React, { useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { filterProductApi } from "../../services/api/product_api";
import { useHistory } from "react-router-dom";

export default function FilterProduct() {
    const [inputValue, setInputValue] = useState("");
    //const [filterSearch, setFilterSearch] = useState("");
    const history = useHistory();
    const { loading: loadingProduct, runAsync: getProductsByFilter } = useRequest(
        (filterSearch) => filterProductApi(filterSearch),
        {
            debounceWait: 250,
            cacheKey: "filter-cache",
            manual: true,
        }
    );

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        let filterQuery = encodeURIComponent(`name lk %${inputValue}%`);
        if (inputValue.length > 0) {
            await getProductsByFilter(filterQuery)
                .then((res) => {
                    history.push({
                        pathname: "/product/filter",
                        state: {
                            data: res.data.allProducts,
                        },
                    });
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <div className="mt-2 col-lg-12">
            <div className="d-flex align-items-center w-100">
                <div className="border rounded-lg px-2 w-100 mr-2">
                    <input
                        type="text"
                        placeholder="Tìm kiếm hơn 1000 sản phẩm..."
                        value={inputValue}
                        onChange={(x) => setInputValue(x.target.value)}
                        onKeyUp={handleKeyUp}
                        className="w-100"
                        style={{ height: 40, border: "none", outline: "none" }}
                    />
                </div>

                <div
                    className="rounded-lg align-content-center"
                    style={{ backgroundColor: "#ffd333", height: 40, padding: 10 }}
                    onClick={() => handleSearch()}
                >
                    <HiMagnifyingGlass size={22} />
                </div>
            </div>
        </div>
    );
}
