// recoil
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const PLATFORM_STATE = atom({
    key: 'PLATFORM_STATE', // unique ID (with respect to other atoms/selectors)
    default: '',
});

export const usePlatformState = () => useRecoilState(PLATFORM_STATE);

export const usePlatformValue = () => useRecoilValue(PLATFORM_STATE);

export const usePlatformSetValue = () => useSetRecoilState(PLATFORM_STATE);