// react
import React, { useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import { url } from "../../services/utils";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";
import { usePlatformValue } from "../../atom/platform";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { filterProductApi } from "../../services/api/product_api";
import { useAuthStateValue } from "../../atom/auth";
import { getRatingApi } from "../../services/api/rating_api";

function ShopPageProduct(props) {
    const { /* productSlug,  */layout, sidebarPosition } = props;
    const { productSlug } = useParams();
    const auth = useAuthStateValue();
    const productID = `id%20eq%20${productSlug}%20`;
    const x = `product_id%20eq%20"${productSlug}"`

    const { data: productsData, loading: loadingCategory, run } = useRequest(() => filterProductApi(productID), {
        debounceWait: 250,
        cacheKey: "product-cache",
    });

    
    const { data: reviewsData, loading: loadingReviewsData, run: getReviews } = useRequest(() => getRatingApi(x), {
        debounceWait: 250,
        cacheKey: "rating",
    });

    const product = props.location.state?.product ? props.location.state.product : productsData?.data?.allProducts?.data[0];
    const platform = usePlatformValue();
    const [isLoading, setIsLoading] = useState(true);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);

    const breadcrumb = [
        { title: "Trang chủ", url: url.home() },
        { title: "Sản phẩm", url: url.catalog() },
        { title: product?.name, url: url.product(product) },
    ];

    let content;

    if (layout === "sidebar") {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    {/* <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={latestProducts} />
                    </div> */}
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} />
                            <ProductTabs withSidebar reviewsData={reviewsData} product={product} />
                        </div>

                        {relatedProducts.length > 0 && (
                            <BlockProductsCarousel
                                title="Related Products"
                                layout="grid-4-sm"
                                products={relatedProducts}
                                withSidebar
                            />
                        )}
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} reviewsData={reviewsData} />
                        <ProductTabs product={product} reviewsData={reviewsData} />
                    </div>
                </div>

                {relatedProducts.length > 0 && (
                    <BlockProductsCarousel title="Related Products" layout="grid-5" products={relatedProducts} />
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {platform == "web" ? (
                <Helmet>
                    <title>{`${product?.name} — ${theme.name}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`${product?.name}`}</title>
                </Helmet>
            )}

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
    layout: "standard",
    sidebarPosition: "start",
};

export default ShopPageProduct;
