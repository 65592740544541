// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { useAuthState } from '../../atom/auth';
import { setDataToStorage } from '../../utils/constant/config';
import { useUserSetValue } from '../../atom/user';
import { StorageKey } from '../../services/storage';
import { useProductByCategoryState } from '../../atom/product_by_category';
import { useRequest } from 'ahooks';
import { productsByCategory } from '../../services/api/product_api';
import BlockLoader from '../blocks/BlockLoader';

function MobileLinks(props) {
    const [auth, setAuth] = useAuthState();
    const setUser = useUserSetValue();
    const { links, level, onItemClick } = props;

    const [, setProductByCategory] = useProductByCategoryState();
    const { data, loading, runAsync } = useRequest((slug) => productsByCategory(slug, 1), {
        debounceWait: 250,
        cacheKey: "products-category-cache",
        manual: true,
    });
    const handleSlug = async(idSlug) => {
        await runAsync(idSlug)
            .then((res) => {
                setProductByCategory(res.data.allProducts);
            })
            .catch((err) => console.log(err));
    }

    const subMenuLogin = [{ type: 'link', label: 'Đăng nhập', url: '/account/login' }];

    const handleItemClick = (item) => {
        
        if (onItemClick) {
            if(item.url == '/account/login'){
                logout()
            }
            if((item.url).indexOf('/category/') !== -1 && item.url !== '/category/all-products'){
                handleSlug(`&${(item.url).slice(10)}`)
            }
            onItemClick(item);
        }
    };
    const logout = async() => {
        const token = null;
        const userInfor = {
            id: '',
            name: 'Họ và tên',
            phone: '123',
            company_id: '1',
            img_url: '',
            email: 'example@example.com',
            address: '',
        };
        await setDataToStorage(StorageKey.Authen, token);
        setAuth({ loading: false, reinitialized: false, token: token });
        setUser(userInfor);
    };

    const linksList = links.map((link, index) => {
        let item;
        if (link.type === 'link' || link.type === 'button') {
            item = (
                <Collapse
                    toggleClass="mobile-links__item--open"
                    render={({ toggle, setItemRef, setContentRef }) => {
                        let arrow;
                        let subLinks;
                        let linkOrButton;

                        
                        if (link.children && link.children.length > 0) {
                            
                            arrow = (
                                <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                    <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                                </button>
                            );

                            subLinks = (
                                <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                    {link.url == '/account' && !auth.token ? 
                                    <MobileLinks
                                        links={subMenuLogin}
                                        level={level + 1}
                                        onItemClick={onItemClick}
                                    /> 
                                    :
                                    <MobileLinks
                                        links={link.children}
                                        level={level + 1}
                                        onItemClick={onItemClick}
                                    />
                                    }
                                </div>
                            );
                        }

                        if (link.type === 'link') {
                            linkOrButton = (
                                <AppLink
                                    to={link.url == '/account' && !auth.token ? '/account/login' : link.url}
                                    className="mobile-links__item-link"
                                    onClick={() => handleItemClick(link)}
                                >
                                    {link.label}
                                </AppLink>
                            );
                        } else {
                            linkOrButton = (
                                <button
                                    type="button"
                                    className="mobile-links__item-link"
                                    onClick={() => handleItemClick(link)}
                                >
                                    {link.label}
                                </button>
                            );
                        }

                        return (
                            <div className="mobile-links__item" ref={setItemRef}>
                                <div className="mobile-links__item-title">
                                    {linkOrButton}
                                    {arrow}
                                </div>
                                {subLinks}
                            </div>
                        );
                    }}
                />
            );
        } else if (link.type === 'divider') {
            item = <div className="mobile-links__divider" />;
        }

        return <li key={index}>{item}</li>;
    });
    if(loading) return <BlockLoader />
    
    return (
        <ul className={`mobile-links mobile-links--level--${level}`}>
            {linksList}
        </ul>
    );
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};

export default MobileLinks;
