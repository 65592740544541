import * as yup from "yup";
import { REGEX } from "./regex";

export const schemaRegister = yup.object().shape({
    name: yup
        .string()
        .required("Vui lòng nhập họ và tên.")
        .min(5, "Họ và tên phải hơn 5 ký tự.")
        .matches(RegExp(REGEX.NAME), "Họ và tên không hợp lệ. Vui lòng nhập lại."),
    phone: yup
        .string()
        .required("Vui lòng nhập số điện thoại.")
        .matches(
            RegExp(REGEX.NUMBER_PHONE),
            "Số điện thoại không hợp lệ. Vui lòng nhập lại (bắt buộc 10 chữ số và phải bắt đầu bằng số 0)."
        ),
    email: yup
        .string()
        .required("Vui lòng nhập Email.")
        .max(120)
        .matches(RegExp(REGEX.EMAIL), "Email không hợp lệ. Vui lòng thử lại.")
        .typeError(""),
    password: yup
        .string()
        .required("Vui lòng nhập mật khẩu.")
        .max(120)
        .matches(RegExp(REGEX.PASSWORD), "Tối thiểu 8 ký tự.")
        .typeError(""),
    confirmPassword: yup
        .string()
        .required("Vui lòng nhập mật khẩu.")
        .max(120)
        .matches(RegExp(REGEX.PASSWORD), "Tối thiểu 8 ký tự.")
        .typeError(""),
});
