// react
import React, { useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import Currency from "../shared/Currency";
import { validateDate } from "../../utils/constant/convertDate";
import { HiOutlineClock, HiOutlineMapPin, HiOutlinePhone } from "react-icons/hi2";
import { HiOutlineMail } from "react-icons/hi";
import Modal from "react-modal";
import Select from "react-select";
import { usePlatformValue } from "../../atom/platform";
import { useAuthStateValue } from "../../atom/auth";
import { addRatingApi } from "../../services/api/rating_api";
import { useRequest } from "ahooks";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function AccountPageOrderDetails(props) {
    const { order } = props.location.state;
    const platform = usePlatformValue();
    const auth = useAuthStateValue();
    const history = useHistory();

    const [openModal, setOpenModal] = useState(false);
    const data = useRef(null);

    const { runAsync: addRating, loading: addRatingLoading } = useRequest(
        (data) => addRatingApi(data, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "add-rating",
            manual: true,
        }
    );

    useEffect(() => {
        const newData = order.items.map((item) => ({
            order_id: order.xid,
            rating: 5,
            product_id: item.product.xid,
            user_comment: "",
        }));
        data.current = newData;
    }, []);

    const rateOrder = () => {
        const rateOrderData = {object: data.current}
        addRating(rateOrderData).then((res) => {
            toast.success(`Đánh giá thành công`, {
                theme: "colored",
                className: "mx-2 rounded mt-2",
            });
            history.push("/account/orders")
        }).catch((err) => {
            console.log(err);
            toast.warning(`Đánh giá thất bại`, {
                theme: "colored",
                className: "mx-2 rounded mt-2",
            });
        });
    }

    const renderCart = () => {
        const items = order.items.map((item) => {
            return (
                <tr>
                    <td className="order-list__column-image">
                        <div className="product-image">
                            <div className="product-image__body">
                                <img
                                    className="product-image__img"
                                    src={item.product.img_sapo || item.product.image_url}
                                    alt=""
                                />
                            </div>
                        </div>
                    </td>
                    <td className="order-list__column-product">{item.product.name}</td>
                    <td className="order-list__column-quantity" data-title="Số lượng:">
                        {item.quantity}
                    </td>
                    <td className="order-list__column-total">
                        <Currency value={item.subtotal} />
                    </td>
                </tr>
            );
        });

        return (
            <div className="card mt-2">
                <p className="card-body mb-0 font-weight-bold" style={{ fontSize: 20 }}>
                    Đơn hàng của tôi
                </p>
                <div className="order-list border-top">
                    <table className="pl-2" style={{ fontSize: 14 }}>
                        <thead className="order-list__header">
                            <tr>
                                <th className="order-list__column-label" colSpan="2">
                                    Sản phẩm
                                </th>
                                <th className="order-list__column-quantity">Số lượng</th>
                                <th className="order-list__column-total">Tổng tiền</th>
                            </tr>
                        </thead>
                        <tbody className="order-list__products">{items}</tbody>
                        <tbody className="order-list__subtotals">
                            <tr>
                                <th className="order-list__column-label" colSpan="3">
                                    Tổng tiền hàng
                                </th>
                                <td className="order-list__column-total" style={{ fontWeight: "700" }}>
                                    <Currency value={order.total} />
                                </td>
                            </tr>
                            <tr>
                                <th className="order-list__column-label" colSpan="3">
                                    Phí giao hàng
                                </th>
                                <td className="order-list__column-total" style={{ fontWeight: "700" }}>
                                    <Currency value={0} />
                                </td>
                            </tr>
                        </tbody>
                        <tfoot className="order-list__footer" style={{ fontSize: 16, fontWeight: "700" }}>
                            <tr>
                                <th className="order-list__column-label" colSpan="3" style={{ fontWeight: "700" }}>
                                    Tổng thanh toán
                                </th>
                                <td className="order-list__column-total">
                                    <Currency value={order.total} />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    };

    const RatingModal = () => {
        const modalStyles = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 99999,
            },
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                width: platform == "web" ? "90%" : "90%",
                marginRight: "-50%",
                maxHeight: "70%",
                transform: "translate(-50%, -50%)",
                borderRadius: 10,
            },
        };

        const RatingCard = ({ item, data }) => {
            const comment = useRef("");

            const options = [
                { value: 5, label: "5 sao" },
                { value: 4, label: "4 sao" },
                { value: 3, label: "3 sao" },
                { value: 2, label: "2 sao" },
                { value: 1, label: "1 sao" },
            ];

            const handleRating = (rating) => {
                const updatedData = data.current.map((x) =>
                    x.product_id === item.product.xid ? { ...x, rating: rating } : x
                );
                data.current = updatedData;
            };

            const handleComment = () => {
                const userComment = comment.current.value;
                const updatedData = data.current.map((x) =>
                    x.product_id === item.product.xid ? { ...x, user_comment: userComment } : x
                );
                data.current = updatedData;
            };

            return (
                <div className="border p-3 rounded mb-3" style={{ fontSize: 14 }}>
                    <div className="p-2 mb-2 d-flex align-items-center border-b">
                        <img
                            src={item.product.img_sapo || item.product.image_url}
                            alt={item.product.name}
                            style={{ width: 50 }}
                        />
                        <span className="ml-3">{item.product.name}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="review-stars" style={{ marginRight: 4 }}>
                            Chất lượng
                        </label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={options}   
                            defaultValue={options[0]}
                            onChange={(x) => {
                                handleRating(x.value);
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="review-text">Bình luận của bạn</label>
                        <textarea
                            ref={comment}
                            onChange={handleComment}
                            className="form-control"
                            id="review-text"
                            rows="3"
                        />
                    </div>
                </div>
            );
        };

        return (
            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                style={modalStyles}
                contentLabel="Modal"
                ariaHideApp={false}
            >
                <h5 className="mb-4">Bình luận</h5>
                {order.items.map((item, index) => (
                    <RatingCard item={item} key={index} data={data} />
                ))}
                <button onClick={rateOrder} className="btn btn-primary btn-md">Đánh giá</button>
            </Modal>
        );
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Chi tiết đơn hàng</title>
            </Helmet>

            <div className="row no-gutters mx-n2">
                <div className="col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Thông tin người nhận</div>

                            <div className="address-card__name">Họ và tên: {order.shipping_address.name}</div>

                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlinePhone size={18} />
                                    <div className="ml-1 mt-1">Số điện thoại</div>
                                </div>
                                <div className="address-card__row-content mt-2">{order.shipping_address.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineMail size={18} />
                                    <div className="ml-1 mt-1">Địa chỉ email</div>
                                </div>
                                <div className="address-card__row-content mt-2">{order.shipping_address.email}</div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineMapPin size={18} />
                                    <div className="ml-1 mt-1">Địa chỉ</div>
                                </div>
                                <div className="address-card__row-content mt-2">
                                    {order.shipping_address.shipping_address}
                                </div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineClock size={18} />
                                    <div className="ml-1 mt-1">Ngày đặt hàng</div>
                                </div>
                                <div className="address-card__row-content mt-2">{validateDate(order.order_date)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {renderCart()}

            {!order.rating_id && order.order_status == "completed" && <div className="mt-3 d-flex justify-content-between align-items-center">
                <div style={{ color: "gray", fontSize: 14 }}>Đơn hàng chưa có đánh giá</div>
                <button onClick={() => setOpenModal(true)} className="btn btn-primary btn-sm d-flex align-items-center">
                    Đánh giá
                </button>
            </div>}

            <RatingModal />
        </React.Fragment>
    );
}
