// react
import React, { useState, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import Barcode from "react-barcode";
import Modal from "react-modal";
import {
    HiOutlineDocumentText,
    HiOutlineMapPin,
    HiOutlineHeart,
    HiOutlineChevronRight,
    HiOutlineQuestionMarkCircle,
    HiOutlineEnvelope,
    HiXMark,
    HiOutlineUser,
    HiOutlineCreditCard,
    HiOutlineStar,
} from "react-icons/hi2";
import { useRequest } from "ahooks";

import { useUserState } from "../../atom/user";
import { useAuthStateValue } from "../../atom/auth";
import { convertSDT } from "../../utils/convertSDT";
import { getInfoUserApi, updateUserApi } from "../../services/api/user_api";
import { getPhoneUser, openChatScreen, ratingMiniApp, setDataToStorage } from "../../utils/constant/config";
import { StorageKey } from "../../services/storage";

export default function AccountDashboardMobile() {
    const [user, setUserState] = useUserState();
    const auth = useAuthStateValue();
    const history = useHistory();
    const [phone, setPhone] = useState();
    const [modalIsOpen, setIsOpen] = useState({ open: true, index: null });

    const { runAsync: updatePhoneUser } = useRequest(() => updateUserApi(user, { token: auth.token }), {
        debounceInterval: 250,
        cacheKey: "update-zalo",
        manual: true,
    });
    const { data: userInfo, runAsync: getInfoUser } = useRequest(() => getInfoUserApi({ token: auth.token }), {
        debounceInterval: 250,
        cacheKey: "info-user",
        manual: true,
    });

    useEffect(() => {
        const fetchData = async () => {
            const phone = await localStorage.getItem(StorageKey.Phone);
            // console.log(phone);
            let checkPhone = phone == "null" ? null : phone;
            setPhone(checkPhone);
            await getInfoUser().catch(err => console.log(err));
            if (checkPhone) {
                await updatePhoneUser()
                    .then((res) => {
                        // console.log("add sdt successfully", res.data.user);
                    })
                    .catch((err) => console.log("Login error", err));
            }
        };
        fetchData();
    }, [modalIsOpen.open]);

    const handleSubmit = async () => {
        getPhoneUser()
            .then((res) => {
                console.log("res", res);
                const phoneConvert = convertSDT(res);
                const nameUser = user.name;
                setUserState((data) => ({
                    ...data,
                    phone: phoneConvert,
                    name: nameUser,
                }));
                setDataToStorage(StorageKey.Phone, phoneConvert);
                setIsOpen((data) => ({ ...data, open: false })); //close modal
            })
            .catch((err) => {
                console.error("checkout error", err);
                history.goBack();
            });
    };

    const ItemCard = (props) => {
        const { icon, label } = props;
        return (
            <div
                {...props}
                className="d-flex align-items-center justify-content-between py-2 "
                style={{ marginLeft: 1, marginRight: 1 }}
            >
                <div className="d-flex align-items-center">
                    {icon}
                    <div style={{ marginLeft: 12, fontSize: 14, color: "black" }}>{label}</div>
                </div>
                <HiOutlineChevronRight size={24} color={"#ffd333"} />
            </div>
        );
    };

    const FollowModal = () => {
        const customStyles = {
            content: {
                top: "45%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                transform: "translate(-50%, -50%)",
                border: 0,
                backgroundColor: null,
                width: "90%",
            },
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                zIndex: 99999,
            },
        };

        return (
            <Modal
                isOpen={modalIsOpen.open}
                onRequestClose={() => setIsOpen((data) => ({ ...data, open: false }))}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
            >
                <div
                    className="text-warning justify-content-end d-flex rounded-circle"
                    style={{
                        fontWeight: "500",
                        fontSize: 19,
                        textAlign: "center",
                        fontSize: 18,
                        position: "absolute",
                        top: 10,
                        right: 10,
                        padding: 5,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                    }}
                >
                    <div onClick={() => history.goBack()}>
                        <HiXMark size={25} color="#ffd333" />
                    </div>
                </div>
                <div
                    className="justify-content-center"
                    style={{
                        fontWeight: "500",
                        fontSize: 19,
                        textAlign: "center",
                        fontSize: 18,
                        paddingTop: 40,
                    }}
                >
                    <p className="mx-5 mt-3" style={{ fontSize: 14, color: "#e5e7eb" }}>
                        Cho phép truy cập thông tin của bạn để đặt hàng, xem lịch sử đơn hàng, danh sách địa chỉ, sản
                        phẩm yêu thích và nhiều tính năng khác.
                    </p>
                    <img src="https://i.imgur.com/FRffDAL.png" style={{ height: "50%", width: "50%", marginTop: 10 }} />
                    <div
                        onClick={() => handleSubmit()}
                        style={{
                            backgroundColor: "#ffd333",
                            fontWeight: "500",
                            padding: 8,
                            borderRadius: 10,
                            color: "black",
                            fontSize: 14,
                            marginRight: 30,
                            marginLeft: 30,
                            marginTop: "34%",
                        }}
                    >
                        Cho phép
                    </div>
                </div>
            </Modal>
        );
    };

    const MemberCard = () => {
        const icon = {
            bronze: "https://cdn-icons-png.flaticon.com/512/7645/7645366.png",
            silver: "https://cdn-icons-png.flaticon.com/512/7645/7645294.png",
            gold: "https://cdn-icons-png.flaticon.com/512/7645/7645279.png",
            diamond: "https://cdn-icons-png.flaticon.com/512/1021/1021204.png",
        };
        const checkRank = (rank) => {
            if (rank == "ĐỒNG") return "https://cdn-icons-png.flaticon.com/512/7645/7645366.png";
            if (rank == "BẠC") return "https://cdn-icons-png.flaticon.com/512/7645/7645294.png";
            if (rank == "VÀNG") return "https://cdn-icons-png.flaticon.com/512/7645/7645279.png";
            if (rank == "KIM CƯƠNG") return "https://cdn-icons-png.flaticon.com/512/1021/1021204.png";
        };
        return (
            <div style={{ backgroundColor: "#fef9c3", borderRadius: 15 }} className="px-3 pt-2 mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                        <span style={{ fontSize: 14, fontWeight: "500" }}>Thành viên: </span>
                        <span style={{ fontSize: 14, fontWeight: "500", color: "orange" }}>
                            {userInfo?.data?.user?.member?.rank}
                        </span>
                        <img
                            src={checkRank(userInfo?.data?.user?.member?.rank)}
                            style={{ width: 22, height: 22, marginLeft: 3 }}
                        />
                    </div>
                    <div>
                        <span style={{ fontSize: 14, fontWeight: "500" }}>Điểm tích lũy: </span>
                        <span style={{ fontSize: 15, fontWeight: "500", color: "orange" }}>
                            {userInfo?.data?.user?.rank_point}
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                        <span style={{ fontSize: 14 }}>Điểm để đạt thứ hạng kế tiếp: </span>
                        <span style={{ fontSize: 14, fontWeight: "500", color: "orange" }}>
                            {userInfo?.data?.user?.member?.max_point}
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div class="progress bg-white" style={{ height: 6, width: "100%" }}>
                        <div
                            class="progress-bar"
                            role="progressbar"
                            style={{
                                width: `${
                                    (userInfo?.data?.user?.rank_point / userInfo?.data?.user?.member?.max_point) * 100
                                }%`,
                                height: 6,
                                backgroundColor: "orange",
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                    <span className="ml-3">
                        {(userInfo?.data?.user?.rank_point / userInfo?.data?.user?.member?.max_point) * 100}%
                    </span>
                </div>
                <div style={{ backgroundColor: "#d1d5db", width: "100%", height: 1 }} className="my-2" />
                <div className="w-100 d-flex justify-content-center pb-3">
                    <Barcode value={user.id} height={60} fontSize={14} />
                </div>
            </div>
        );
    };

    const Utilities = () => {
        return (
            <div style={{ backgroundColor: "#f9fafb", borderRadius: 15 }} className="my-3 py-3">
                <div className="row align-items-center">
                    <div className="col">
                        <div onClick={() => history.push("/account/orders")} style={{ marginLeft: 15 }}>
                            <HiOutlineDocumentText size={28} style={{ width: "100%", marginBottom: 4, color: "#ffd333", alignItems: "center" }} />
                            <div style={{ fontSize: 13, fontWeight: "500", color: "#6b7280", textAlign: "center" }}>Đơn hàng</div>
                        </div>
                    </div>
                    <div className="col border-right border-left">
                        <div onClick={() => history.push("/shop/wishlist")}>
                            <HiOutlineHeart
                                size={28}
                                style={{ width: "100%", marginBottom: 4, color: "#ffd333", alignItems: "center" }}
                            />
                            <div style={{ fontSize: 13, fontWeight: "500", color: "#6b7280", textAlign: "center" }}>
                                Yêu thích
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div onClick={() => history.push("/account/addresses")} style={{ marginRight: 15 }}>
                            <HiOutlineMapPin
                                size={28}
                                style={{ width: "100%", marginBottom: 4, color: "#ffd333", alignItems: "center" }}
                            />
                            <div style={{ fontSize: 13, fontWeight: "500", color: "#6b7280", textAlign: "center" }}>
                                Địa chỉ
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Personal = () => {
        return (
            <div className="px-3 py-2 mb-3" style={{ backgroundColor: "#f9fafb", borderRadius: 15 }}>
                <span style={{ fontSize: 16, fontWeight: "500" }}>Cá nhân</span>
                <ItemCard
                    icon={<HiOutlineUser size={24} color={"#ffd333"} />}
                    label="Thông tin cá nhân"
                    onClick={() => history.push("/account/profile")}
                />
                <div style={{ backgroundColor: "#d1d5db", width: "100%", height: 1 }} className="my-1" />
                <ItemCard
                    icon={<HiOutlineCreditCard size={24} color={"#ffd333"} />}
                    label="Thiết lập thanh toán"
                    // onClick={() => ratingMiniApp()}
                />
            </div>
        );
    };

    const Other = () => {
        return (
            <div className="px-3 py-2 mb-3" style={{ backgroundColor: "#f9fafb", borderRadius: 15 }}>
                <span style={{ fontSize: 16, fontWeight: "500" }}>Khác</span>
                <ItemCard
                    icon={<HiOutlineEnvelope size={24} color={"#ffd333"} />}
                    label="Liên hệ và góp ý"
                    onClick={openChatScreen}
                />
                <div style={{ backgroundColor: "#d1d5db", width: "100%", height: 1 }} className="my-1" />
                <ItemCard
                    icon={<HiOutlineQuestionMarkCircle size={24} color={"#ffd333"} />}
                    label="Điều khoản và điều kiện"
                    onClick={() => history.push("/site/terms")}
                />
            </div>
        );
    };

    return (
        <React.Fragment>

            <Helmet>
                <title>{`Cá nhân`}</title>
            </Helmet>

            {!phone && <FollowModal />}

            <div className="w-100 pb-3 border-bottom">
                <div className="d-flex align-items-center">
                    <img
                        src={user?.img_url || "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                        alt=""
                        class="rounded-circle "
                        style={{ width: 80, height: 80 }}
                    />
                    <div className="ml-2">
                        <div className="profile-card__name" style={{ color: "black", fontSize: 20 }}>
                            {user.name || "Tên người dùng"}
                        </div>
                        <div className="" style={{ color: "black", fontWeight: "400", fontSize: 12 }}>
                            ID: {user.id || "xxxxxx xxxxxx xxxxxx xxxxxx"}
                        </div>
                    </div>
                </div>
            </div>

            <Utilities />

            <MemberCard />

            <Personal />

            <Other />

            <div class="row justify-content-end mr-2 mt-4">
                <span style={{ fontSize: 13, fontWeight: "300", color: "gray" }}>
                    Design By <a href="https://digibird.io/" style={{ color: "#ffd333", fontWeight: "500" }}>DigiBird Co.</a>
                </span>
            </div>

        </React.Fragment>
    );
}
