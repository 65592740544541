import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';
import { openChatScreen } from '../../utils/constant/config';
import { useBannerFeatureValue } from '../../atom/banner_feature';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const BlockSlideShow = ({ locale, withDepartments, platform }) => {
    const departmentsAreaRef = useRef(null);
    const history = useHistory();
    const media = window.matchMedia('(min-width: 992px)');

    const banner = useBannerFeatureValue();
    const banner_img = banner.front_settings.top_banners_details;

    /* useEffect(() => {
        const onChangeMedia = () => {
            if (media.matches) {
                departmentsAria.area = departmentsAreaRef.current;
            }
        };

        if (media.addEventListener) {
            media.addEventListener('change', onChangeMedia);
        } else {
            media.addListener(onChangeMedia);
        }

        return () => {
            departmentsAria.area = null;

            if (media.removeEventListener) {
                media.removeEventListener('change', onChangeMedia);
            } else {
                media.removeListener(onChangeMedia);
            }
        };
    }, [media]); */

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });

    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    

    const setDepartmentsAreaRef = (ref) => {
        departmentsAreaRef.current = ref;

        if (media.matches) {
            departmentsAria.area = departmentsAreaRef.current;
        }
    };

    const { direction } = languages[locale];

    const blockClasses = classNames('block-slideshow block', {
        'block-slideshow--layout--full': !withDepartments,
        'block-slideshow--layout--with-departments': withDepartments,
    });

    const layoutClasses = classNames('col-12', {
        'col-lg-12': !withDepartments,
        'col-lg-9': withDepartments,
    });

    const slides = [
        {
            title: /* 'Sản phẩm chất lượng<br>đến từng chi tiết' */'',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: {
                ltr: banner_img && banner_img[2]?.name,
                rtl: banner_img && banner_img[2]?.name,
            },
            image_full: {
                ltr: banner_img && banner_img[2]?.name,
                rtl: banner_img && banner_img[2]?.name,
            },
            image_mobile: {
                ltr: banner_img && banner_img[2]?.name,
                rtl: banner_img && banner_img[2]?.name,
            },
        },
        {
            title: /* 'Vận chuyển nhanh<br> chóng, đóng gói cẩn thận' */'',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: {
                ltr: banner_img && banner_img[1]?.name,
                rtl: banner_img && banner_img[1]?.name,
            },
            image_full: {
                ltr: banner_img && banner_img[1]?.name,
                rtl: banner_img && banner_img[1]?.name,
            },
            image_mobile: {
                ltr: banner_img && banner_img[1]?.name,
                rtl: banner_img && banner_img[1]?.name,
            },
        },
        {
            title: /* 'Giá cả hợp lý,<br> cạnh tranh thị trường' */'',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: {
                ltr: banner_img && banner_img[0]?.name,
                rtl: banner_img && banner_img[0]?.name,
            },
            image_full: {
                ltr: banner_img && banner_img[0]?.name,
                rtl: banner_img && banner_img[0]?.name,
            },
            image_mobile: {
                ltr: banner_img && banner_img[0]?.name,
                rtl: banner_img && banner_img[0]?.name,
            },
        },
    ];

    return (

        <div className={blockClasses}>
            <div className="container">
                <div className="row">
                    {/* {withDepartments && (
                        <div className="col-3 d-lg-block d-none" ref={setDepartmentsAreaRef} />
                    )} */}
                    <div className={`col-lg-12`}>
                        <div className="block-slideshow__body ml-0" style={{ height: platform === 'web' ? windowSize.width < 600 ? '195px': '' : '180px'}}>
                            <StroykaSlick {...slickSettings}>
                                {slides.map((slide, index) => {
                                    const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];

                                    return (
                                        <div key={index} className={`block-slideshow__slide ${platform == 'web' ? windowSize.width < 600 ? 'block-slideshow__slide-web': '' : 'block-slideshow__slide-app'}`}>
                                            <div
                                                className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                                                style={{
                                                    backgroundImage: `url(${image})`,
                                                    borderRadius: platform === 'web' ? '' : '10px',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                }}
                                            />
                                            <div
                                                className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                                                style={{
                                                    backgroundImage: `url(${image})`,
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                    borderRadius: platform === 'web' ? '' : '10px',
                                                }}
                                            />
                                            {/* <div className="block-slideshow__slide-content">
                                                <div
                                                    className="block-slideshow__slide-title"
                                                    style={{ color: '#FFCC00' }}
                                                    dangerouslySetInnerHTML={{ __html: slide.title }}
                                                />
                                                <div
                                                    className="block-slideshow__slide-text"
                                                    dangerouslySetInnerHTML={{ __html: slide.text }}
                                                />
                                                <div className="block-slideshow__slide-button">
                                                    <div
                                                        onClick={() => platform !== 'web' ? openChatScreen() : {}}
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        Liên hệ ngay
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    );
                                })}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
    platform: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
