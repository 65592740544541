// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import { useRequest } from "ahooks";
import { getMyOrdersApi } from "../../services/api/order_api";
import { useAuthStateValue } from "../../atom/auth";
import { useUserState, useUserValue } from "../../atom/user";
import Currency from "../shared/Currency";
import { validateDate } from "../../utils/constant/convertDate";
import { getAddressApi } from "../../services/api/address_api";
import { getInfoUserApi } from "../../services/api/user_api";
import { useEffect } from "react";
import { usePlatformValue } from "../../atom/platform";
import BlockLoader from "../blocks/BlockLoader";
import { getAccessToken } from "zmp-sdk";
import { isTokenValid } from "../../services/tokenManager";

export default function AccountPageDashboard() {
    const [user, setUser] = useUserState();
    const auth = useAuthStateValue();
    const platform = usePlatformValue();
    const { runAsync: getInfoUser } = useRequest((token) => getInfoUserApi(token), {
        debounceInterval: 250,
        cacheKey: "info-user",
        manual: true,
    });
    const {
        runAsync: getMyOrders,
        loading: getMyOrdersLoading,
        data: myOrders,
    } = useRequest((token) =>getMyOrdersApi(token),{
            debounceInterval: 250,
            cacheKey: "myOrders",
        });

    const {
        runAsync: getAddresses,
        loading: getAddressesLoading,
        data: addresses,
    } = useRequest((token) => getAddressApi(token), {
            debounceInterval: 250,
            cacheKey: "address-list",
        });
    useEffect(() => {
        const fetchData = async() => {
            await getInfoUser({ token: auth.token }).then(res => {
                setUser(res.data.user);
            }).catch(err => console.log(err));
            await getMyOrders({ token: auth.token }).catch(err => console.log(err));
            await getAddresses({ token: auth.token }).catch(err => console.log(err));
        }
        fetchData();
    },[auth.token])

    const Status = ({ value, cancelled, paid }) => {
        return (
            <div style={{ color: "black", fontSize: 16, whiteSpace: "nowrap" }}>
                {paid == "paid" ? (
                    <span
                        className="px-3 py-1"
                        style={{
                            borderRadius: 10,
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#389e0d",
                            backgroundColor: "#f6ffed",
                        }}
                    >
                        Hoàn thành
                    </span>
                ) : cancelled == 0 ? (
                    <span
                        className="px-3 py-1"
                        style={{
                            borderRadius: 10,
                            fontSize: 12,
                            fontWeight: "500",
                            color:
                                value == "ordered"
                                    ? "#096dd9"
                                    : value == "confirmed"
                                    ? "#08979c"
                                    : value == "delivered"
                                    ? "#f97316"
                                    : value == "cancelled"
                                    ? "#FFFFFF"
                                    : "#389e0d",
                            backgroundColor:
                                value == "ordered"
                                    ? "#e6f7ff"
                                    : value == "confirmed"
                                    ? "#e6fffb"
                                    : value == "delivered"
                                    ? "#ffedd5"
                                    : value == "cancelled"
                                    ? "#ef4444"
                                    : "#f6ffed",
                        }}
                    >
                        {value == "ordered" ? "Chờ xét duyệt" : value == "confirmed" ? "Đã xác nhận" : "Đang giao hàng"}
                    </span>
                ) : (
                    <span
                        className="px-3 py-1"
                        style={{
                            borderRadius: 10,
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#FFFFFF",
                            backgroundColor: "#ef4444",
                        }}
                    >
                        Đã hủy
                    </span>
                )}
            </div>
        );
    };

    const orders = myOrders?.data?.orders?.slice(0, 3).map((order) => (
        <tr key={order.id}>
            <td>
                <Link to={{ pathname: `/account/orders/${order.unique_id}`, state: { order: order } }}>{`#${order.unique_id}`}</Link>
            </td>
            <td>{validateDate(order.order_date)}</td>
            <td>
                <Status value={order.order_status} cancelled={order.cancelled} paid={order.payment_status} />
            </td>
            <td><Currency value={order.total} /></td>
        </tr>
    ));

    return (
        <div className="dashboard">
            <Helmet>
                <title>Tài khoản</title>
            </Helmet>
            {getMyOrdersLoading && <BlockLoader />}
            <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    <div className="profile-card__avatar">
                        <img src={user.profile_image_url ? user.profile_image_url : "https://test-pos.digibird.io/images/user.png"} alt="" />
                    </div>
                    <div className="profile-card__name">{user.name}</div>
                    <div className="profile-card__email">{user.email}</div>
                    <div className="profile-card__edit">
                        <Link to="profile" className="btn btn-secondary btn-sm">
                            Chỉnh sửa
                        </Link>
                    </div>
                </div>
            </div>
            <div className="dashboard__address card address-card address-card--featured">
                {/* {address.default && <div className="address-card__badge">Mặc định</div>} */}
                {addresses?.data?.length > 0 ? (
                    <div className="address-card__body">
                        <div className="address-card__name">{addresses?.data[0]?.name}</div>
                        <div className="address-card__row">
                            {addresses?.data[0]?.shipping_address}, {addresses?.data[0]?.state},{" "}
                            {addresses?.data[0]?.city}, {addresses?.data[0]?.country}
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Số điện thoại</div>
                            <div className="address-card__row-content">{addresses?.data[0]?.phone}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Địa chỉ email</div>
                            <div className="address-card__row-content">{addresses?.data[0]?.email}</div>
                        </div>
                        <div className="address-card__footer">
                            <Link
                                to={{
                                    pathname: `/account/addresses/edit/${addresses?.data[0]?.xid}`,
                                    state: { data: addresses?.data[0] },
                                }}
                            >
                                Chỉnh sửa địa chỉ
                            </Link>
                        </div>
                    </div>
                ) : (
                    <Link
                        to={{ pathname: `/account/addresses/add`, state: { data: null } }}
                        className="addresses-list__item--new h-100 m-3"
                    >
                        <div className="addresses-list__plus" />
                        <div className="btn btn-secondary btn-sm">
                            Thêm mới
                        </div>
                    </Link>
                )}
            </div>
            <div className="dashboard__orders card">
                <div className="card-header">
                    <h5>Đơn đặt hàng gần đây</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        {myOrders?.data?.orders?.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Mã đơn hàng</th>
                                        <th>Ngày đặt</th>
                                        <th>Trạng thái</th>
                                        <th>Tổng tiền</th>
                                    </tr>
                                </thead>
                                <tbody>{orders}</tbody>
                            </table>
                        ) : (
                            <div className="block block-empty" style={{ marginBottom: 0 }}>
                                <div className="container">
                                    <div className="block-empty__body">
                                        <div>
                                            <img
                                                src="https://cdn-icons-png.flaticon.com/512/4318/4318459.png"
                                                style={{ height: 48 }}
                                            />
                                        </div>
                                        <div className="block-empty__message mt-3">Bạn chưa có đơn hàng nào,</div>
                                        <div className="block-empty__message">Hãy đặt hàng ngay nào!</div>
                                        <div className="block-empty__actions">
                                            <Link to="/" className="btn btn-primary btn-sm shadow-sm">
                                                Đặt hàng ngay
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
