export function formatName(str) {
    if (!str) {
        return str; // Return the original string if it is empty or undefined
      }
    
      var words = str.toLowerCase().split(" "); // Convert the string to lowercase and split into an array of words
    
      // Capitalize the first character of each word
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        if (word.length > 0) {
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
      }
    
      return words.join(" "); // Join the words back into a single string
  }