export const formatCategory = (idCategory) => {
    if(idCategory)
        return `category_id eq ${idCategory} `;
    else return '';
}

export const formatPrice = (priceMin, priceMax) => {
    if(priceMin !== 0 && priceMax !== 0){
        let filterPrice = `sales_price gte ${priceMin} and sales_price lte ${priceMax}`;
        return `${filterPrice} `;
    }
    else if(priceMin == 0 && priceMax !== 0){
        let filterPrice = `sales_price lte ${priceMax}`;
        return `${filterPrice} `;
    }
    else 
        return '';
}

export const formatBrand = (listBrand) => {
    if(listBrand?.length > 0){
        return `brand_id eq ${listBrand[0]}`;
    } else return ''
}

export const formatFilter = (category, price, brand) => {
    if(category !== ''){
        const priceFormat = price? `and ${price}` : '';
        const brandFormat = brand? `and ${brand}` : '';
        return encodeURIComponent(`${category}${priceFormat}${brandFormat}`);
    }
    else{
        const brandFormat = price ? brand ? `and ${brand}` : '' : brand;
        return encodeURIComponent(`${price}${brandFormat}`);
    }
    
}
