import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import Rating from "./Rating";
import { HiHeart, HiShare, HiShoppingCart } from "react-icons/hi2";
import { HiHome } from "react-icons/hi";
import { usePlatformValue } from "../../atom/platform";
import { useWishList } from "../../atom/wishlist";
import { toast } from "react-toastify";
import { useCart } from "../../atom/cart";
import { shareCurrentPage } from "../../utils/constant/config";
import { useRef } from "react";
import { useEffect } from "react";
import { useInfoAppValue } from "../../atom/info_app";
import { useLocation } from "react-router-dom";

const Product = ({ product, layout, reviewsData }) => {
    const { cart, setCart, addToCart, removeFromCart, isItemInCart, getQuantity, updateCartItem } = useCart();
    const { wishList, setWishList, addToWishlist, removeFromWishlist, isWishProduct } = useWishList();
    const history = useHistory();

    const platform = usePlatformValue();
    const [quantity, setQuantity] = useState(getQuantity(product?.product_id));
    const infoApp = useInfoAppValue();
    const location = useLocation();
    const pathShareLink = infoApp.env ? `${location.pathname}?env=${infoApp.env}&version=${infoApp.version}` : '';

    const handleAddToWishlist = (product) => {
        addToWishlist(product);
        toast.success(`Đã thêm "${product.name}" vào danh sách yêu thích!`, {
            theme: "colored",
            className: "mx-2 rounded mt-2",
        });
    };

    const handleRemoveFromWishlist = (product) => {
        removeFromWishlist(product.product_id);
        toast.success(`Đã xóa "${product.name}" khỏi danh sách yêu thích!`, {
            theme: "colored",
            className: "mx-2 rounded mt-2",
        });
    };

    const handleAddToCart = (product, quantity) => {
        addToCart(product, quantity);
        toast.success(`Đã thêm "${product.name}" vào giỏ hàng!`, { theme: "colored", className: "mx-2 rounded mt-2" });
    };

    const handleChangeQuantity = (item, newQuantity) => {
        setQuantity(newQuantity);
        addToCart(item, newQuantity, "multi");
    };

    let prices;

    if (product?.compareAtPrice) {
        prices = (
            <>
                <span className="product__new-price">
                    <Currency value={product?.sales_price} />
                </span>{" "}
                <span className="product__old-price">
                    <Currency value={product?.purchase_price} />
                </span>
            </>
        );
    } else {
        prices = <Currency value={product?.sales_price} />;
    }

    const componentRef = useRef(null);
    useEffect(() => {
        // Scroll to the component's DOM element when it mounts
        // console.log("onto screen");
        if (componentRef.current) {
            componentRef.current.scrollIntoView({});
        }
    }, []);

    return (
        <div className={`product product--layout--${layout} mt-3`} ref={componentRef}>
            <div className="product__content">
                <ProductGallery layout={layout} images={[product?.img_sapo || product?.image_url]} />

                <div className="product__info">
                    <div className="product__wishlist-compare">
                        <button
                            type="button"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Wishlist"
                            onClick={() =>
                                isWishProduct(product.product_id)
                                    ? handleRemoveFromWishlist(product)
                                    : handleAddToWishlist(product)
                            }
                            style={{
                                backgroundColor: "#f0f0f0",
                                height: 46,
                                width: 60,
                                justifyItems: "center",
                                alignItems: "center",
                                border: "none",
                            }}
                        >
                            <HiHeart
                                size={24}
                                color={isWishProduct(product?.product_id) && "red"}
                                style={{ display: "inline" }}
                            />
                        </button>
                    </div>
                    <h1 className="product__name">{product?.name?.toUpperCase()}</h1>
                    <div className="product__rating">
                        <div className="product__rating-stars">
                            <Rating value={product?.rating || 5} />
                        </div>
                        <div className="product__rating-legend">
                            <span>
                                {reviewsData?.data.length > 0
                                    ? reviewsData?.data.length + " đánh giá"
                                    : "Chưa có đánh giá"}
                            </span>
                        </div>
                        <div className="product__rating-legend">
                            <span>Đã bán {product?.total_quantity || 0}</span>
                        </div>
                    </div>
                    <div className="product__description mt-2" style={{ textAlign: "justify" }}>
                        {product?.subDescription ||
                            "Hiện nay các loại thiết bị máy móc công nghiệp tại tphcm rất nhiều loại. Được ra đời bởi sự nghiên cứu và sáng tạo từ những con người nhằm thúc đẩy sự phát triển ngành công nghiệp. Nên các loại thiết bị máy móc hạng nặng trong cơ khí rất là đa dạng như máy khoan bàn, máy khoan từ, máy cưa vòng, máy đột lỗ thủy lực v.v… "}
                    </div>
                    {/* <ul className="product__features">
                        <li>Speed: 750 RPM</li>
                        <li>Power Source: Cordless-Electric</li>
                        <li>Battery Cell Type: Lithium</li>
                        <li>Voltage: 20 Volts</li>
                        <li>Battery Capacity: 2 Ah</li>
                    </ul> */}
                    <ul className="product__meta">
                        <li className="product__meta-availability">
                            Tình trạng:{" "}
                            {product?.status == "out_of_stock" ? (
                                <span className="text-danger">Hết hàng</span>
                            ) : (
                                <span className="text-success">Còn hàng</span>
                            )}
                        </li>
                        <li>{product?.atrribute.replace(":", ": ")}</li>
                        <li>Nhãn hàng: {product?.name_brand}</li>
                    </ul>
                </div>

                <div className="product__sidebar">
                    <div className="product__availability">
                        Tình trạng: <span className="text-success">{product?.status}</span>
                    </div>

                    <div className="product__prices">{prices}</div>
                    <form className="product__options">
                        {platform === "web" ? (
                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">
                                    Số lượng
                                </label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={quantity}
                                            onChange={(newQuantity) => handleChangeQuantity(product, newQuantity)}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <button
                                            type="button"
                                            onClick={() => handleAddToCart(product, quantity)}
                                            disabled={!quantity}
                                            className={classNames("btn btn-primary btn-lg")}
                                            style={{
                                                backgroundColor: "#ffd333",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                border: "none",
                                            }}
                                        >
                                            <HiShoppingCart size={18} color="#212529" />
                                        </button>
                                    </div>
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <button
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Wishlist"
                                            onClick={() =>
                                                isWishProduct(product.product_id)
                                                    ? handleRemoveFromWishlist(product)
                                                    : handleAddToWishlist(product)
                                            }
                                            style={{
                                                backgroundColor: isWishProduct(product?.product_id)
                                                    ? "#fee2e2"
                                                    : "#f0f0f0",
                                                height: 46,
                                                width: 60,
                                                justifyItems: "center",
                                                alignItems: "center",
                                                border: "none",
                                            }}
                                        >
                                            <HiHeart
                                                size={24}
                                                color={isWishProduct(product?.product_id) && "red"}
                                                style={{ display: "inline" }}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="fixed-bottom z-50 d-flex align-items-center justify-content-between border px-2"
                                style={{ backgroundColor: "#f9fafb", height: 64 }}
                            >
                                <div className="d-flex">
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={() => history.push("/")}
                                        style={{
                                            backgroundColor: "#f0f0f0",
                                            height: 46,
                                            justifyItems: "center",
                                            alignItems: "center",
                                            border: "none",
                                            marginRight: 6,
                                            borderRadius: 5,
                                        }}
                                    >
                                        <HiHome size={24} color="black" style={{ display: "inline" }} />
                                    </button>

                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={() =>
                                            isWishProduct(product.product_id)
                                                ? handleRemoveFromWishlist(product)
                                                : handleAddToWishlist(product)
                                        }
                                        style={{
                                            backgroundColor: isWishProduct(product?.product_id) ? "#fee2e2" : "#f0f0f0",
                                            height: 46,
                                            justifyItems: "center",
                                            alignItems: "center",
                                            border: "none",
                                            borderRadius: 5,
                                        }}
                                    >
                                        <HiHeart
                                            size={24}
                                            color={isWishProduct(product?.product_id) ? "red" : "black"}
                                            style={{ display: "inline" }}
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={async () =>
                                            await shareCurrentPage(product?.name, product?.img_sapo, pathShareLink)
                                        }
                                        style={{
                                            backgroundColor: "#f0f0f0",
                                            height: 46,
                                            justifyItems: "center",
                                            alignItems: "center",
                                            border: "none",
                                            marginLeft: 6,
                                            borderRadius: 5,
                                        }}
                                    >
                                        <HiShare size={24} color="black" style={{ display: "inline" }} />
                                    </button>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={quantity}
                                            onChange={(newQuantity) => handleChangeQuantity(product, newQuantity)}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleAddToCart(product, quantity)}
                                        disabled={!quantity}
                                        className={classNames("btn btn-primary btn-lg")}
                                        style={{
                                            backgroundColor: "#ffd333",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            border: "none",
                                        }}
                                    >
                                        <HiShoppingCart size={18} color="#212529" />
                                    </button>
                                </div>
                            </div>
                        )}
                    </form>
                </div>

                <div className="product__footer">
                    {/* <div className="product__tags tags">
                        <div className="tags__list">
                            <span>Điện máy</span>
                            <span>Đồ điện</span>
                            <span>Máy móc</span>
                        </div>
                    </div> */}

                    {/* {platform === "web" && (
                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like">
                                    <Link to="/">Like</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--tweet">
                                    <Link to="/">Tweet</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--pin">
                                    <Link to="/">Pin It</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--counter">
                                    <Link to="/">4K</Link>
                                </li>
                            </ul>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

export default Product;
