import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const PRODUCT_BY_CATEGORY_STATE = atom({
    key: 'PRODUCT_BY_CATEGORY_STATE', // unique ID (with respect to other atoms/selectors)
    default: {
        "current_page": 1,
        "data": [{
            "product_id": '',
            "current_stock":  0,
            "mrp": 0,
            "purchase_price": 0,
            "sales_price": 0,
            "tax_id": null,
            "purchase_tax_type": '',
            "sales_tax_type": '',
            "stock_quantitiy_alert":  0,
            "opening_stock":  0,
            "opening_stock_date": "",
            "wholesale_price": null,
            "wholesale_quantity": null,
            "status": "",
            "created_at": "",
            "updated_at": "",
            "company_id": 0,
            "name": "",
            "slug": "",
            "barcode_symbology": "",
            "item_code": "",
            "image": "",
            "description": null,
            "xid": "",
            "x_category_id": "",
            "x_brand_id": "",
            "x_unit_id": "",
            "x_user_id": "",
            "x_warehouse_id": "",
            "img_sapo": ""
        }],
        "first_page_url": "https://compassionate-wilbur.68-183-178-146.plesk.page/api/v1/front/all-products/2?page=1",
        "from": 1,
        "last_page": 1,
        "last_page_url": "https://compassionate-wilbur.68-183-178-146.plesk.page/api/v1/front/all-products/2?page=1",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "https://compassionate-wilbur.68-183-178-146.plesk.page/api/v1/front/all-products/2?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": null,
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": null,
        "path": "https://compassionate-wilbur.68-183-178-146.plesk.page/api/v1/front/all-products/2",
        "per_page": 6,
        "prev_page_url": null,
        "to": 4,
        "total": 4
    },
});

export const useProductByCategoryState = () => useRecoilState(PRODUCT_BY_CATEGORY_STATE);

export const useProductByCategoryValue = () => useRecoilValue(PRODUCT_BY_CATEGORY_STATE);

export const useProductByCategorySetValue = () => useSetRecoilState(PRODUCT_BY_CATEGORY_STATE);