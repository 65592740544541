import { ApiUtils } from '.';
import { PATH } from './path';

export const getAddressApi = async (customHeaders) => {
    return await ApiUtils.request(PATH.get_address_shipping, customHeaders);
}
// add-address-shipping
export const addAddressApi = async (data, customHeaders) => {
    return await ApiUtils.post(PATH.add_address_shipping, data, customHeaders);
}

//update-address-shipping
export const updateAddressApi = async (id, customHeaders, data) => {
    return await ApiUtils.put(PATH.update_address_shipping + id, customHeaders, data);
}

export const deleteAddressApi = async (id, customHeaders) => {
    return await ApiUtils.remove(PATH.delete_address_shipping + id, customHeaders);
}