import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const CATEGORIES_STATE = atom({
    key: 'CATEGORIES_STATE', // unique ID (with respect to other atoms/selectors)
    default: [{
        xid: '',
        name: '',
        slug: '',
        image: '',
        x_parent_id: '',
        img_sapo: '',
    }]
     // default value (aka initial value)
});

export const useCategoriesState = () => useRecoilState(CATEGORIES_STATE);

export const useCategoriesValue = () => useRecoilValue(CATEGORIES_STATE);

export const useSetCategoriesValue = () => useSetRecoilState(CATEGORIES_STATE);