// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import {
    Fi24Hours48Svg,
    FiFreeDelivery48Svg,
    FiPaymentSecurity48Svg,
    FiTag48Svg,
} from '../../svg';

export default function BlockFeatures(props) {
    const { layout } = props;

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='mt-5 container'>
            {windowSize.width < 600 ?
                <div className={` block block-features block-features--layout--boxed`}>
                    <div className="">
                        <div className="block-features__list">
                            <div className='row mr-1 ml-1'>
                            <div className="block-features__item border block-features__item2">
                                <div className="block-features__icon">
                                    <FiFreeDelivery48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Miễn phí vận chuyển</div>
                                    <div className="block-features__subtitle">Đơn hàng {'>'} 300.000đ</div>
                                </div>
                            </div>
                            
                            <div className="block-features__item border block-features__item2">
                                <div className="block-features__icon">
                                    <Fi24Hours48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Hỗ trợ 24/7</div>
                                    <div className="block-features__subtitle">Liên hệ bất cứ lúc nào bạn cần</div>
                                </div>
                            </div>
                            </div>
                            
                            <div className='row  mr-1 ml-1'>
                            <div className="block-features__item border">
                                <div className="block-features__icon">
                                    <FiPaymentSecurity48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">100% An toàn</div>
                                    <div className="block-features__subtitle">Thanh toán dễ hàng</div>
                                </div>
                            </div>
                            
                            <div className="block-features__item border">
                                <div className="block-features__icon">
                                    <FiTag48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Sản phẩm đa dạng mẫu mã</div>
                                    <div className="block-features__subtitle">Giảm giá đến 30%</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                :

                <div className={`block block-features block-features--layout--${layout}`}>
                    <div className="">
                        <div className="block-features__list">
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <FiFreeDelivery48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Miễn phí vận chuyển</div>
                                    <div className="block-features__subtitle">Đơn hàng {'>'} 300.000đ</div>
                                </div>
                            </div>
                            <div className="block-features__divider" />
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <Fi24Hours48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Hỗ trợ 24/7</div>
                                    <div className="block-features__subtitle">Liên hệ bất cứ lúc nào</div>
                                </div>
                            </div>
                            <div className="block-features__divider" />
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <FiPaymentSecurity48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">100% An toàn</div>
                                    <div className="block-features__subtitle">Thanh toán dễ hàng</div>
                                </div>
                            </div>
                            <div className="block-features__divider" />
                            <div className="block-features__item">
                                <div className="block-features__icon">
                                    <FiTag48Svg />
                                </div>
                                <div className="block-features__content">
                                    <div className="block-features__title">Sản phẩm đa dạng</div>
                                    <div className="block-features__subtitle">Giảm giá đến 30%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
