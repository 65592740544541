import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { setDataToStorage } from "../utils/constant/config";
import { StorageKey } from "../services/storage";

const WISHLIST_STATE = atom({
    key: "WISHLIST_STATE", // unique ID (with respect to other atoms/selectors)
    default: [],
    // default value (aka initial value)
});

export const useWishList = () => {
    const [wishList, setWishList] = useRecoilState(WISHLIST_STATE);

    const addToWishlist = (product) => {
        let updatedWisList = [product, ...wishList];
        setWishList(updatedWisList);
        setDataToStorage(StorageKey.WishList, updatedWisList);
    };

    const removeFromWishlist = (itemId) => {
        let updatedWisList = wishList.filter((item) => item.product_id !== itemId);
        setWishList(updatedWisList);
        setDataToStorage(StorageKey.WishList, updatedWisList);
    };

    const isWishProduct = (itemId) => {
        const index = wishList.findIndex((item) => item.product_id === itemId);
        return index != -1;
    };

    return { wishList, setWishList, addToWishlist, removeFromWishlist, isWishProduct };
};

export const useWishListState = () => useRecoilState(WISHLIST_STATE);

export const useWishListValue = () => useRecoilValue(WISHLIST_STATE);

export const useSetWishListValue = () => useSetRecoilState(WISHLIST_STATE);
