import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";

import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";

import { useOrder } from "../../atom/order";
import { useAuthStateValue } from "../../atom/auth";
import { checkoutOrdersApi, checkoutOrdersVNPayApi, saveOrderApi } from "../../services/api/order_api";
import { useRequest } from "ahooks";
import { APP_ID, ID_COMPANY, BASE_URL_PRODUCTION, BASE_URL_DEVELOPMENT } from "../../utils/constant";
import { useWarehouseValue } from "../../atom/warehouse";
import BlockLoader from "../blocks/BlockLoader";
import { formatDateVNPay, validateDate } from "../../utils/constant/convertDate";
import { toast } from "react-toastify";
import { StorageKey } from "../../services/storage";
import { useEffect } from "react";
import { getAddressApi } from "../../services/api/address_api";
import { HiOutlineBanknotes, HiOutlineCreditCard } from "react-icons/hi2";
import { setDataToStorage } from "../../utils/constant/config";
import { usePlatformValue } from "../../atom/platform";
import { openWebview, events, EventName } from "zmp-sdk/apis";
import { getParamsUrl } from "../../utils/convertURL";

const ShopPageCheckout = () => {
    const history = useHistory();

    const platform = usePlatformValue();
    const auth = useAuthStateValue();
    const warehouse = useWarehouseValue();
    const { order, getTotalPrice } = useOrder();

    const [currentAddress, setCurrentAddress] = useState();
    const [addresses, setAddresses] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("Khi nhận hàng");
    const [phone, setPhone] = useState("null");

    const { runAsync: checkoutOrders, loading: checkoutLoading } = useRequest(
        (data) => checkoutOrdersApi(data, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "checkout-order",
            manual: true,
        }
    );

    const { runAsync: checkoutOrdersVNPay, loading: checkoutVNPayLoading } = useRequest(
        (data) => checkoutOrdersVNPayApi(data, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "checkout-order-VNpay",
            manual: true,
        }
    );

    const { runAsync: getAddresses, loading: getAddressesLoading } = useRequest(
        () => getAddressApi({ token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "address-list",
            manual: true,
        }
    );

    const { runAsync: saveOrder, loading: saveOrderLoading } = useRequest(
        (params) => saveOrderApi(params, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "save-order",
            manual: true,
        }
    );

    useEffect(() => {
        const checkPhone = async () => {
            const phone = await localStorage.getItem(StorageKey.Phone);
            let checkPhone = phone == "null" ? null : phone;
            setPhone(checkPhone);
        };
        checkPhone();

        getAddresses().then((res) => {
            setAddresses(res.data);
            setCurrentAddress(res.data[0]);
        });
    }, []);

    /* Thanh toán bằng tiền mặt */
    const checkout = () => {
        if(!currentAddress){
            return toast.error('Bạn chưa chọn địa chỉ giao hàng', { theme: "colored", className: "mx-2 rounded mt-2" });
        }
        let products = order.map((item) => ({ xid: item.product.xid, cart_quantity: item.quantity }));
        let data = {
            products: products,
            address_id: currentAddress.xid,
            warehouse: warehouse[0].slug,
            company_id: `${ID_COMPANY}`,
        };
        checkoutOrders(data)
            .then((res) => {
                let orderSuccessdata = {
                    products: order,
                    unique_id: res.data.unique_id,
                    subTotal: getTotalPrice(),
                    shippingTotal: 0,
                    total: getTotalPrice(),
                    pay: paymentMethod,
                    shippingAddress: currentAddress,
                    createdAt: validateDate(),
                };
                history.push({ pathname: "/shop/checkout/success", state: { data: orderSuccessdata } });
            })
            .catch((err) => {
                console.log(err);
                switch (err.error.message) {
                    case "Not a valid store.":
                        toast.error(`Không tìm thấy cửa hàng`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    case "Address not valid.":
                        toast.error(`Không tìm thấy địa chỉ giao hàng`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    case "An unknown error occurred":
                        toast.error(`Lỗi hệ thống, vui lòng thử lại sau !`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    default:
                        toast.error(err.error.message, { theme: "colored", className: "mx-2 rounded mt-2" });
                        break;
                }
            });
    };

    /* Thành toán bằng vnpay trên web */
    const checkoutVNPay = () => {
        let products = order.map((item) => ({ xid: item.product.xid, cart_quantity: item.quantity }));
        let data = {
            products: products,
            address_id: currentAddress.xid,
            warehouse: warehouse[0].slug,
            company_id: `${ID_COMPANY}`,
            // return_url: `${BASE_URL_PRODUCTION}/shop/checkout`,
            return_url: `${BASE_URL_DEVELOPMENT}/shop/checkout/success`,
        };
        checkoutOrdersVNPay(data)
            .then((res) => {
                let orderSuccessdata = {
                    products: order,
                    unique_id: res.unique_id,
                    subTotal: getTotalPrice(),
                    shippingTotal: 0,
                    total: getTotalPrice(),
                    pay: paymentMethod,
                    shippingAddress: currentAddress,
                    createdAt: validateDate(),
                };
                setDataToStorage(StorageKey.OrderSuccess, orderSuccessdata);
                window.location.href = res.data;
            })
            .catch((err) => {
                console.log(err);
                switch (err.error.message) {
                    case "Not a valid store.":
                        toast.error(`Không tìm thấy cửa hàng`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    case "Address not valid.":
                        toast.error(`Không tìm thấy địa chỉ giao hàng`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    case "An unknown error occurred":
                        toast.error(`Lỗi hệ thống, vui lòng thử lại sau !`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    default:
                        toast.error(err.error.message, { theme: "colored", className: "mx-2 rounded mt-2" });
                        break;
                }
            });
    };

    /* Thành toán bằng vnpay trên zalo */
    const checkoutVNPayZalo = () => {
        let products = order.map((item) => ({ xid: item.product.xid, cart_quantity: item.quantity }));
        let data = {
            products: products,
            address_id: currentAddress.xid,
            warehouse: warehouse[0].slug,
            company_id: `${ID_COMPANY}`,
            return_url: `https://zalo.me/app/link/zapps/${APP_ID}/`,
        };
        checkoutOrdersVNPay(data)
            .then((res) => {
                /* Mở web view tới trang thanh toán VN Pay */
                openWebview({ url: res.data });

                /* Bắt data callback của api vnpay thông qua sự kiện tắt webview  */
                events.on(EventName.OpenApp, (data) => {
                    const status = new URLSearchParams(data?.path).get("vnp_ResponseCode");
                    const payDate = new URLSearchParams(data?.path).get("vnp_PayDate");
                    const params = getParamsUrl(data?.path)
                    let orderSuccessdata = {
                        products: order,
                        unique_id: res.unique_id,
                        subTotal: getTotalPrice(),
                        shippingTotal: 0,
                        total: getTotalPrice(),
                        pay: paymentMethod,
                        shippingAddress: currentAddress,
                        createdAt: formatDateVNPay(payDate),
                    };
                    saveOrder(params)
                        .then((response) => {
                            console.log("Đã lưu thanh toán: ", response);
                            if (status == "00" || status == "07") {
                                history.push({ pathname: "/shop/checkout/success", state: { data: orderSuccessdata } });
                            }
                        })
                        .catch((err) => {
                            console.log("Lưu thanh toán thất bại:", err);
                            if (status == "00" || status == "07") {
                                history.push({ pathname: "/shop/checkout/success", state: { data: orderSuccessdata } });
                            }
                        });
                });
            })
            .catch((err) => {
                console.log(err);
                switch (err.error.message) {
                    case "Not a valid store.":
                        toast.error(`Không tìm thấy cửa hàng`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    case "Address not valid.":
                        toast.error(`Không tìm thấy địa chỉ giao hàng`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    case "An unknown error occurred":
                        toast.error(`Lỗi hệ thống, vui lòng thử lại sau !`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        break;
                    default:
                        toast.error(err.error.message, { theme: "colored", className: "mx-2 rounded mt-2" });
                        break;
                }
            });
    };

    const renderCart = () => {
        const items = order.map((item, index) => {
            return (
                <tr style={{ fontSize: 14 }} key={index}>
                    <td className="order-list__column-image">
                        <div className="product-image">
                            <div className="product-image__body">
                                <img
                                    className="product-image__img"
                                    src={item.product.img_sapo ? item.product.img_sapo : item?.product.image_url}
                                    alt=""
                                />
                            </div>
                        </div>
                    </td>
                    <td className="order-list__column-product">
                        <div>{item.product.name}</div>
                    </td>
                    <td className="order-list__column-quantity" data-title="Số lượng:">
                        {item.quantity}
                    </td>
                    <td className="order-list__column-total">
                        <Currency value={item.product.sales_price * item.quantity} />
                    </td>
                </tr>
            );
        });

        return (
            <div className="card">
                <h3 className="card-body card-title mb-0" style={{ fontSize: 20 }}>
                    Đơn hàng của tôi
                </h3>
                <div className="order-list border-top">
                    <table className="pl-2" style={{ fontSize: 14 }}>
                        <thead className="order-list__header">
                            <tr>
                                <th className="order-list__column-label" colSpan="2" style={{ fontSize: 14 }}>
                                    Sản phẩm
                                </th>
                                <th className="order-list__column-quantity" style={{ fontSize: 14 }}>
                                    Số lượng
                                </th>
                                <th className="order-list__column-total" style={{ fontSize: 14 }}>
                                    Tổng tiền
                                </th>
                            </tr>
                        </thead>
                        <tbody className="order-list__products">{items}</tbody>
                        <tbody className="order-list__subtotals">
                            <tr>
                                <th className="order-list__column-label" colSpan="3">
                                    Tổng tiền hàng
                                </th>
                                <td className="order-list__column-total" style={{ fontWeight: "700" }}>
                                    <Currency value={getTotalPrice()} />
                                </td>
                            </tr>
                            <tr>
                                <th className="order-list__column-label" colSpan="3">
                                    Phí giao hàng
                                </th>
                                <td className="order-list__column-total" style={{ fontWeight: "700" }}>
                                    <Currency value={0} />
                                </td>
                            </tr>
                        </tbody>
                        <tfoot className="order-list__footer" style={{ fontSize: 16, fontWeight: "700" }}>
                            <tr>
                                <th className="order-list__column-label" colSpan="3" style={{ fontWeight: "700" }}>
                                    Tổng thanh toán
                                </th>
                                <td className="order-list__column-total">
                                    <Currency value={getTotalPrice()} />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    };

    const AddressCard = ({ item }) => {
        return (
            <>
                <div className="row justify-content-between" style={{ marginLeft: 0 }}>
                    <div className="address-card__name">
                        Người nhận: <span style={{ fontWeight: "300", marginLeft: 4 }}>{`${item?.name}`}</span>
                    </div>
                </div>
                <div className="address-card__row">
                    <div className="address-card__row-title">Địa chỉ giao hàng</div>
                    <div className="address-card__row-content">
                        {item?.shipping_address}, {item?.state}, {item?.city}, {item?.country}
                    </div>
                </div>
                <div className="address-card__row">
                    <div className="address-card__row-title">Số điện thoại</div>
                    <div className="address-card__row-content">{item?.phone}</div>
                </div>
                <div className="address-card__row" style={{ marginBottom: 10 }}>
                    <div className="address-card__row-title">Email liên hệ</div>
                    <div className="address-card__row-content">{item?.email}</div>
                </div>
                <div onClick={() => setIsOpen(true)}>
                    <span style={{ color: "blue", fontSize: 14 }}>Dùng địa chỉ khác ?</span>
                </div>
            </>
        );
    };

    const ModalAddressList = ({ data }) => {
        const customStyles = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black overlay
                zIndex: 99999,
            },
            content: {
                top: "50%",
                left: "50%",
                width: "90%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 10,
                maxHeight: "70%",
            },
        };
        return (
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel="Addresses list modal"
                ariaHideApp={false}
            >
                {data.length > 0 &&
                    data.reverse().map((item, index) => (
                        <div
                            key={index}
                            className={`border ${
                                currentAddress && item.xid == currentAddress.xid && "border-warning"
                            } p-3 mb-3`}
                            onClick={() => {
                                setCurrentAddress(item);
                                setTimeout(() => setIsOpen(false), [200]);
                            }}
                        >
                            <AddressCard item={item} />
                        </div>
                    ))}
                <Link
                    to={{ pathname: "/account/addresses/add", state: { data: null } }}
                    className="btn btn-secondary btn-sm w-100"
                >
                    <span style={{ color: "black" }}>Thêm mới</span>
                </Link>
            </Modal>
        );
    };

    const breadcrumb = [
        { title: "Trang chủ", url: "" },
        { title: "Giỏ hàng", url: "/shop/cart" },
        { title: "Đặt hàng", url: "" },
    ];

    if (checkoutLoading || getAddressesLoading || saveOrderLoading) {
        return <BlockLoader />;
    }

    if (!auth.token) {
        return <BlockLoader />;
    }

    return (
        <>
            <Helmet>
                <title>{`Đặt hàng`}</title>
            </Helmet>

            <PageHeader header="Đặt hàng" breadcrumb={breadcrumb} />

            <div className="checkout block">
                <div className="container">
                    <div className="row">
                        {auth.token ? (
                            <div className="col-12 mb-3">
                                <div className="h-15"></div>
                            </div>
                        ) : (
                            <div className="col-12 mb-3">
                                <div className="alert alert-primary alert-lg">
                                    Bạn là khách hàng ? <Link to="/account/login">Đăng nhập tại đây</Link>
                                </div>
                            </div>
                        )}

                        <div className="col-12 col-lg-5 col-xl-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h3 className="card-title" style={{ fontSize: 20 }}>
                                        Chi tiết giao hàng
                                    </h3>

                                    {addresses?.length < 1 ? (
                                        <Link
                                            to={
                                                phone
                                                    ? { pathname: "/account/addresses/add", state: { data: null } }
                                                    : { pathname: "/account" }
                                            }
                                            className="addresses-list__item--new"
                                        >
                                            <div className="addresses-list__plus" />
                                            <div className="btn btn-secondary btn-sm">Thêm địa chỉ mới</div>
                                        </Link>
                                    ) : (
                                        <AddressCard item={currentAddress} />
                                    )}
                                </div>
                            </div>
                            <div className="card mb-lg-0">
                                <div className="card-body">
                                    <h3 className="card-title" style={{ fontSize: 20 }}>
                                        Phương thức thanh toán
                                    </h3>

                                    {warehouse[0].check !== "hidden" && (
                                        <div
                                            onClick={() => setPaymentMethod("VN Pay")}
                                            className={`d-flex align-items-center p-3 border border-3 rounded mb-2 ${
                                                paymentMethod == "VN Pay" && "border-warning"
                                            }`}
                                            style={{ color: paymentMethod == "VN Pay" ? "#ffc107" : "" }}
                                        >
                                            <HiOutlineCreditCard size={24} />
                                            <span className="ml-1" style={{ cursor: "pointer" }}>
                                                Thanh toán trực tuyến VNPay
                                            </span>
                                        </div>
                                    )}
                                    <div
                                        onClick={() => setPaymentMethod("Khi nhận hàng")}
                                        className={`d-flex align-items-center p-3 border border-3 rounded mb-2 ${
                                            paymentMethod == "Khi nhận hàng" && "border-warning"
                                        }`}
                                        style={{ color: paymentMethod == "Khi nhận hàng" ? "#ffc107" : "" }}
                                    >
                                        <HiOutlineBanknotes size={24} />
                                        <span className="ml-1" style={{ cursor: "pointer" }}>
                                            Thanh toán khi nhận hàng
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-7 col-xl-8 mt-4 mt-lg-0">
                            <div className="mb-0">
                                {renderCart()}
                                <button
                                    className="btn btn-primary btn-md btn-block rounded mt-3"
                                    onClick={
                                        paymentMethod == "Khi nhận hàng"
                                            ? checkout
                                            : platform == "web"
                                            ? checkoutVNPay
                                            : checkoutVNPayZalo
                                    }
                                >
                                    Đặt hàng
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddressList data={addresses} />
        </>
    );
};

export default ShopPageCheckout;
