// react
import React from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";

// pages
import AccountPageAddresses from "./AccountPageAddresses";
import AccountPageDashboard from "./AccountPageDashboard";
import AccountPageEditAddress from "./AccountPageEditAddress";
import AccountPageOrderDetails from "./AccountPageOrderDetails";
import AccountPageOrders from "./AccountPageOrders";
import AccountPagePassword from "./AccountPagePassword";
import AccountPageProfile from "./AccountPageProfile";
import { usePlatformValue } from "../../atom/platform";
import AccountDashboardMobile from "./AccountDashboardMobile";
import { setDataToStorage } from "../../utils/constant/config";
import { StorageKey } from "../../services/storage";
import { useAuthState, useSetAuthState } from "../../atom/auth";
import { useUserState } from "../../atom/user";

export default function AccountLayout(props) {
    const { match, location } = props;
    const platform = usePlatformValue();
    const [user, setUser] = useUserState();
    const [auth, setAuth] = useAuthState();
    const breadcrumb = [
        { title: "Trang chủ", url: "" },
        { title: "Tài khoản của tôi", url: "" },
    ];

    const logout = () => {
        const token = null;
        const userInfor = {
            id: "",
            name: "Họ và tên",
            phone: "123",
            company_id: "1",
            img_url: "",
            email: "example@example.com",
            address: "",
        };
        setDataToStorage(StorageKey.Authen, token);
        setAuth({ loading: false, reinitialized: false, token: token });
        setUser(userInfor);
    };

    const links = [
        { title: "Tài khoản của tôi", url: "dashboard" },
        { title: "Chỉnh sửa thông tin", url: "profile" },
        { title: "Đơn hàng của tôi", url: "orders" },
        // { title: "Order Details", url: "orders/5" },
        { title: "Danh sách địa chỉ", url: "addresses" },
        // { title: "Edit Address", url: "addresses/5" },
        { title: "Thay đổi mật khẩu", url: "password" },
        { title: "Đăng xuất", url: "login" },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames("account-nav__item", {
            "account-nav__item--active": isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url} onClick={link.url === "login" && logout}>
                    {link.title}
                </Link>
            </li>
        );  
    });

    return (
        <React.Fragment>
            <PageHeader header="Tài khoản" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        {platform == "web" && (
                            <div className="col-12 col-lg-3 d-flex">
                                <div className="account-nav flex-grow-1">
                                    <h4 className="account-nav__title">Danh mục</h4>
                                    <ul>{links}</ul>
                                </div>
                            </div>
                        )}

                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route
                                    exact
                                    path={`${match.path}/dashboard`}
                                    component={platform == "web" ? AccountPageDashboard : AccountDashboardMobile}
                                />
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route
                                    exact
                                    path={`${match.path}/orders/:orderId`}
                                    component={AccountPageOrderDetails}
                                />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />

                                <Route exact path={`${match.path}/addresses/add/`} component={AccountPageEditAddress} />

                                <Route
                                    exact
                                    path={`${match.path}/addresses/edit/:addressIndex`}
                                    //component={AccountPageEditAddress}
                                    render={(props) => (
                                        <AccountPageEditAddress
                                            {...props}
                                            indexAddress={props.match.params.addressIndex}
                                        />
                                    )}
                                />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
