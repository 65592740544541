// react
import React from "react";
import { Link } from "react-router-dom";
import { useCategoriesValue } from "../../atom/categories";
import BlockHeader from "../shared/BlockHeader";
import "./style.css";
import { formatName } from "../../utils/constant/formatName";

const listImageCategory = [
    'https://i.imgur.com/MXGVMNa.jpg',
    'https://i.imgur.com/IWAy0yV.png',
    'https://i.imgur.com/e1vupap.jpg',
    'https://i.imgur.com/IhV2a5F.png',
    'https://i.imgur.com/dcerSBM.jpg',
    'https://i.imgur.com/VHyMKuP.jpg',
    'https://i.imgur.com/grUVB8s.jpg',
    'https://i.imgur.com/u7XaRdn.jpg',
    'https://i.imgur.com/zfVhV8H.png',
    'https://i.imgur.com/IhOypli.jpg',
]

export default function Category() {
    const categories = useCategoriesValue();

    return (
        <div className="container">
            <BlockHeader title={"Danh mục"} />
            
            <div className="d-flex justify-content-center">
                {categories?.slice(0, 4).map((category, index) => (
                    <div className="col-md-3 pr-2 pl-2 justify-content-center">
                        <Link key={index} to={{ pathname: `/category/${category.xid}`, state: { category: category } }}>
                            <div
                                className=""
                                style={{ alignItems: "center", width: '100%', height: "100%" }}
                            >
                                <img
                                    style={{ width: 60, height: 60 }}
                                    className="object-fit-cover"
                                    src={category.image}
                                />
                                <p className="text-center mt-2" style={{ color: "black", marginLeft: -5 }}>
                                    {category.name.length > 12
                                        ? category.name.substring(0, 12).concat("..")
                                        : category.name.toUpperCase()
                                    }
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center">
                {categories?.slice(4, 7).map((category, index) => (
                    <div className="col-md-3 pr-2 pl-2 justify-content-center">
                        <Link key={index} to={{ pathname: `/category/${category.xid}`, state: { category: category } }}>
                            <div
                                className=""
                                style={{ alignItems: "center", width: '100%', height: "100%" }}
                            >
                                <img
                                    style={{ width: 60, height: 60 }}
                                    className="object-fit-cover"
                                    src={category.image}
                                />
                               <p className="text-center mt-2" style={{ color: "black", marginLeft: -5 }}>
                                    {category.name.length > 12
                                        ? category.name.substring(0, 12).concat("..")
                                        : category.name.toUpperCase()
                                    }
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
                <div className="d-flex col-md-3 pr-2 pl-2 justify-content-center">
                    <Link to={{ pathname: `/category/all-products`, state: { category: null } }}>
                        <div
                            className=""
                            style={{ alignItems: "center", width: '100%', height: "100%" }}
                        >
                            <img
                                style={{ width: 60, height: 60 }}
                                className="object-fit-cover"
                                src={'https://i.imgur.com/1SKf08I.png'}
                            />
                            <p className="text-center mt-2" style={{ color: "black", marginLeft: -5 }}>
                                TẤT CẢ
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
