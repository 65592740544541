import { ApiUtils } from '.';
import { PATH } from './path';

export const loginZaloApi = async (data) => {
    return await ApiUtils.post(PATH.update_user, data);
}

export const loginWebApi = async (data) => {
    return await ApiUtils.post(PATH.login_web, data);
}

export const signUpApi = async (data) => {
    return await ApiUtils.post(PATH.sign_up_web, data);
}

export const updateUserApi = async (data, customHeaders) => {
    return await ApiUtils.post(PATH.update_user_zalo, data, customHeaders);
}

export const getInfoUserApi = async (customHeaders) => {
    return await ApiUtils.request(PATH.get_profile, customHeaders);
}