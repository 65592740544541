
// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
import departments from '../../data/headerDepartments';
import { useCategoriesValue } from '../../atom/categories';
import { useProductByCategoryState } from '../../atom/product_by_category';
import { productsByCategory } from '../../services/api/product_api';
import { useRequest } from 'ahooks';
import { formatName } from '../../utils/constant/formatName';
import MenuCategories from './MenuCategories';
import BlockLoader from '../blocks/BlockLoader';

function DepartmentsLinks() {
    const categories = useCategoriesValue();
    const [, setProductByCategory] = useProductByCategoryState();
    const { data, loading, runAsync } = useRequest((slug) => productsByCategory(slug, 1), {
        debounceWait: 250,
        cacheKey: "products-category-cache",
        manual: true,
    });
    const handleSlug = async(idSlug) => {
        await runAsync(idSlug)
            .then((res) => {
                setProductByCategory(res.data.allProducts);
            })
            .catch((err) => console.log(err));
    }
    const linksList = categories.slice(0, 14).map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';

        if (department.children) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        if (department.children) { 
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <MenuCategories items={department.children} runAsync={runAsync} />
                </div>
            );
        }

        /* if (department.submenu && department.submenu.type === 'megamenu') {
            console.log(department.submenu.menu);
            submenu = (
                <div className={`departments__megamenu departments__megamenu--${department.submenu.menu.size}`}>
                    <Megamenu menu={department.submenu.menu} location="department" />
                </div>
            );
        } */

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={`/category/${department.xid}`}>
                <div onClick={() => handleSlug(`&${department.xid}`)}>
                    {formatName(department.name)}
                    {arrow}
                </div>
                </Link>
                {submenu}
        </li>
        );
    });

    if(loading) return <BlockLoader />

    return (
        <ul className="departments__links">
            {linksList}
            <li style={{color: '#ffd333'}}>
                <Link to='/category/all-products'>
                    Xem thêm...
                </Link>
            </li>
        </ul>
    );
}

export default DepartmentsLinks;
