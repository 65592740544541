// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import Modal from "react-modal";
import BlockLoader from "../blocks/BlockLoader";
import { usePlatformValue } from "../../atom/platform";
import { HiOutlineMapPin, HiOutlinePhone } from "react-icons/hi2";
import { HiOutlineMail } from "react-icons/hi";
import { useRequest } from "ahooks";
import { deleteAddressApi, getAddressApi } from "../../services/api/address_api";
import { useAuthStateValue } from "../../atom/auth";
import { useMemo } from "react";
import { toast } from "react-toastify";

const AccountPageAddresses = () => {
    const platform = usePlatformValue();
    const auth = useAuthStateValue();

    const [modalIsOpen, setIsOpen] = useState({ open: false, index: null });

    const {
        run: getAddresses,
        loading: getAddressesLoading,
        data: addresses,
    } = useRequest(() => getAddressApi({ token: auth.token }), {
        debounceInterval: 250,
        cacheKey: "address-list",
    });

    const { runAsync: deleteAddress, loading: deleteAddressLoading } = useRequest(
        (addressId) => deleteAddressApi(addressId, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "delete-address",
            manual: true,
        }
    );

    const AddressCard = ({ item }) => {
        if (platform !== "web") {
            return (
                <React.Fragment>
                    <div className={"addresses-list__item card address-card rounded"}>
                        {/* {address.default && <div className="address-card__badge">Default</div>} */}

                        <div className="address-card__body">
                            <div className="row justify-content-between" style={{ marginLeft: 0 }}>
                                <div className="address-card__name">Họ và tên: {item?.name}</div>
                                <div onClick={() => openModal(item, item.xid)} className="text-danger mr-2">
                                    Xóa
                                </div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineMapPin size={18} />
                                    <div className="ml-1 mt-1">Địa chỉ</div>
                                </div>
                                <div className="address-card__row-content mt-2">
                                    {item?.shipping_address}, {item?.state}, {item?.city}, {item?.country}
                                </div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlinePhone size={18} />
                                    <div className="ml-1 mt-1">Số điện thoại</div>
                                </div>
                                <div className="address-card__row-content mt-2">{item?.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineMail size={18} />
                                    <div className="ml-1 mt-1">Địa chỉ email</div>
                                </div>
                                <div className="address-card__row-content mt-2">{item?.email}</div>
                            </div>
                            <div className="address-card__footer row justify-content-between mx-1">
                                <Link to={{ pathname: `/account/addresses/edit/${item?.xid}`, state: { data: item } }}>
                                    Chỉnh sửa
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="addresses-list__divider" />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={"addresses-list__item card address-card"}>
                        {/* {address.default && <div className="address-card__badge">Default</div>} */}

                        <div className="address-card__body h-100">
                            <div className="address-card__name">Họ và tên: {item?.name}</div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineMapPin size={18} />
                                    <div className="ml-1 mt-1">Địa chỉ</div>
                                </div>
                                <div className="address-card__row-content mt-2">
                                    {item?.shipping_address}, {item?.state}, {item?.city}, {item?.country}
                                </div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlinePhone size={18} />
                                    <div className="ml-1 mt-1">Số điện thoại</div>
                                </div>
                                <div className="address-card__row-content mt-2">{item?.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div
                                    className="address-card__row-title d-flex align-items-center"
                                    style={{ marginLeft: 0, fontSize: 13 }}
                                >
                                    <HiOutlineMail size={18} />
                                    <div className="ml-1 mt-1">Địa chỉ email</div>
                                </div>
                                <div className="address-card__row-content mt-2">{item?.email}</div>
                            </div>
                            <div className="address-card__footer row justify-content-between mx-1">
                                <Link to={{ pathname: `/account/addresses/edit/${item?.xid}`, state: { data: item } }}>
                                    Chỉnh sửa
                                </Link>
                                <div onClick={() => openModal(item.xid)} className="text-danger mr-2" style={{cursor: 'pointer'}}>
                                    Xóa
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="addresses-list__divider" />
                </React.Fragment>
            );
        }
    };

    const handleDeleteAddress = (addressId) => {
        deleteAddress(addressId)
            .then((res) => {
                console.log(res);
                getAddresses();
                toast.success(`Xóa địa chỉ thành công`, {
                    theme: "colored",
                    className: "mx-2 rounded mt-2",
                });
            })
            .catch((error) => {
                console.error(error);
                toast.error(`Xóa địa chỉ thất bại`, {
                    theme: "colored",
                    className: "mx-2 rounded mt-2",
                });
            });
    };

    const openModal = (index) => {
        setIsOpen({ open: true, index: index });
    };

    const closeModal = () => setIsOpen((data) => ({ ...data, open: false }));

    const CancelModal = () => {
        
        const customStyles = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                zIndex: 99999,
            },
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 10,
            },
        };

        return (
            <Modal
                isOpen={modalIsOpen.open}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p
                    className="text-warning justify-content-center d-flex"
                    style={{ fontWeight: "500", fontSize: 19, textAlign: "center", fontSize: 18 }}
                >
                    Bạn muốn xóa địa chỉ này?
                </p>
                <div style={{ justifyContent: "center" }} className="d-flex px-5 mt-4"></div>
                <div className="justify-content-between row mx-1 mt-2">
                    <div
                        onClick={closeModal}
                        style={{
                            backgroundColor: "#E0E0E0",
                            fontWeight: "500",
                            padding: 8,
                            borderRadius: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                            fontSize: 15,
                        }}
                    >
                        Không
                    </div>
                    <div
                        onClick={() => {
                            handleDeleteAddress(modalIsOpen.index);
                            closeModal();
                        }}
                        style={{
                            backgroundColor: "#FF0000",
                            fontWeight: "500",
                            padding: 8,
                            borderRadius: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                            color: "white",
                            fontSize: 15,
                        }}
                    >
                        Xóa ngay
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <div className="addresses-list">
            <Helmet>
                <title>Thông tin địa chỉ</title>
            </Helmet>

            {addresses?.data?.length < 6 && (
                <>
                    <div className="addresses-list__item card address-card p-3">
                        <Link
                            to={{ pathname: `/account/addresses/add`, state: { data: null } }}
                            className="addresses-list__item--new h-100 "
                        >
                            <div className="addresses-list__plus" />
                            <div className="btn btn-secondary btn-sm">
                                Thêm mới
                            </div>
                        </Link>
                    </div>
                    <div className="addresses-list__divider" />
                </>
            )}

            {getAddressesLoading && <BlockLoader />}

            {useMemo(() => addresses?.data?.map((item, index) => <AddressCard key={index} item={item} />), [addresses])}

            <CancelModal />
        </div>
    );
};

export default AccountPageAddresses;