// react
import React from "react";

// third-party
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import Rating from "../shared/Rating";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { HiShoppingCart } from "react-icons/hi2";
// data stubs
import { usePlatformValue } from "../../atom/platform";
import { useWishList } from "../../atom/wishlist";
import { toast } from "react-toastify";
import { useCart } from "../../atom/cart";

function ShopPageWishlist(props) {
    const { wishList, setWishList, addToWishlist, removeFromWishlist, isWishProduct } = useWishList();
    const { cart, setCart, addToCart, removeFromCart, isItemInCart, updateCartItem } = useCart();

    const platform = usePlatformValue();

    const handleRemoveFromWishlist = (product) => {
        removeFromWishlist(product.product_id);
        toast.success(`Đã xóa "${product.name}" khỏi danh sách yêu thích!`, {
            theme: "colored",
            className: "mx-2 rounded mt-2",
        });
    };

    const handleAddToCart = (product, quantity) => {
        addToCart(product, quantity);
        toast.success(`Đã thêm "${product.name}" vào giỏ hàng!`, { theme: "colored", className: "mx-2 rounded mt-2" });
    };

    const breadcrumb = [
        { title: "Trang chủ", url: "" },
        { title: "Danh sách yêu thích", url: "" },
    ];

    let content;

    if (wishList?.length > 0) {
        const itemsList = wishList.map((item) => {
            let image;

            if (item.img_sapo ? item.img_sapo : item.image_url) {
                image = (
                    <div className="product-image">
                        <Link
                            to={{ pathname: url.product(item), state: { product: item } }}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.img_sapo ? item.img_sapo : item.image_url}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }

            if (platform !== "web") {
                return (
                    <div
                        key={item.id}
                        className="row mt-3 mx-1 p-2 border rounded shadow-sm"
                        style={{ position: "relative", fontSize: 14 }}
                    >
                        <Link to={{ pathname: url.product(item), state: { product: item } }}>
                            <div className="product-image" style={{ width: 120, height: 120 }}>
                                <div className="product-image__body">
                                    <img
                                        className="product-image__img"
                                        src={item.img_sapo ? item.img_sapo : item.image_url}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </Link>
                        <div className="col" style={{ justifyContent: "space-between" }}>
                            <div className="mr-2">
                                <span style={{ color: "black", fontWeight: "500" }}>
                                    {item.name.length < 40 ? item.name : item.name.slice(0, 40) + "..."}
                                </span>
                                <div style={{ marginLeft: 0 }}>
                                    <Rating value={item.rating || 5} />
                                </div>
                                {/* <div className="wishlist__product-rating-legend ml-0 mt-1" style={{ fontSize: 12 }}>
                                    {Math.floor(Math.random() * 31) + 20}+ Đánh giá
                                </div> */}
                            </div>
                            <div style={{ position: "absolute", bottom: 0, right: 0, width: "100%" }}>
                                <div className="row align-items-baseline justify-content-between ml-3 mr-1">
                                    <div style={{ fontWeight: "500" }}>
                                        <Currency value={item.sales_price} />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleAddToCart(item, 1)}
                                        className="btn btn-primary btn-sm"
                                    >
                                        <HiShoppingCart size={18} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "absolute", top: 0, right: 0 }}>
                            <button
                                type="button"
                                onClick={() => handleRemoveFromWishlist(item)}
                                className={classNames("btn btn-secondary btn-sm btn-svg-icon")}
                                aria-label="Remove"
                            >
                                <Cross12Svg />
                            </button>
                        </div>
                    </div>
                );
            }

            return (
                <tr key={item.product_id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">
                        <div className="product-image">
                            <Link
                                to={{ pathname: url.product(item), state: { product: item } }}
                                className="product-image__body"
                            >
                                <img
                                    className="product-image__img"
                                    src={item.img_sapo ? item.img_sapo : item.image_url}
                                    alt=""
                                />
                            </Link>
                        </div>
                    </td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link
                            to={{ pathname: url.product(item), state: { product: item } }}
                            className="wishlist__product-name"
                        >
                            {item.name}
                        </Link>
                        <div className="wishlist__product-rating">
                            <Rating value={item.rating || 5} />
                            {/* <div className="wishlist__product-rating-legend">
                                {Math.floor(Math.random() * 31) + 20}+ Đánh giá
                            </div> */}
                        </div>
                    </td>
                    <td className="wishlist__column wishlist__column--stock">
                        <div className="badge badge-success">{item.status == "in_stock" ? "Còn hàng" : "Hết hàng"}</div>
                    </td>
                    <td className="wishlist__column wishlist__column--price">
                        <Currency value={item.sales_price} />
                    </td>
                    <td className="wishlist__column wishlist__column--tocart">
                        <button
                            type="button"
                            onClick={() => handleAddToCart(item, 1)}
                            className="btn btn-primary btn-sm"
                        >
                            Thêm vào giỏ hàng
                        </button>
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <button
                            type="button"
                            onClick={() => handleRemoveFromWishlist(item)}
                            className={classNames("btn btn-light btn-sm btn-svg-icon")}
                            aria-label="Remove"
                        >
                            <Cross12Svg />
                        </button>
                    </td>
                </tr>
            );
        });
        if (platform !== "web") {
            content = (
                <div className="block">
                    <div className="container">{itemsList}</div>
                </div>
            );
        } else {
            content = (
                <div className="block">
                    <div className="container">
                        <table className="wishlist">
                            <thead className="wishlist__head">
                                <tr className="wishlist__row">
                                    <th className="wishlist__column wishlist__column--image">Hình ảnh</th>
                                    <th className="wishlist__column wishlist__column--product">Tên sản phẩm</th>
                                    <th className="wishlist__column wishlist__column--stock">Trạng thái</th>
                                    <th className="wishlist__column wishlist__column--price">Giá</th>
                                    <th
                                        className="wishlist__column wishlist__column--tocart"
                                        aria-label="Add to cart"
                                    />
                                    <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                                </tr>
                            </thead>
                            <tbody className="wishlist__body">{itemsList}</tbody>
                        </table>
                    </div>
                </div>
            );
        }
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div>
                            <img src="https://cdn-icons-png.flaticon.com/512/5735/5735325.png" style={{ height: 48 }} />
                        </div>
                        <div className="block-empty__message mt-3">Danh sách yêu thích của bạn trống,</div>
                        <div className="block-empty__message">Thêm sản phẩm yêu thích ngay!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                Đến trang chủ
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Danh sách yêu thích`}</title>
            </Helmet>

            <PageHeader header="Danh sách yêu thích" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

export default ShopPageWishlist;
