// recoil
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { StorageKey } from "../services/storage/index";
import { useMount, useRequest } from "ahooks";
import { useUserState } from "./user";
import { getPlatform, getUser, getDataToStorage } from "../utils/constant/config";
import { usePlatformState } from "./platform";
import { useWishListState } from "./wishlist";
import { useCartState } from "./cart";
import { getInfoUserApi, loginZaloApi } from "../services/api/user_api";
import { ID_COMPANY } from "../utils/constant";
import { getAccessToken, isTokenValid } from "../services/tokenManager";
import { useWarehouse } from "./warehouse";
import { useCategoriesState } from "./categories";
import { getCategoryApi } from "../services/api/category_api";

const AUTH_STATE = atom({
    key: "AUTH_STATE", // unique ID (with respect to other atoms/selectors)
    default: {
        token: undefined,
        loading: false,
        reinitialized: true,
        modalVisible: /Mobi|Android/i.test(navigator.userAgent), // check acccess mobile or web?
    }, // default value (aka initial value)
});

export const useAuthState = () => {
    return useRecoilState(AUTH_STATE);
};

export const useAuthStateValue = () => {
    return useRecoilValue(AUTH_STATE);
};

export const useSetAuthState = () => {
    return useSetRecoilState(AUTH_STATE);
};

export const useAuth = async () => {
    const [, setAuthState] = useAuthState();
    const [userData, setUserState] = useUserState();
    const [, setPlatform] = usePlatformState();
    const [, setWishList] = useWishListState();
    const [, setCart] = useCartState();
    const { getWarehouse } = useWarehouse();
    const [, setCategory] = useCategoriesState();

    const { runAsync: saveUser, loading } = useRequest(() => loginZaloApi(userData), {
        debounceInterval: 250,
        cacheKey: "login-zalo",
        manual: true,
    });
    const { runAsync: getCategory } = useRequest(() => getCategoryApi(), {
        debounceWait: 250,
        cacheKey: "category-cache",
        manual: true,
        onSuccess: (res) => {
            if (res?.data) {
                /* let dataCategory = res?.data?.categories;
                setCategory(dataCategory); */
                const allCategoriesArray = [];
                var listArray = res?.data?.categories;
                listArray.forEach((node) => {
                    // No parentId means top level
                    if (!node.x_parent_id) return allCategoriesArray.push(node);

                    // Insert node as child of parent in listArray array
                    const parentIndex = listArray.findIndex(
                        (el) => el.xid === node.x_parent_id
                    );
                    if (!listArray[parentIndex].children) {
                        return (listArray[parentIndex].children = [node]);
                    }

                    listArray[parentIndex].children.push(node);
                });

                // allCategories.value = allCategoriesArray;
                setCategory(allCategoriesArray);
            }
        },
    });

    const { runAsync: getInfoUser } = useRequest((token) => getInfoUserApi(token), {
        debounceInterval: 250,
        cacheKey: "info-user",
        manual: true,
    });

    useMount(async () => {
        const platform = await getPlatform();
        setPlatform(platform);
        if (platform !== "web") {
            const userInfo = await getUser();
            setUserState((prevUserData) => ({
                ...prevUserData,
                id: userInfo.id,
                name: userInfo.name,
                img_url: userInfo.avatar,
                company_id: ID_COMPANY,
            }));

            saveUser()
                .then((res) => {
                    setAuthState((authData) => ({
                        ...authData,
                        token: res.data.token,
                    }));
                    console.log("login successfully", res);
                })
                .catch((err) => console.log("Login error", err));
        } else {
            const tokenAuth = await getAccessToken();
            const isValidToken = await isTokenValid();
            if(isValidToken){
                await getInfoUser({ token: tokenAuth }).then((res) => {
                    setUserState(res.data.user);
                }).catch(err => console.log(err));
            }
            setAuthState({ loading: false, reinitialized: false, token: isValidToken ? tokenAuth : null });
        }
        await getCategory();
        getDataToStorage(StorageKey.WishList).then((res) => setWishList(res ? res : []));
        getDataToStorage(StorageKey.Cart).then((res) => setCart(res ? res : []));
        await getDataToStorage(StorageKey.Phone).then((res) => {
            console.log(res);
            setUserState((prevUserData) => ({
                ...prevUserData,
                phone: res ? res : null
            }))
        }
        );
        await getWarehouse();
    });
};
