// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import theme from "../../data/theme";
import { useAuthStateValue } from "../../atom/auth";
import { useRequest } from "ahooks";
import { updateUserApi } from "../../services/api/user_api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserValue } from "../../atom/user";
import { schemaPassword } from "../../schema/schemaPassword";
import TextInput from "../Custom/TextInput";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function AccountPagePassword() {
    const history = useHistory();
    const user = useUserValue();
    const auth = useAuthStateValue();
    const [isErrorPassword, setIsErrorPassword] = useState(false);

    const { runAsync: changePassword, loading } = useRequest((data) => updateUserApi(data, { token: auth.token }), {
        debounceInterval: 250,
        cacheKey: "change-password",
        manual: true,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaPassword),
    });

    const onSubmit = (data) => {
        console.log(data);
        if (data.newPassword !== data.confirmPassword) {
            setIsErrorPassword(true);
        } else {
            setIsErrorPassword(false);
            changePassword({ name: user.name, oldPassword: data.oldPassword, newPassword: data.newPassword })
                .then((res) => {
                    console.log(res);
                    toast.success(`Đổi mật khẩu thành công`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                    history.push("/account/login")
                })
                .catch((err) => {
                    console.log(err);
                    if (err.error.message == 'Wrong password.') {
                        toast.error(`Mật khẩu hiện tại không chính xác`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                    }else{
                        toast.error(`Đổi mật khẩu thất bại`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                    }
                });
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Đổi mật khẩu — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Đổi mật khẩu</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextInput
                                label="Mật khẩu hiện tại"
                                rules={"oldPassword"}
                                type="password"
                                placeholder="Nhập mật khẩu hiện tại"
                                register={register}
                                errors={errors.oldPassword ? errors.oldPassword.message : ""}
                            />
                            <TextInput
                                label="Mật khẩu mới"
                                rules={"newPassword"}
                                type="password"
                                placeholder="Nhập mật khẩu mới"
                                register={register}
                                errors={errors.newPassword ? errors.newPassword.message : ""}
                            />
                            <TextInput
                                label="Xác nhận mật khẩu"
                                rules={"confirmPassword"}
                                type="password"
                                placeholder="Nhập lại mật khẩu mới"
                                register={register}
                                errors={errors.confirmPassword ? errors.confirmPassword.message : ""}
                            />
                            {isErrorPassword && <p style={{ color: "red", marginTop: 2 }}>Mật khẩu không trùng khớp</p>}
                            <button type="submit" className="btn btn-primary rounded mt-4 mb-10">
                                Lưu thông tin
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
