// recoil
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const USER_STATE = atom({
    key: 'USER_STATE', // unique ID (with respect to other atoms/selectors)
    default: {
        id: '',
        name: 'Họ và tên',
        phone: '123',
        company_id: '1',
        img_url: '',
        email: 'example@example.com',
        address: '',
    }, // default value (aka initial value)
});

export const useUserState = () => useRecoilState(USER_STATE);

export const useUserValue = () => useRecoilValue(USER_STATE);

export const useUserSetValue = () => useSetRecoilState(USER_STATE);