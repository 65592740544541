// react
import React, { useMemo, useEffect, useState, useRef } from "react";

// third-party
import { Helmet } from "react-helmet-async";

// application
import shopApi from "../../api/shop";
import { useDeferredData, useProductColumns, useProductTabs } from "../../services/hooks";

// blocks
import BlockBanner from "../blocks/BlockBanner";
import BlockBrands from "../blocks/BlockBrands";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockPosts from "../blocks/BlockPosts";
import BlockProductColumns from "../blocks/BlockProductColumns";
import BlockProducts from "../blocks/BlockProducts";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";

// data stubs
import categories from "../../data/shopBlockCategories";
import posts from "../../data/blogPosts";
import theme from "../../data/theme";
import { filterProductApi, getTopSellingApi } from "../../services/api/product_api";
import { useRequest, useMount } from "ahooks";
import { useCategoriesState } from "../../atom/categories";
import { usePlatformValue } from "../../atom/platform";
import Category from "../zalo_app/Category";
import ShopPageCategory from "../shop/ShopPageCategory";
import { useAuthStateValue } from "../../atom/auth";
import BlockCategories from "../blocks/BlockCategories";
import SocialLinks from "../shared/SocialLinks";
import { ModalMiniApp } from "../shared/ModalMiniApp";
import FilterProduct from "../zalo_app/FilterProduct";

function HomePageOne() {

    let isMobile = useRef(/Mobi|Android/i.test(navigator.userAgent));
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });

    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    const [category, setCategory] = useCategoriesState();
    const platform = usePlatformValue();
    const auth = useAuthStateValue();

    const {
        data: topSellers,
        loading: loadingTopSellers,
        run: getTopSelling,
    } = useRequest(() => getTopSellingApi(), {
        debounceWait: 250,
        cacheKey: "top-selling-cache",
    });

    const brandID = `brand_id%20eq%20nBWMexW5%20`
    const {
        data: productByBrand,
        loading: loadingProductBrand,
        run,
    } = useRequest(() => filterProductApi(brandID), {
        debounceWait: 250,
        cacheKey: "product-by-brand-cache",
    });


    const latestProducts = useProductTabs(
        useMemo(() => category.slice(0, 5), []),
        (tab) => shopApi.getLatestProducts({ limit: 9, category: category?.slug })
    );

    let columns = [
        {
            title: "Sản phẩm giảm giá",
            products: (productByBrand?.data?.allProducts?.data)?.slice(0, 4),
        },
        {
            title: "ㅤ",
            products: (productByBrand?.data?.allProducts?.data)?.slice(4, 8),
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${theme.name}`}</title>
            </Helmet>
            {useMemo(
                () => (platform == "web" && auth.modalVisible ? <ModalMiniApp /> : null),
                [platform]
            )}
            {useMemo(
                () => (platform == "web" ? windowSize.width < 600 ? <FilterProduct /> : null : <FilterProduct />),
                [platform, windowSize]
            )}
            {useMemo(
                () => (
                    <BlockSlideShow withDepartments platform={platform} />
                ),
                [platform]
            )}

            {useMemo(
                () => (platform == "web" ? <BlockFeatures /> : <Category />),
                [category]
            )}

            {useMemo(
                () => (
                    <BlockProducts
                        title="Bán chạy nhất"
                        layout="large-first"
                        featuredProduct={topSellers?.data?.topSellingProduct[0]}
                        products={topSellers?.data?.topSellingProduct}
                    />
                ),
                [topSellers]
            )}

            {useMemo(
                    () => (
                        platform == "web" &&
                        <BlockProductsCarousel
                            title="Các thương hiệu nổi bật"
                            layout="horizontal"
                            rows={3}
                            products={topSellers?.data?.topSellingProduct}
                            //loading={latestProducts.isLoading}
                            groups={category.slice(0, 4)}
                            categories={category[0].xid}
                        />
                    ),
                    [category]
            )}

            {useMemo(() => (
                platform == "web" &&
                <BlockCategories
                    title="Sản phẩm tốt nhất"
                    layout="classic"
                    products={productByBrand?.data?.allProducts?.data?.slice(10, 19)}
                />
            ), [productByBrand])}

            {/* {useMemo(() => platform == "web" && <BlockProductColumns columns={columns} />, [columns])} */}
            {platform == "web" && <BlockBanner />}

            {useMemo(
                () => (
                    <ShopPageCategory columns={4} viewMode="grid" sidebarPosition="start" />
                ),
                []
            )}
            
        </React.Fragment>
    );
}

export default HomePageOne;
