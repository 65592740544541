import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import ProductTabDescription from "./ProductTabDescription";
import ProductTabSpecification from "./ProductTabSpecification";
import ProductTabReviews from "./ProductTabReviews";
import { usePlatformValue } from "../../atom/platform";

function ProductTabs({ withSidebar, reviewsData, product }) {
    const [currentTab, setCurrentTab] = useState("description");
    const platform = usePlatformValue();

    const setTab = (newTab) => {
        setCurrentTab(newTab);
    };

    const tabs = [
        { key: "description", title: "Mô tả", content: <ProductTabDescription description={product?.description} /> },
        // { key: "specification", title: "Chi tiết", content: <ProductTabSpecification /> },
        { key: "reviews", title: "Đánh giá", content: <ProductTabReviews reviewsData={reviewsData}/> },
    ];

    const tabsButtons = tabs.map((tab) => {
        const classes = classNames("product-tabs__item", {
            "product-tabs__item--active": currentTab === tab.key,
        });

        if (platform !== "web") {
            return (
                <div
                    className={classes}
                    style={{ textAlign: 'center', fontSize: 16, fontWeight: "500", marginLeft: 0, paddingHorizontal: 10 }}
                    key={tab.key}
                    onClick={() => setTab(tab.key)}
                >
                    {tab.title}
                </div>
            );
        }

        return (
            <button
                style={{ textAlign: 'center', fontWeight: "500", marginLeft: 0 }}
                key={tab.key}
                type="button"
                onClick={() => setTab(tab.key)}
                className={classes}
            >
                {tab.title}
            </button>
        );
    });

    const tabsContent = tabs.map((tab) => {
        const classes = classNames("product-tabs__pane", {
            "product-tabs__pane--active": currentTab === tab.key,
        });

        return (
            <div key={tab.key} className={classes}>
                {tab.content}
            </div>
        );
    });

    const classes = classNames("product-tabs", {
        "product-tabs--layout--sidebar": withSidebar,
    });

    return (
        <div className={classes}>
            <div
                className="product-tabs__list justify-content-between py-2"
                style={{ overflowX: "hidden", width: "100%" }}
            >
                {tabsButtons}
            </div>
            <div className="product-tabs__content">{tabsContent}</div>
        </div>
    );
}

ProductTabs.propTypes = {
    withSidebar: PropTypes.bool,
};

ProductTabs.defaultProps = {
    withSidebar: false,
};

export default ProductTabs;
