// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Liên hệ với chúng tôi</h5>

            <div className="footer-contacts__text">
                Cửa hàng Điện Máy Nông Cơ Minh Ký mang đến những sản phẩm chính hãng, chất lượng với giá cả và chi phí phải chăng. Điện Máy Minh Ký xin được phục vụ quý khách.
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <div className="footer-contacts__text" style={{fontWeight: 'bold', fontSize: 16, marginBottom: 10}}>
                        Văn phòng giao dịch hàng hóa
                    </div>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {theme.contacts.address}
                </li>
                {/* <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {theme.contacts.email}
                </li> */}
                <li>
                    <i className="footer-contacts__icon fas fa-phone" />
                    {`${theme.contacts.phone}`}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Cả ngày 10:00pm - 7:00pm
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    Zalo Mini App: Cửa hàng Minh Ký
                </li>
            </ul>
        </div>
    );
}
