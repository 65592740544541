// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useCategoriesState } from '../../atom/categories';
import { useRequest } from 'ahooks';
import { productsByCategory } from '../../services/api/product_api';
import { useProductByCategoryState } from '../../atom/product_by_category';
import BlockLoader from '../blocks/BlockLoader';

function MegamenuLinks(props) {
    const { links, depth } = props;

    const [categories, setCategories] = useCategoriesState();
    const [, setProductByCategory] = useProductByCategoryState();
    const { data, loading, runAsync } = useRequest((slug) => productsByCategory(slug, 1), {
        debounceWait: 250,
        cacheKey: "products-category-cache",
        manual: true,
    });
    function formatName(name) {
        // Uppercase the first character
        const firstChar = name.charAt(0).toUpperCase();
        // Lowercase the remaining characters
        const restOfName = name.slice(1).toLowerCase();
        // Concatenate the first character and the rest of the name
        const formattedName = firstChar + restOfName;
        return formattedName;
    }

    const handleSlug = (idSlug) => {
        runAsync(idSlug)
            .then((res) => {
                console.log(res.data.allProducts);
                setProductByCategory(res.data.allProducts);
            })
            .catch((err) => console.log(err));
    }

    const categoriesList = categories?.slice(0, 15)?.map((item, index) => {
        let subLinks = null;
        const classes = classNames('megamenu__item', {
            'megamenu__item--with-submenu': subLinks,
        });
        return (
            <li key={index} className={classes}>
                <div onClick={() => handleSlug(`&${item.xid}`)}>
                    <Link to={`/category/${item.xid}`}>{formatName(item.name)}
                    </Link>
                </div>
            </li>
        );
    });
    return (
        <ul className={`megamenu__links megamenu__links--level--${depth}`}>
            {/* {linksList} */}
            {categoriesList}
            <li style={{ color: 'gray' }}>
                <Link to={`/category/all-products`}>Xem thêm...
                </Link>
            </li>
        </ul>
    );
}

MegamenuLinks.propTypes = {
    links: PropTypes.array,
    depth: PropTypes.number,
};

MegamenuLinks.defaultProps = {
    depth: 0,
};

export default MegamenuLinks;
