// react
import React from "react";

// application
import Pagination from "../shared/Pagination";
import Rating from "../shared/Rating";

// data stubs
import { validateDate } from "../../utils/constant/convertDate";
import { useState } from "react";

function ProductTabReviews({ reviewsData }) {
    const itemsPerPage = 3; // number item in one page
    const totalPages = Math.ceil(reviewsData?.data.length / itemsPerPage) || 1;
    const [currentPage, setCurrentPage] = useState(1);
    const [positionSlice, setPositionSlice] = useState({
        startIndex: 0,
        endIndex: itemsPerPage,
    });

    const handlePage = (newPage) => {
        setCurrentPage(newPage);
        const startIndex = (newPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setPositionSlice({ startIndex: startIndex, endIndex: endIndex });
    };

    const ReviewCard = ({ item }) => {
        return (
            <li className="reviews-list__item">
                <div className="review">
                    <div className="review__avatar">
                        <img
                            src={item.user?.profile_image || "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                            alt=""
                        />
                    </div>
                    <div className=" review__content">
                        <div className=" review__author">{item.user.name}</div>
                        <div className=" review__rating">
                            <Rating value={item.rating} />
                        </div>
                        <div className=" review__text">{item.user_comment}</div>
                        <div className=" review__date">{validateDate(item.created_at)}</div>
                    </div>
                </div>
            </li>
        );
    };

    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                <h5 className="reviews-view__header">Đánh giá của người dùng</h5>

                <div className="reviews-list">
                    <ol className="reviews-list__content">
                        {reviewsData?.data.length > 0 ? (
                            reviewsData?.data
                                .slice(positionSlice.startIndex, positionSlice.endIndex)
                                .map((item, index) => <ReviewCard key={index} item={item} />)
                        ) : (
                            <span style={{ color: "gray" }}>Chưa có đánh giá nào!!!</span>
                        )}
                    </ol>
                    <div className="reviews-list__pagination">
                        <Pagination current={currentPage} total={totalPages} onPageChange={(newPage) => handlePage(newPage)} />
                    </div>
                </div>
            </div>

            {/* <form className="reviews-view__form">
                <h5 className="reviews-view__header">Bình luận</h5>
                <div className="row">
                    <div className="col-12 col-lg-9 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="review-stars">Chất lượng</label>
                                <select id="review-stars" className="form-control">
                                    <option>5 Sao</option>
                                    <option>4 Sao</option>
                                    <option>3 Sao</option>
                                    <option>2 Sao</option>
                                    <option>1 Sao</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-author">Tên của bạn</label>
                                <input type="text" className="form-control" id="review-author" placeholder="Tên của bạn" />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-email">Địa chỉ liên hệ</label>
                                <input type="text" className="form-control" id="review-email" placeholder="Địa chỉ của bạn" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="review-text">Bình luận của bạn</label>
                            <textarea className="form-control" id="review-text" rows="6" />
                        </div>
                        <div className="form-group mb-0">
                            <div className="btn btn-primary btn-lg">Đăng</div>
                        </div>
                    </div>
                </div>
            </form> */}
        </div>
    );
}

export default ProductTabReviews;
