// react
import React from "react";

function ProductTabDescription({ description }) {
    if (description) {
        return (
            <div className="typography">
                <div
                    dangerouslySetInnerHTML={{ __html: description }}
                    style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                />
            </div>
        );
    } else {
        return (
            <div className="typography">
                <h5>Chi tiết sản phẩm</h5>
                <p style={{ textAlign: "justify" }}>
                    Theo như mọi người cũng đã biết máy móc thiết bị công nghiệp rất là nhiều ? Vậy nó áp dụng trong môi
                    trường cơ khí nặng nhọc hoặc gia công trong doanh nghiệp, cơ sở.
                    <br />
                    Như trong công việc sản xuất, gia công nó được ứng dụng nổi bật như thế nào:
                    <br />
                    – Tiết kiệm được thời gian làm việc, nâng cao năng suất hoạt động.
                    <br />
                    – Áp dụng trong môi trường công nghiệp dây chuyền sản xuất, nhằm thu hút nhà đầu tư lớn.
                    <br />
                    – Nâng cao cải tiến, sử dụng kỹ thuật vào sản xuất cải thiện nâng suất hoạt động. Thay thế người lao
                    động làm việc trong môi trường nặng nọc, liên hoàn, không bị ngắt quãng.
                    <br />
                    – Hạn chế chi phí thuê nhân công, tiết kiệm sức lao động của con người.
                    <br />
                    – Giảm chi phí sản xuất, tăng chất lượng sản phẩm lên cao và hạ giá thành đầu ra của sản phẩm.
                    <br />
                    – Từng chuỗi sản xuất gia công các mặt hàng công nghiệp thì giá thành thiết bị phù hợp hơn.
                    <br />– Hỗ trợ công việc mà con người không thể vận dụng bằng phương pháp thủ công như khoan trên
                    sắt thép, đường ray xe lửa, hay taro trên các vật liệu khác.
                </p>
            </div>
        );
    }
}

export default ProductTabDescription;
