import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { url } from "../../services/utils";
import { usePlatformValue } from "../../atom/platform";
import Rating from "../shared/Rating";
import { useCart } from "../../atom/cart";
import { useOrder } from "../../atom/order";
import { Cross12Svg } from "../../svg";
import classNames from "classnames";
import Modal from "react-modal";
import { useAuthState } from "../../atom/auth";

const ShopPageCart = () => {
    const [auth, setAuth] = useAuthState();
    const { cart, setCart, addToCart, removeFromCart, isItemInCart, updateCartItem } = useCart();
    const { order, setOrder, addToOrder, removeFromOrder, getTotalPrice, isItemInOrder } = useOrder();

    const platform = usePlatformValue();
    const [isCheckedAll, setIsCheckedAll] = useState(true);
    const [modalIsOpen, setIsOpen] = useState({ product: null, open: false });

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });

    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const handleCheckAll = () => {
        setIsCheckedAll(!isCheckedAll);
        if (!isCheckedAll) {
            setOrder(cart);
        } else {
            setOrder([]);
        }
    };

    const handleRemoveItem = (id) => {
        if (order.length > 0) {
            removeFromOrder(id);
        }
        removeFromCart(id);
    };

    useEffect(() => {
        const checkStatusOrder = () => {
            for (let i = 0; i < cart.length; i++) {
                if (!isItemInOrder(cart[i].product.product_id)) {
                    setIsCheckedAll(false);
                    return;
                }
            }
        };
        checkStatusOrder();
    }, []);

    const Item = ({ item }) => {
        const quantity = useRef(item.quantity);

        const handleChangeQuantity = (item, newQuantity) => {
            quantity.current = newQuantity;
            addToCart(item.product, newQuantity, "multi");
            if (isItemInOrder(item.product.product_id)) {
                addToOrder(item.product, quantity.current);
            }
        };

        const handleAddToOrder = (item) => {
            if (!isItemInOrder(item.product.product_id)) {
                addToOrder(item.product, quantity.current);
                if (order.length + 1 === cart.length) {
                    setIsCheckedAll(true);
                }
            } else {
                setIsCheckedAll(false);
                removeFromOrder(item.product.product_id);
            }
        };

        if (platform !== "web") {
            return (
                <div
                    key={item?.product?.product_id}
                    className="row mt-3 mx-1 p-2 border rounded shadow-sm"
                    style={{ position: "relative", fontSize: 14 }}
                >
                    <div className="product-image" style={{ width: 120, height: 120 }}>
                        <div className="product-image__body">
                            <img
                                className="product-image__img"
                                src={item?.product?.img_sapo || item?.product.image_url}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col" style={{ justifyContent: "space-between" }}>
                        <Link to={{ pathname: url.product(item?.product), state: { product: item?.product } }}>
                            <div className="mr-2">
                                <span style={{ color: "black", fontWeight: "500" }}>
                                    {item?.product?.name.length < 40
                                        ? item?.product?.name
                                        : item?.product?.name.slice(0, 40) + "..."}
                                </span>
                                <div className="row" style={{ marginLeft: 0 }}>
                                    <Rating value={item.product.rating || 5} />
                                    {/* <div className="wishlist__product-rating-legend" style={{ fontSize: 12 }}>
                                    10+ Đánh giá
                                </div> */}
                                </div>
                            </div>
                        </Link>
                        <div style={{ position: "absolute", bottom: 0, right: 0, width: "100%" }}>
                            <div className="row align-items-center justify-content-between ml-3 mr-1">
                                <span>Số lượng</span>
                                <div className="w-50">
                                    <InputNumber
                                        onChange={(newQuantity) => handleChangeQuantity(item, newQuantity)}
                                        value={quantity.current}
                                        min={1}
                                        size="sm"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-between ml-3 mr-1 mt-1">
                                <span>Tổng tiền</span>
                                <div style={{ fontWeight: "500" }}>
                                    <Currency value={item?.product?.sales_price * quantity.current} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <input
                        type="checkbox"
                        checked={isItemInOrder(item.product.product_id)}
                        onChange={() => handleAddToOrder(item)}
                        style={{
                            width: 22,
                            height: 22,
                            paddingRight: 3,
                            paddingBottom: 3,
                            position: "absolute",
                            top: -1,
                            left: -1,
                            zIndex: 0,
                        }}
                    />
                    <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <button
                            type="button"
                            onClick={() => setIsOpen({ product: item.product, open: true })}
                            className={classNames("btn btn-secondary btn-sm btn-svg-icon")}
                            aria-label="Remove"
                        >
                            <Cross12Svg />
                        </button>
                    </div>
                </div>
            );
        }
        else{
        if(windowSize.width < 800){
            return (
                <div
                    key={item?.product?.product_id}
                    className="row mt-3 mx-1 p-2 border rounded shadow-sm"
                    style={{ position: "relative", fontSize: 14 }}
                >
                    <div className="product-image" style={{ width: 120, height: 120 }}>
                        <div className="product-image__body">
                            <img
                                className="product-image__img"
                                src={item?.product?.img_sapo || item?.product.image_url}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col" style={{ justifyContent: "space-between" }}>
                        <Link to={{ pathname: url.product(item?.product), state: { product: item?.product } }}>
                            <div className="mr-2">
                                <span style={{ color: "black", fontWeight: "500" }}>
                                    {item?.product?.name.length < 40
                                        ? item?.product?.name
                                        : item?.product?.name.slice(0, 40) + "..."}
                                </span>
                                <div className="row" style={{ marginLeft: 0 }}>
                                    <Rating value={item.product.rating || 5} />
                                    {/* <div className="wishlist__product-rating-legend" style={{ fontSize: 12 }}>
                                    10+ Đánh giá
                                </div> */}
                                </div>
                            </div>
                        </Link>
                        <div style={{ position: "absolute", bottom: 0, right: 0, width: "100%" }}>
                            <div className="row align-items-center justify-content-between ml-3 mr-1">
                                <span>Số lượng</span>
                                <div className="w-50">
                                    <InputNumber
                                        onChange={(newQuantity) => handleChangeQuantity(item, newQuantity)}
                                        value={quantity.current}
                                        min={1}
                                        size="sm"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-between ml-3 mr-1 mt-1">
                                <span>Tổng tiền</span>
                                <div style={{ fontWeight: "500" }}>
                                    <Currency value={item?.product?.sales_price * quantity.current} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <input
                        type="checkbox"
                        checked={isItemInOrder(item.product.product_id)}
                        onChange={() => handleAddToOrder(item)}
                        style={{
                            width: 22,
                            height: 22,
                            paddingRight: 3,
                            paddingBottom: 3,
                            position: "absolute",
                            top: 6,
                            left: 6,
                            zIndex: 0,
                        }}
                    />
                    <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <button
                            type="button"
                            onClick={() => setIsOpen({ product: item.product, open: true })}
                            className={classNames("btn btn-secondary btn-sm btn-svg-icon")}
                            aria-label="Remove"
                        >
                            <Cross12Svg />
                        </button>
                    </div>
                </div>
            );
            
        }

        return (
            <tr key={item.product.product_id} className="wishlist__row">
                <td className="wishlist__column">
                    <input
                        type="checkbox"
                        checked={isItemInOrder(item.product.product_id)}
                        onChange={() => handleAddToOrder(item)}
                        style={{ width: 20, height: 20, backgroundColor: "red"}}
                    />
                </td>
                <td className="wishlist__column wishlist__column--image">
                    <div className="product-image">
                        <Link
                            to={{ pathname: url.product(item.product), state: { product: item.product } }}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.product.img_sapo || item.product.image_url}
                                alt=""
                            />
                        </Link>
                    </div>
                </td>
                <td className="wishlist__column wishlist__column--product">
                    <Link
                        to={{ pathname: url.product(item.product), state: { product: item.product } }}
                        className="wishlist__product-name"
                    >
                        {item.product.name}
                    </Link>
                    <div className="wishlist__product-rating">
                        <Rating value={item.product.rating || 5} />
                        {/* <div className="wishlist__product-rating-legend">
                            {Math.floor(Math.random() * 31) + 20}+ Đánh giá
                        </div> */}
                    </div>
                </td>
                <td className="wishlist__column wishlist__column--stock">
                    <InputNumber
                        onChange={(quantity) => handleChangeQuantity(item, quantity)}
                        value={quantity.current}
                        min={1}
                    />
                </td>
                <td className="wishlist__column wishlist__column--price">
                    <Currency value={item.product.sales_price * quantity.current} />
                </td>
                <td className="wishlist__column wishlist__column--remove">
                    <button
                        type="button"
                        onClick={() => setIsOpen({ product: item.product, open: true })}
                        className={classNames("btn btn-light btn-sm btn-svg-icon")}
                        aria-label="Remove"
                    >
                        <Cross12Svg />
                    </button>
                </td>
            </tr>
        );
    }
    };

    const renderCart = () => {
        return platform !== "web" ? (
            <div className="cart block">
                <div className="container">
                    {cart.length > 0 && cart.map((item, index) => <Item key={index} item={item} />)}
                    <div className="fixed-bottom border" style={{ height: 68, backgroundColor: "white", zIndex: 20 }}>
                        <div className="row h-100">
                            <div className="col-7 align-items-center">
                                <div className="col">
                                    <div className="d-flex mt-1">
                                        <input
                                            type="checkbox"
                                            checked={isCheckedAll}
                                            onChange={() => handleCheckAll()}
                                            style={{ width: 20, height: 20, marginRight: 8 }}
                                        />
                                        <span style={{ color: "gray" }}>Tất cả</span>
                                    </div>
                                    <div style={{ fontWeight: "700", fontSize: 18 }}>
                                        <Currency value={getTotalPrice()} />
                                    </div>
                                </div>
                            </div>
                            {order.length < 1 ? (
                                <div className="col-5 d-flex align-items-center justify-content-center">
                                    <button
                                        type="button"
                                        disabled={order.length < 1}
                                        className="btn h-100 w-100"
                                        style={{ backgroundColor: "#FFD333" }}
                                    >
                                        <span className="text-nowrap">Mua hàng({order.length})</span>
                                    </button>
                                </div>
                            ) : (
                                <Link to={{ pathname: "/shop/checkout" }} className="col-5">
                                    <div
                                        className="h-100 d-flex align-items-center justify-content-center"
                                        style={{ backgroundColor: "#FFD333" }}
                                    >
                                        <span className="text-nowrap" style={{ color: "#000", fontWeight: "500" }}>
                                            Mua hàng({order.length})
                                        </span>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="block">
                <div className="container">
                    <div className="d-flex" style={{ marginBottom: 12 }}>
                        <input
                            type="checkbox"
                            checked={isCheckedAll}
                            onChange={() => handleCheckAll()}
                            style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span style={{ color: "black" }}>Chọn tất cả</span>
                    </div>
                    {windowSize.width < 800 ?
                    cart.length > 0 && cart.map((item, index) => <Item key={index} item={item} />)
                    :
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image" aria-label="Add to order" />
                                <th className="wishlist__column wishlist__column--image">Sản phẩm</th>
                                <th className="wishlist__column wishlist__column--product">Tên</th>
                                <th className="wishlist__column wishlist__column--stock">Số lượng</th>
                                <th className="wishlist__column wishlist__column--price">Giá</th>
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">
                            {cart.length > 0 && cart.map((item, index) => <Item key={index} item={item} />)}
                        </tbody>
                    </table>
                    }
                    <div style={{ marginTop: 32 }}>
                        <div className="row justify-content-end mr-0">
                            <div className="">
                                <div style={{ fontWeight: "700", fontSize: 18, marginBottom: 8 }}>
                                    Tổng tiền: <Currency value={getTotalPrice()} />
                                </div>
                                {order.length < 1 ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <button
                                            type="button"
                                            disabled={order.length < 1}
                                            className="btn w-100 align-items-center justify-content-center"
                                            style={{ backgroundColor: "#FFD333", height: 50 }}
                                        >
                                            <span className="text-nowrap">Mua hàng({order.length})</span>
                                        </button>
                                    </div>
                                ) : (
                                    <Link to={{ pathname: auth.token ? "/shop/checkout" : "/account/login" }}>
                                        <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{ backgroundColor: "#FFD333", height: 50 }}
                                        >
                                            <span className="text-nowrap" style={{ color: "#000", fontWeight: "500" }}>
                                                Mua hàng({order.length})
                                            </span>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const CancelModal = () => {
        const customStyles = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black overlay
                zIndex: 99999,
            },
            content: {
                top: "40%",
                left: "50%",
                width: platform !== "web" ? "90%" : "30%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 10,
                maxHeight: "70%",
            },
        };
        return (
            <Modal
                isOpen={modalIsOpen.open}
                onRequestClose={() => setIsOpen({ title: "", open: false })}
                style={customStyles}
                contentLabel="delete items in cart"
            >
                <p style={{ textAlign: "justify" }}>
                    Bạn muốn <span style={{ fontWeight: "500", color: "red" }}>xóa</span> "
                    <span style={{ fontWeight: "500" }}>{modalIsOpen.product?.name}</span>" khỏi giỏ hàng không ?
                </p>
                <div className="w-100 row justify-content-between ml-0">
                    <button
                        type="button"
                        onClick={() => setIsOpen({ product: null, open: false })}
                        className="btn btn-secondary rounded"
                    >
                        Không
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            handleRemoveItem(modalIsOpen.product.product_id);
                            setIsOpen({ product: null, open: false });
                        }}
                        className="btn btn-danger rounded"
                    >
                        Xóa ngay
                    </button>
                </div>
            </Modal>
        );
    };

    const breadcrumb = [
        { title: "Trang chủ", url: "" },
        { title: "Giỏ hàng", url: "" },
    ];

    return (
        <>
            <Helmet>
                <title>Giỏ hàng</title>
            </Helmet>

            <PageHeader header="Giỏ hàng" breadcrumb={breadcrumb} />

            {cart?.length > 0 ? (
                renderCart()
            ) : (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div>
                                <img
                                    src="https://cdn-icons-png.flaticon.com/512/4318/4318459.png"
                                    style={{ height: 48 }}
                                />
                            </div>
                            <div className="block-empty__message mt-3">Giỏ hàng của bạn chưa có sản phẩm nào,</div>
                            <div className="block-empty__message">Đặt mua sản phẩm ngay!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    Đến trang chủ
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <CancelModal />
        </>
    );
};

export default ShopPageCart;
