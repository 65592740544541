export const valueFilter = {}
export const stateFilter = [
    {
        "type": "category",
        "slug": "category",
        "name": "Danh mục",
        "items": [
            {
                "id": 2,
                "name": "Power Tools",
                "slug": "power-tools",
                "image": "assets/images/categories/category-1.jpg",
                "items": 370,
                "customFields": {},
                "parent": {
                    "id": 1,
                    "name": "Instruments",
                    "slug": "instruments",
                    "image": null,
                    "items": 272,
                    "customFields": {},
                    "parent": null
                },
                "children": [
                    {
                        "id": 3,
                        "name": "Drills & Mixers",
                        "slug": "drills-mixers",
                        "image": null,
                        "items": 57,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    },
                    {
                        "id": 4,
                        "name": "Cordless Screwdrivers",
                        "slug": "cordless-screwdrivers",
                        "image": null,
                        "items": 15,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    },
                    {
                        "id": 5,
                        "name": "Screwdrivers",
                        "slug": "screwdrivers",
                        "image": null,
                        "items": 126,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    },
                    {
                        "id": 6,
                        "name": "Wrenches",
                        "slug": "wrenches",
                        "image": null,
                        "items": 12,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    },
                    {
                        "id": 7,
                        "name": "Grinding Machines",
                        "slug": "grinding-machines",
                        "image": null,
                        "items": 25,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    },
                    {
                        "id": 8,
                        "name": "Milling Cutters",
                        "slug": "milling-cutters",
                        "image": null,
                        "items": 78,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    },
                    {
                        "id": 9,
                        "name": "Electric Spray Guns",
                        "slug": "electric-spray-guns",
                        "image": null,
                        "items": 3,
                        "customFields": {},
                        "parent": {
                            "id": 2,
                            "name": "Power Tools",
                            "slug": "power-tools",
                            "image": "assets/images/categories/category-1.jpg",
                            "items": 370,
                            "customFields": {},
                            "parent": {
                                "id": 1,
                                "name": "Instruments",
                                "slug": "instruments",
                                "image": null,
                                "items": 272,
                                "customFields": {},
                                "parent": null
                            }
                        }
                    }
                ]
            }
        ],
        "value": "power-tools"
    },
    {
        "type": "range",
        "slug": "price",
        "name": "Giá",
        "min": 0,
        "max": 50000000,
        "value": [
            0,  //  default value min price
            0, //  default value max price
        ]
    },
    {
        "type": "check",
        "slug": "brand",
        "name": "Hãng",
        "items": [
            {
                "slug": "brandix",
                "name": "Brandix",
                "count": 5
            },
            {
                "slug": "zosch",
                "name": "Zosch",
                "count": 1
            },
            {
                "slug": "wakita",
                "name": "Wakita",
                "count": 2
            },
            {
                "slug": "wevalt",
                "name": "WeVALT",
                "count": 1
            },
            {
                "slug": "hammer",
                "name": "Hammer",
                "count": 4
            },
            {
                "slug": "mitasia",
                "name": "Mitasia",
                "count": 2
            },
            {
                "slug": "metaggo",
                "name": "Metaggo",
                "count": 1
            }
        ],
        "value": []
    },
    {
        "type": "radio",
        "slug": "discount",
        "name": "Giảm giá",
        "items": [
           
            {
                "slug": "no",
                "name": "Không",
                // "count": 15
            },
            {
                "slug": "yes",
                "name": "Có",
                // "count": 1
            }
        ],
        "value": "any"
    },
    {
        "type": "radio",
        "slug": "size",
        "name": "Kích thước",
        "items": [
           
            {
                "slug": "small",
                "name": "Size nhỏ",
                //"count": 15
            },
            {
                "slug": "medium",
                "name": "Size trung bình",
                // "count": 15
            },
            {
                "slug": "large",
                "name": "Size lớn",
                // "count": 1
            }
        ],
        "value": "any"
    },
    /* {
        "type": "color",
        "slug": "color",
        "name": "Màu sắc",
        "items": [
            {
                "slug": "white",
                "name": "White",
                "color": "#fff",
                "count": 1
            },
            {
                "slug": "silver",
                "name": "Silver",
                "color": "#d9d9d9",
                "count": 1
            },
            {
                "slug": "light-gray",
                "name": "Light Gray",
                "color": "#b3b3b3",
                "count": 1
            },
            {
                "slug": "gray",
                "name": "Gray",
                "color": "#808080",
                "count": 1
            },
            {
                "slug": "dark-gray",
                "name": "Dark Gray",
                "color": "#666",
                "count": 1
            },
            {
                "slug": "coal",
                "name": "Coal",
                "color": "#4d4d4d",
                "count": 1
            },
            {
                "slug": "black",
                "name": "Black",
                "color": "#262626",
                "count": 1
            },
            {
                "slug": "red",
                "name": "Red",
                "color": "#ff4040",
                "count": 1
            },
            {
                "slug": "orange",
                "name": "Orange",
                "color": "#ff8126",
                "count": 1
            },
            {
                "slug": "yellow",
                "name": "Yellow",
                "color": "#ffd333",
                "count": 2
            },
            {
                "slug": "pear-green",
                "name": "Pear Green",
                "color": "#becc1f",
                "count": 1
            },
            {
                "slug": "green",
                "name": "Green",
                "color": "#8fcc14",
                "count": 1
            },
            {
                "slug": "emerald",
                "name": "Emerald",
                "color": "#47cc5e",
                "count": 1
            },
            {
                "slug": "shamrock",
                "name": "Shamrock",
                "color": "#47cca0",
                "count": 1
            },
            {
                "slug": "shakespeare",
                "name": "Shakespeare",
                "color": "#47cccc",
                "count": 1
            },
            {
                "slug": "blue",
                "name": "Blue",
                "color": "#40bfff",
                "count": 1
            },
            {
                "slug": "dark-blue",
                "name": "Dark Blue",
                "color": "#3d6dcc",
                "count": 1
            },
            {
                "slug": "violet",
                "name": "Violet",
                "color": "#7766cc",
                "count": 1
            },
            {
                "slug": "purple",
                "name": "Purple",
                "color": "#b852cc",
                "count": 1
            },
            {
                "slug": "cerise",
                "name": "Cerise",
                "color": "#e53981",
                "count": 1
            }
        ],
        "value": []
    } */
]