import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { setDataToStorage } from "../utils/constant/config";
import { StorageKey } from "../services/storage";

export const CART_STATE = atom({
    key: "CART_STATE", // unique ID (with respect to other atoms/selectors)
    default: [],
    // default value (aka initial value)
});

export const useCart = () => {
    const [cart, setCart] = useRecoilState(CART_STATE);

    const addToCart = (product, quantity, type) => {
        if (cart.length < 1) {
            setCart([{ product: product, quantity: quantity }]);
        } else {
            const existingItem = cart.find((cartItem) => cartItem.product.product_id === product.product_id);
            if (existingItem) {
                let updatedCart = cart.map((cartItem) =>
                    cartItem.product.product_id === product.product_id
                        ? { product: product, quantity: type == "multi" ? quantity : cartItem.quantity + quantity }
                        : cartItem
                );
                setCart(updatedCart);
                setDataToStorage(StorageKey.Cart, updatedCart);
            } else {
                let updatedCart = [{ product: product, quantity: quantity }, ...cart];
                setCart(updatedCart);
                setDataToStorage(StorageKey.Cart, updatedCart);
            }
        }
    };

    const removeFromCart = (itemId) => {
        let updatedCart = cart.filter((x) => x.product.product_id !== itemId);
        setCart(updatedCart);
        setDataToStorage(StorageKey.Cart, updatedCart);
    };

    const isItemInCart = (itemId) => {
        const index = cart.findIndex((item) => item.product.product_id === itemId);
        return index != -1;
    };

    const getQuantity = (itemId) => {
        const index = cart.findIndex((item) => item.product.product_id === itemId);
        return index != -1 ? cart[index].quantity : 1;
    };

    const updateCartItem = (itemId, updatedItem) => {
        setCart((prevCart) =>
            prevCart.map((item) => {
                if (item.id === itemId) {
                    return { ...item, ...updatedItem };
                }
                return item;
            })
        );
    };

    return { cart, setCart, addToCart, removeFromCart, isItemInCart, getQuantity, updateCartItem };
};

// [company_id: 1, warehouse: 'electronifly', address_id: 'AdWNDqgV', products: [{xId, cart_quantity: 1}], ]

export const useCartState = () => useRecoilState(CART_STATE);

export const useCartValue = () => useRecoilValue(CART_STATE);

export const useSetCartValue = () => useSetRecoilState(CART_STATE);
