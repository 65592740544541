// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
import mobileMenuLinks from '../../data/mobileMenu';
import { useCategoriesValue } from '../../atom/categories';

function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
    } = props;

    const category = useCategoriesValue();
    const categoryData = category?.map((item) => ({
        type: 'link',
        label: item.name,
        url: `/category/${item.xid}`
    }));
    const mobileMenu = [
        {
            type: 'link',
            label: 'Trang chủ',
            url: '/',
        },
        {
            label: 'Danh mục',
            url: '/category/all-products',
            type: 'link',
            children: categoryData
        },
        {
            label: 'Sản phẩm',
            url: '/category/all-products',
            type: 'link',
            children: [
                { type: 'link', label: 'Danh sách sản phẩm', url: '/category/all-products' },
                { type: 'link', label: 'Danh sách yêu thích', url: '/shop/wishlist' },
                { type: 'link', label: 'Giỏ hàng', url: '/shop/cart' },
            ]
        },
    
        {
            label: 'Tài khoản',
            type: 'link',
            url: '/account',
            children: [
                { type: 'link', label: 'Tài khoản của tôi', url: '/account/dashboard' },
                { type: 'link', label: 'Chỉnh sửa thông tin', url: '/account/profile' },
                { type: 'link', label: 'Đơn hàng của tôi', url: '/account/orders' },
                { type: 'link', label: 'Danh sách địa chỉ', url: '/account/addresses' },
                { type: 'link', label: 'Thay đổi mật khẩu', url: '/account/password' },
                { type: 'link', label: 'Đăng xuất', url: '/account/login' },
            ]
    
        },
        {
            label: 'Liên hệ',
            url: '/contact-us',
            type: 'link',
        },
    ];

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenu} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
