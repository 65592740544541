export const getParamsUrl = (url) => {
    if (url.charAt(0) === "/") {
        url = url.slice(1);
    }
    const params = new URLSearchParams(url);
    const data = {};

    for (const [key, value] of params) {
        data[key] = value;
    }

    return data;
};
