// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";

// data stubs
import theme from "../../data/theme";
import TextInput from "../Custom/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaRegister } from "../../schema/schemaRegister";
import { useRequest } from "ahooks";
import { signUpApi } from "../../services/api/user_api";
import { toast } from "react-toastify";
import BlockLoader from "../blocks/BlockLoader";

export default function AccountPageRegister() {
    const history = useHistory();
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schemaRegister),
    });

    const { runAsync, loading } = useRequest((data) => signUpApi(data), {
        debounceInterval: 250,
        cacheKey: "signUp",
        manual: true,
    });

    const onSubmit = (data) => {
        console.log(data);
        if (data.password !== data.confirmPassword) {
            setIsErrorPassword(true);
        } else {
            setIsErrorPassword(false);
            runAsync(data)
                .then((res) => {
                    console.log(res);
                    toast.success(`Đăng ký tài khoản thành công, đăng nhập ngay!`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                    history.push("/account/login");
                })
                .catch((err) => {
                    console.log(err);
                    const errorMessage = err.error.details;
                    let x = true;
                    if (errorMessage.email && errorMessage.email[0] == "The email has already been taken.") {
                        toast.error(`Email đã tồn tại, vui lòng thử email khác!`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        x = false;
                    }
                    if (errorMessage.phone && errorMessage.phone[0] == "The phone has already been taken.") {
                        toast.error(`Số điện thoại đã tồn tại, vui lòng thử số điện thoại khác!`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                        x = false;
                    }
                    if (x) {
                        toast.error(`Đăng ký tài khoản không thành công, vui lòng thử lại sau!`, {
                            theme: "colored",
                            className: "mx-2 rounded mt-2",
                        });
                    }
                });
        }
    };

    const breadcrumb = [
        { title: "Trang chủ", url: "" },
        { title: "Tài khoản của tôi", url: "" },
    ];

    if (loading) return <BlockLoader />;

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Đăng ký — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Đăng ký tài khoản" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="col-md-6 d-flex mt-4 mt-md-0">
                        <div className="card flex-grow-1 mb-0">
                            <div className="card-body">
                                <h3 className="card-title">Đăng ký</h3>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <TextInput
                                        label="Họ và tên"
                                        rules={"name"}
                                        type="text"
                                        placeholder="Nguyễn Văn Ánh"
                                        register={register}
                                        errors={errors.name ? errors.name.message : ""}
                                    />
                                    <TextInput
                                        label="Số điện thoại"
                                        rules={"phone"}
                                        type="numberic"
                                        placeholder="0 xxx xxx xxx"
                                        register={register}
                                        errors={errors.phone ? errors.phone.message : ""}
                                    />
                                    <TextInput
                                        label="Địa chỉ email"
                                        rules={"email"}
                                        type="email"
                                        placeholder="example@example"
                                        register={register}
                                        errors={errors.email ? errors.email.message : ""}
                                    />
                                    <TextInput
                                        label="Mật khẩu"
                                        rules={"password"}
                                        type="password"
                                        placeholder="xxx xxx xxx"
                                        register={register}
                                        errors={errors.password ? errors.password.message : ""}
                                    />
                                    <TextInput
                                        label="Xác nhận mật khẩu"
                                        rules={"confirmPassword"}
                                        type="password"
                                        placeholder="xxx xxx xxx"
                                        register={register}
                                        errors={errors.confirmPassword ? errors.confirmPassword.message : ""}
                                    />
                                    {isErrorPassword && (
                                        <p style={{ color: "red", marginTop: 2 }}>Mật khẩu không trùng khớp</p>
                                    )}
                                    <button type="submit" className="btn btn-primary rounded mt-3 mb-10">
                                        Đăng ký
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
