import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Indicator from '../header/Indicator';
import {
  Menu18x14Svg,
  LogoSmallSvg,
  Search20Svg,
  Heart20Svg,
  Cart20Svg,
} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';
import { useWishListValue } from '../../atom/wishlist';
import { useCartValue } from '../../atom/cart';

const MobileHeader = ({ openMobileMenu, wishlist, cart }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const searchInput = useRef(null);
  const wishlistAtom = useWishListValue();
  const cartAtom = useCartValue();

  useEffect(() => {
    if (searchOpen && searchInput.current) {
      searchInput.current.focus();
    }
  }, [searchOpen]);

  const handleOpenSearch = () => {
    setSearchOpen(true);
  };

  const handleCloseSearch = () => {
    setSearchOpen(false);
  };

  const searchClasses = classNames('mobile-header__search', {
    'mobile-header__search--open': searchOpen,
  });

  return (
    <div className="mobile-header">
      <div className="mobile-header__panel">
        <div className="container">
          <div className="mobile-header__body">
            <button
              type="button"
              className="mobile-header__menu-button"
              onClick={openMobileMenu}
            >
              <Menu18x14Svg />
            </button>
            <Link to="/" className="mobile-header__logo">
              <LogoSmallSvg />
            </Link>
            {/* <Search
              context="mobile-header"
              className={searchClasses}
              inputRef={searchInput}
              onClose={handleCloseSearch}
            /> */}
            <div className="mobile-header__indicators">
              {/* <Indicator
                className="indicator--mobile indicator--mobile-search d-md-none"
                onClick={handleOpenSearch}
                icon={<Search20Svg />}
              /> */}
              <Indicator
                className="indicator--mobile d-sm-flex d-none"
                url="/shop/wishlist"
                value={wishlistAtom.length}
                icon={<Heart20Svg />}
              />
              <Indicator
                className="indicator--mobile"
                url="/shop/cart"
                value={cartAtom.length}
                icon={<Cart20Svg />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  wishlist: state.wishlist,
});

const mapDispatchToProps = {
  openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
