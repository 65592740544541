import { ApiUtils } from ".";
import { PATH } from "./path";

export const checkoutOrdersApi = async (data, customHeaders) => {
    return await ApiUtils.post(PATH.checkout_order, data, customHeaders);
};

export const checkoutOrdersVNPayApi = async (data, customHeaders) => {
    return await ApiUtils.post(PATH.checkout_order_vnpay, data, customHeaders);
};

export const getMyOrdersApi = async (customHeaders) => {
    return await ApiUtils.request(PATH.get_my_orders, customHeaders);
};

export const cancelOrderApi = async (uniqueId, customHeaders) => {
    return await ApiUtils.post(PATH.cancel_order + uniqueId, customHeaders);
};

export const saveOrderApi = async (params, customHeaders) => {
    return await ApiUtils.post(PATH.save_order, params, customHeaders);
};