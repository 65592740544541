// react
import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

export default function SelectInput(props) {
    const { name, label, icon, errors, control, options, func, reset, defaultValue } = props;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#EEEEEE" : "white",
            color: state.isSelected ? "#FF9900" : "black",
        }),
    };

    return (
        <div className="form-group">
            <div className="d-flex align-items-center mb-2">
                {icon}
                <span style={{ fontSize: 16, fontWeight: "500", marginLeft: 4 }}>{label}</span>
            </div>
            <Controller
                name={name} // for the gender field
                control={control} // obtained from the useForm hook
                render={({ field: { onChange, onBlur, value } }) => {
                    return (
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={options}
                            styles={customStyles}
                            defaultValue={defaultValue}
                            onChange={(x) => {
                                onChange(x.label)
                                func(x.value)
                                reset()
                            }}
                            noOptionsMessage={() => "Không có dữ liệu"}
                        />
                    );
                }}
            />
            <p style={{ color: "red", marginTop: 2 }}>{errors}</p>
        </div>
    );
}
