import React from "react";
import "./loading.css";

export function Loading() {
    return (
        <div
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <div className="loader"></div>
        </div>
    );
}
