import * as yup from "yup";
import { REGEX } from "./regex";

export const schemaPassword = yup.object().shape({
    oldPassword: yup
        .string()
        .required("Vui lòng nhập mật khẩu hiện tại.")
        .max(120)
        .matches(RegExp(REGEX.PASSWORD), "Tối thiểu 8 ký tự.")
        .typeError(""),
    newPassword: yup
        .string()
        .required("Vui lòng nhập mật khẩu mới.")
        .max(120)
        .matches(RegExp(REGEX.PASSWORD), "Tối thiểu 8 ký tự.")
        .typeError(""),
    confirmPassword: yup
        .string()
        .required("Vui lòng nhập lại mật khẩu mới.")
        .max(120)
        .matches(RegExp(REGEX.PASSWORD), "Tối thiểu 8 ký tự.")
        .typeError(""),
});
