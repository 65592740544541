// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import { usePlatformValue } from "../../atom/platform";
import { useUserState } from "../../atom/user";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiOutlineMapPin, HiOutlinePhone, HiOutlineUser } from "react-icons/hi2";
import { HiOutlineMail } from "react-icons/hi";
import { schemaEditProfile, schemaEditProfileZalo } from "../../schema/schemaEditProfile";
import TextInput from "../Custom/TextInput";
import { useRequest } from "ahooks";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useAuthStateValue } from "../../atom/auth";
import { updateUserApi } from "../../services/api/user_api";

export default function AccountPageProfile() {
    const platform = usePlatformValue();
    const [user, setUser] = useUserState();
    const history = useHistory();
    const auth = useAuthStateValue();

    const { runAsync: updateProfile, loading } = useRequest(
        (userData) => updateUserApi(userData, { token: auth.token }),
        {
            debounceInterval: 250,
            cacheKey: "update-profile",
            manual: true,
        }
    );

    if (platform !== "web") {
        const {
            register,
            handleSubmit,
            formState: { errors },
        } = useForm({
            resolver: yupResolver(schemaEditProfileZalo),
            defaultValues: {
                email: user.email ? user.email : "",
                address: user.address ? user.address : "",
            },
        });

        const onSubmit = (data) => {
            console.log(data);
            let newDataProfile = {
                profile_image: user.img_url,
                name: user.name,
                phone: user.phone,
                email: data.email,
                address: data.address,
            }
            updateProfile(newDataProfile)
                .then((res) => {
                    console.log(res);
                    setUser((prevData) => ({
                        ...prevData,
                        name: res.data.user.name,
                        phone: res.data.user.phone,
                        email: res.data.user.email,
                        address: res.data.user.address,
                        img_url: res.data.user.profile_image
                    }));
                    toast.success(`Thay đổi thông tin cá nhân thành công`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                    history.push("/account")
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(`Thay đổi thông tin cá nhân thất bại`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                });
        };

        return (
            <div>
                <Helmet>
                    <title>Thông tin cá nhân</title>
                </Helmet>

                <div className="profile-card__body">
                    <img
                        src={user.img_url || "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                        alt=""
                        style={{ width: 100, height: 100, borderRadius: "50%" }}
                    />
                </div>
                <div className="d-flex align-items-center mb-2">
                    <HiOutlineUser size={20} />
                    <div style={{ fontSize: 16, fontWeight: "500", marginLeft: 4 }}>Họ và tên</div>
                </div>
                <div
                    className="d-flex align-items-center mb-3 p-2 border rounded"
                    style={{ backgroundColor: "#e5e7eb" }}
                >
                    {user?.name}
                </div>
                <div className="d-flex align-items-center mb-2">
                    <HiOutlinePhone size={20} />
                    <div style={{ fontSize: 16, fontWeight: "500", marginLeft: 4 }}>Số điện thoại</div>
                </div>
                <div className="d-flex align-items-center mb-3 border rounded" style={{ backgroundColor: "#e5e7eb" }}>
                    <div className="p-2" style={{ backgroundColor: "#d1d5db" }}>
                        +84
                    </div>
                    <div className="p-2 w-100">
                        {user?.phone}
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                        label="Địa chỉ"
                        rules={"address"}
                        type="address"
                        placeholder="vd: 23 đường số 8, phường Linh Trung, tp. Thủ Đức, tp. Hồ Chí Minh.."
                        register={register}
                        errors={errors.address ? errors.address.message : ""}
                        icon={<HiOutlineMapPin size={20} />}
                        className="mb-3 p-2 border rounded"
                        style={{ height: 40 }}
                    />
                    <button type="submit" className="btn btn-primary rounded mt-4 mb-10">
                        Lưu thông tin cá nhân
                    </button>
                </form>
            </div>
        );
    } else {
        const {
            register,
            handleSubmit,
            formState: { errors },
        } = useForm({
            resolver: yupResolver(schemaEditProfile),
            defaultValues: {
                name: user.name ? user.name : "",
                phone: user.phone ? user.phone : "",
                email: user.email ? user.email : "",
                address: user.address ? user.address : "",
            },
        });

        const onSubmit = (data) => {
            console.log(data);
            updateProfile(data)
                .then((res) => {
                    console.log(res);
                    setUser((prevData) => ({
                        ...prevData,
                        name: res.data.user.name,
                        phone: res.data.user.phone,
                        email: res.data.user.email,
                        address: res.data.user.address,
                    }));
                    toast.success(`Thay đổi thông tin cá nhân thành công`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                    history.push("/account")
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(`Thay đổi thông tin cá nhân thất bại`, {
                        theme: "colored",
                        className: "mx-2 rounded mt-2",
                    });
                });
        };

        return (
            <div className="card">
                <Helmet>
                    <title>Thông tin tài khoản</title>
                </Helmet>

                <div className="card-header">
                    <h5>Chỉnh sửa thông tin cá nhân</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TextInput
                                    label="Họ và tên"
                                    rules={"name"}
                                    type="text"
                                    placeholder="Nguyen Van A"
                                    register={register}
                                    errors={errors.name ? errors.name.message : ""}
                                    icon={<HiOutlineUser size={22} />}
                                />
                                <TextInput
                                    label="Số điện thoại"
                                    rules={"phone"}
                                    type="numberic"
                                    placeholder="0 xxx xxx xxx"
                                    register={register}
                                    errors={errors.phone ? errors.phone.message : ""}
                                    icon={<HiOutlinePhone size={22} />}
                                />
                                <TextInput
                                    label="Email"
                                    rules={"email"}
                                    type="email"
                                    placeholder="example@example"
                                    register={register}
                                    errors={errors.email ? errors.email.message : ""}
                                    icon={<HiOutlineMail size={22} />}
                                />
                                <TextInput
                                    label="Địa chỉ"
                                    rules={"address"}
                                    type="address"
                                    placeholder="vd: 23 đường số 8, phường Linh Trung, tp. Thủ Đức, tp. Hồ Chí Minh.."
                                    register={register}
                                    errors={errors.address ? errors.address.message : ""}
                                    icon={<HiOutlineMapPin size={22} />}
                                />
                                <button type="submit" className="btn btn-primary rounded mt-4 mb-10">
                                    Lưu thông tin
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
