// react
import React, { useCallback } from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';
import { useCategoriesValue } from '../../atom/categories';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '' },
        { title: 'Delivery Information', url: '' },
        { title: 'Privacy Policy', url: '' },
        { title: 'Brands', url: '' },
        { title: 'Contact Us', url: '' },
        { title: 'Returns', url: '' },
        { title: 'Site Map', url: '' },
    ];
    const categories = useCategoriesValue();

    const accountLinks = [
        { title: 'Store Location', url: '' },
        { title: 'Order History', url: '' },
        { title: 'Wish List', url: '' },
        { title: 'Newsletter', url: '' },
        { title: 'Specials', url: '' },
        { title: 'Gift Certificates', url: '' },
        { title: 'Affiliate', url: '' },
    ];

    return (
        <div className="site-footer">
            <div className="container" style={{maxWidth: '75%'}}>
                <div className="site-footer__widgets">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-3">
                            <FooterLinks title="Danh mục" items={categories?.slice(0,10)} />
                            <div className='mt-3'>
                                <img src='https://i.imgur.com/1D0s9qd.png' width={'120'} height={'50'}/>
                            </div>
                            <div className='mt-1 ml-1'>
                                <img src='https://i.imgur.com/AzwrpaI.png' width={'120'} height={'50'}/>
                            </div>
                        </div>
                        {/* <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div> */}
                        <div className="col-12 col-md-12 col-lg-5">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        {' '}
                        Design by
                        {' '}
                        <a href={'https://digibird.io/'} target="_blank" className="text-primary" style={{color: '#ffd333'}}>
                            {theme.author.name}
                        </a>
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
